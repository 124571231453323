import React, { useEffect, useState } from 'react';
import { ActionDropdown } from '../components/common';
import {
  LeftArrowIcon,
  DownArrowIcon,
  OpenEyeIcon2,
  Pen2Icon,
  OutlinedAddIcon,
  ShareIcon,
  DeleteIcon,
  ClockHistoryIcon,
} from '../assets/icons';
import { Button } from '../components/UI';
import {
  PatientProfileInfo,
  PatientJourney,
  PatientInfoTabs,
  AddPartnerModal,
  SharePatientProfileModal,
  AppointmentHistoryModal,
  PatientInfo,
} from '../components/sections';
import { dropDownMenuItem } from '../components/common/ActionDropdown';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetchPatient from '../hooks/useFetchPatient';

import { getFullName } from '../utils/Helper';
import PatientInfoPageLoader from '../components/loaders/PatientInfoPageLoader';
// import BoardLoader from '../components/loaders/BoardLoader';
import { notify } from '../components/common/Alert';
import { ERROR_TITLE } from '../constants/NotificationConstants';
import { PAGE_TITLE } from '../constants/TitleConstants';

const PatientProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchPatientDetails = useFetchPatient();

  const [patient, setPatient] = useState<PatientInformation>();
  const [partner, setPartner] = useState<PatientInformation>();
  const [addPartnerModal, setAddPartnerModal] = useState(false);
  const [sharePatientModal, setSharePatientModal] = useState(false);
  const [appointmentHistoryModal, setAppointmentHistoryModal] = useState(false);

  const dropdownItems: dropDownMenuItem[] = [
    {
      label: 'Update profile',
      Icon: <Pen2Icon stroke="black" />,
      action: () => {},
    },
    {
      label: 'View Details',
      Icon: <OpenEyeIcon2 />,
      action: () => {},
    },
    {
      label: 'Add partner',
      Icon: <OutlinedAddIcon stroke="black" />,
      action: () => setAddPartnerModal(true),
    },
    {
      label: 'Share Profile',
      Icon: <ShareIcon />,
      action: () => setSharePatientModal(true),
    },
    {
      label: 'Appointment history',
      Icon: <ClockHistoryIcon />,
      action: () => setAppointmentHistoryModal(true),
    },
    {
      label: <span className="text-destructive-500">Delete</span>,
      Icon: <DeleteIcon stroke="#EF4444" />,
      action: () => {},
    },
  ];

  const getPatientDetails = async () => {
    if (id) {
      setIsLoading(true);
      const [response, error] = await fetchPatientDetails(id, false);
      if (response) {
        setPatient(response);
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_PATIENT_FETCH,
          message: error,
        });
      }
      if (response?.partnerPublicId) {
        const [partnerRes, error] = await fetchPatientDetails(
          response.partnerPublicId,
          false
        );
        if (partnerRes) {
          setPartner(partnerRes);
        } else {
          notify.error({
            title: ERROR_TITLE.GENERIC_PARTNER_FETCH,
            message: error,
          });
        }
      } else {
        setPartner(undefined);
      }
      setIsLoading(false);
    }
  };

  const onBackClick = () => {
    const previousPage = sessionStorage.getItem('patientProfilePreviousPage');
    if (previousPage && previousPage !== 'undefined') {
      navigate(previousPage);
    } else {
      navigate('/patients');
    }
  };

  useEffect(() => {
    document.title = `${id} | ${PAGE_TITLE.PATIENT_PROFILE}`;
    setPatient(undefined);
    setPartner(undefined);
    sessionStorage.setItem('patientProfilePreviousPage', location.state?.from);
    getPatientDetails();
  }, [id, location.state?.from]);

  return (
    <>
      <AppointmentHistoryModal
        open={appointmentHistoryModal}
        onClose={() => setAppointmentHistoryModal(false)}
      />
      <SharePatientProfileModal
        open={sharePatientModal}
        onClose={() => setSharePatientModal(false)}
      />
      {patient && addPartnerModal && (
        <AddPartnerModal
          open={addPartnerModal}
          onClose={() => setAddPartnerModal(false)}
          currentPatientPublicId={patient.publicId}
          fetchNewPatient={getPatientDetails}
          patientPartnerId={patient.partnerPublicId}
        />
      )}
      <div className="space-y-5">
        <div className="flex justify-between bg-white z-50">
          <Button
            variant="outlined"
            leadingIcon={<LeftArrowIcon />}
            className="w-[95px] h-[34px] gap-1"
            onClick={onBackClick}
          >
            Back
          </Button>
          <div className="flex items-center gap-2">
            {patient && (
              <div>
                <p className="font-semibold text-[16px] text-neutral-900">
                  {getFullName(patient)}
                </p>
              </div>
            )}
            {patient && (
              <div>
                <p className="px-1.5 py-0.75 rounded-[20px] border border-primary-500 bg-primary-50 font-medium text-primary-500 inline-block">
                  {patient?.publicId}
                </p>
              </div>
            )}
          </div>
          <ActionDropdown
            side="bottom"
            align="end"
            sideOffset={2}
            drownDownItems={dropdownItems}
          >
            <div>
              <Button
                trailingIcon={<DownArrowIcon />}
                variant={'secondary'}
                className="w-[95px] h-[34px] gap-1"
              >
                Actions
              </Button>
            </div>
          </ActionDropdown>
        </div>
        {!isLoading ? (
          patient && partner ? (
            <div className="flex gap-2">
              <PatientProfileInfo patient={patient} />
              <PatientProfileInfo patient={partner} />
            </div>
          ) : (
            patient && <PatientInfo patient={patient} />
          )
        ) : (
          <PatientInfoPageLoader />
        )}

        {/* <BoardLoader /> */}
        <PatientJourney />
        <PatientInfoTabs />
      </div>
    </>
  );
};

export default PatientProfile;
