import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: TeamState = { teams: [] };

export const teamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    addTeams: (state, action: PayloadAction<TeamLite[]>) => {
      state.teams = action.payload;
    },
    deleteTeam: (state, action: PayloadAction<{ publicId: string }>) => {
      state.teams = state.teams.filter(
        staff => staff.publicId !== action.payload.publicId
      );
    },
    addTeam: (state, action: PayloadAction<TeamLite>) => {
      state.teams.push(action.payload);
    },
    updateTeam: (state, action: PayloadAction<TeamLite>) => {
      const staffIndex = state.teams.findIndex(
        staff => staff.publicId === action.payload.publicId
      );
      state.teams[staffIndex] = action.payload;
    },
  },
});

export default teamSlice.reducer;
export const { addTeams, deleteTeam, addTeam, updateTeam } = teamSlice.actions;
