import React, { useEffect, useState } from 'react';
import {
  getTopTestFromTDL,
  searchTDLTest,
} from '../../../../../services/api/endPoints/labs';
import { Badge } from '../../../../../components/UI/Badge';
import AutoComplete from '../../../../../components/UI/AutoComplete';
import LabTestLoader from '../../../../../components/loaders/LabTestLoader';

const TestChips: React.FC<TestChipsProps> = ({ setSelectedTests }) => {
  const [selectedCompTests, setSelectedCompTests] = useState<TDLTest[]>([]);
  const [dropdownSelectedTests, setDropdownSelectedTests] = useState<TDLTest[]>(
    []
  );
  const [allTests, setAllTests] = useState<TDLTest[]>([]);
  const [topTest, setTopTests] = useState<TDLTest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getTopTests = async () => {
    setTopTests([]);
    setIsLoading(true);
    const [response] = await getTopTestFromTDL();
    if (response) {
      const result = response.map((item: TDLTest) => {
        return { label: item.Description, ...item };
      });
      setTopTests(result);
    }
    setIsLoading(false);
  };

  const handleTestClick = (tests: TDLTest[]) => {
    if (tests.length > 0) {
      let updatedSelectedTests = [...selectedCompTests];

      tests.forEach(test => {
        const isTestSelected = updatedSelectedTests.some(t => t.Id === test.Id);

        if (isTestSelected) {
          updatedSelectedTests = updatedSelectedTests.filter(
            t => t.Id !== test.Id
          );
          setSelectedCompTests(updatedSelectedTests);
        }
      });
    }
    setDropdownSelectedTests([...tests]);
  };

  const handleBadgeClick = (test: TDLTest) => {
    const isTestSelected = selectedCompTests.some(t => t.Id === test.Id);
    if (isTestSelected) {
      const updatedSelectedTests = selectedCompTests.filter(
        t => t.Id !== test.Id
      );
      setSelectedCompTests(updatedSelectedTests);
    } else {
      const updatedSelectedTests = [...selectedCompTests, test];
      setSelectedCompTests(updatedSelectedTests);
    }
  };

  const search = async () => {
    setAllTests([]);
    setIsLoading(true);
    const [response] = await searchTDLTest('');
    if (response) {
      const result = response.map((item: TDLTest) => {
        return { label: `${item.Description}(${item.TestCode})`, ...item };
      });
      setAllTests(result);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTopTests();
    search();
  }, []);

  useEffect(() => {
    setSelectedTests([...selectedCompTests, ...dropdownSelectedTests]);
  }, [selectedCompTests, dropdownSelectedTests, setSelectedTests]);
  return (
    <>
      {isLoading ? (
        <LabTestLoader />
      ) : (
        <>
          <AutoComplete
            multi={true}
            placeholder="Search Test"
            setSelectedValue={handleTestClick}
            selectedValue={[]}
            options={allTests}
            labelField={'Description'}
            valueField={'Id'}
          />
          <div className="flex flex-wrap gap-2">
            {topTest?.map(test => (
              <Badge
                type="button"
                key={test.Id}
                onClick={() => handleBadgeClick(test)}
                variant={
                  selectedCompTests.some(t => {
                    return t.Id === test.Id;
                  })
                    ? 'primary'
                    : 'secondary'
                }
                color={
                  selectedCompTests.some(t => t.Id === test.Id)
                    ? 'primary'
                    : 'default'
                }
              >
                {test.Description}
              </Badge>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default TestChips;
