import React, { useEffect } from 'react';
import ProjectRoutes from './routes';
import { messaging } from './firebase.config';
import { getToken } from 'firebase/messaging';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { onMessage } from 'firebase/messaging';
import { useDispatch } from 'react-redux';
import { addNotification } from './redux/slice';
import { registerToPushNotification } from './services/api';
import { GRANTED } from './constants/NotificationPermission';
import { notify } from './components/common';
import { ERROR_TITLE } from './constants/NotificationConstants';
const broadcast = new BroadcastChannel('new-notification');

const App = () => {
  const { token } = useSelector<RootState, AuthEntities>(
    state => state.auth.auth
  );
  const dispatch = useDispatch();
  const getFirebaseToken = async () => {
    const permission = await Notification.requestPermission();
    if (permission === GRANTED) {
      const token = await getToken(messaging);
      await registerToPushNotification(token);
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_ENABLE_BROWSER_NOTIFICATION,
        message: 'Permission denied.',
      });
    }
  };
  useEffect(() => {
    if (token) {
      getFirebaseToken();
      broadcast.onmessage = (event: MessageEvent) => {
        dispatch(addNotification(event.data.data));
      };
      onMessage(messaging, payload => {
        dispatch(addNotification(payload.data!));
      });
    }
  }, [token]);

  return (
    <div className="App">
      <ProjectRoutes />
    </div>
  );
};

export default App;
