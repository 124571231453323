import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ResourceState = { resources: [] };

export const resourceSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    addResources: (state, action: PayloadAction<Resource[]>) => {
      state.resources = action.payload;
    },
    deleteResource: (state, action: PayloadAction<{ publicId: string }>) => {
      state.resources = state.resources.filter(
        staff => staff.publicId !== action.payload.publicId
      );
    },
    addResource: (state, action: PayloadAction<Resource>) => {
      state.resources.push(action.payload);
    },
    updateResource: (state, action: PayloadAction<Resource>) => {
      const resourceIndex = state.resources.findIndex(
        resource => resource.publicId === action.payload.publicId
      );
      state.resources[resourceIndex] = action.payload;
    },
  },
});

export default resourceSlice.reducer;
export const { addResources, deleteResource, addResource, updateResource } =
  resourceSlice.actions;
