/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  RightArrow,
  OutlinedCirlce,
  LogoType2,
  DashboardSidebarIcon,
  UserSidebarIcon,
  LabSidebarIcon,
  BoardSidebarIcon,
  DownArrow,
  AdminSidebarIcon,
  ClinicManagerSidebarIcon,
  AppearanceSidebarIcon,
  FormSidebarIcon,
  EmailManagerSidebarIcon,
  // BookingSidebarIcon,
  // StaffScheduleSidebarIcon,
  // BookingCalendarSidebarIcon,
  // BookingServiceSidebarIcon,
  // ResourceBookingSidebarIcon,
} from '../../assets/icons';
import { ROUTES } from '../../constants/NavigationConstants';
import { LogOutIcon } from 'lucide-react';
import { notify } from '../../components/common/Alert';
import { SUCCESS_NOTIFICATION } from '../../constants/NotificationConstants';

interface SidebarMenuItem {
  name: string;
  path: string;
  Icon: React.FC<{ isActive: boolean }>;
  dropdown?: SidebarMenuItem[];
}

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [hoverID, setHoverId] = useState<string>('');
  const navigate = useNavigate();
  const [showSideBar, setShowSidebar] = useState<boolean>(false);
  const [isBookingDropdownOpen, setIsBookingDropdownOpen] =
    useState<boolean>(false);

  const SIDEBAR_MENU: SidebarMenuItem[] = [
    {
      name: 'Overview',
      path: ROUTES.ROUTE_TO_OVERVIEW,
      Icon: DashboardSidebarIcon,
    },
    {
      name: 'Patients',
      path: ROUTES.ROUTE_TO_PATIENT,
      Icon: UserSidebarIcon,
    },
    {
      name: 'Labs',
      path: ROUTES.LABS.LABS_WILDCARD,
      Icon: LabSidebarIcon,
    },
    // {
    //   name: 'Clinic Board',
    //   path: ROUTES.ROUTE_TO_BASE,
    //   Icon: BoardSidebarIcon,
    // },
    // {
    //   name: 'Booking',
    //   path: '',
    //   Icon: BookingSidebarIcon,
    //   dropdown: [
    //     {
    //       name: 'Booking calendar',
    //       path: '',
    //       Icon: BookingCalendarSidebarIcon,
    //     },
    //     {
    //       name: 'Staff schedule',
    //       path: '',
    //       Icon: StaffScheduleSidebarIcon,
    //     },
    //     {
    //       name: 'Booking service',
    //       path: '',
    //       Icon: BookingServiceSidebarIcon,
    //     },
    //     {
    //       name: 'Resource booking',
    //       path: ROUTES.ROUTE_TO_RESOURCE_BOOKING,
    //       Icon: ResourceBookingSidebarIcon,
    //     },
    //   ],
    // },

    {
      name: 'Admin',
      path: '',
      Icon: AdminSidebarIcon,
      dropdown: [
        {
          name: 'Clinic Manager',
          path: ROUTES.ADMIN.BASE,
          Icon: ClinicManagerSidebarIcon,
        },
        // {
        //   name: 'Appearance',
        //   path: ROUTES.ROUTE_TO_BASE,
        //   Icon: AppearanceSidebarIcon,
        // },
        // {
        //   name: 'Forms',
        //   path: ROUTES.ROUTE_TO_BASE,
        //   Icon: FormSidebarIcon,
        // },
        {
          name: 'Email Manager',
          path: ROUTES.EMAIL_MANAGER,
          Icon: EmailManagerSidebarIcon,
        },
      ],
    },
  ];
  const ACTION_MENU: {
    name: string;
    action: () => void;
    Icon: React.FC<{ isActive: boolean }> | React.FC;
  }[] = [
    {
      name: 'Sign Out',
      action: () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate(ROUTES.ROUTE_TO_SIGN_IN);
        notify.success({
          title: SUCCESS_NOTIFICATION.TITLE.SIGN_OUT,
          message: SUCCESS_NOTIFICATION.MESSAGE.SIGN_OUT,
        });
      },
      Icon: LogOutIcon,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setShowSidebar(width >= 1025);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={
        'md:flex-1 z-[99999] flex-1 flex flex-col bg-primary-900 h-svh transition-all duration-150 ease-in-out fixed md:relative ' +
        (showSideBar ? 'min-w-[300px] px-[20px]' : 'min-w-[65px] px-[10px]')
      }
    >
      {!showSideBar && (
        <button
          className="absolute bg-primary-900 rounded-full right-[-10px] top-[17px] z-[9999] p-[5px]"
          onClick={() => setShowSidebar(true)}
        >
          <RightArrow stroke="white" />
        </button>
      )}
      <div
        className={
          'py-[16px] flex items-center ' +
          (showSideBar ? 'justify-between' : 'justify-center')
        }
      >
        <div className="flex items-center gap-2">
          <img src={OutlinedCirlce} alt="Outlined Circle" />
          <img
            src={LogoType2}
            alt="Logo"
            className={'transition-all ' + (showSideBar ? 'block' : 'hidden')}
          />
        </div>
        {showSideBar && (
          <button className="-rotate-180" onClick={() => setShowSidebar(false)}>
            <RightArrow stroke="white" />
          </button>
        )}
      </div>
      <ul className="list-none flex flex-col gap-2">
        {SIDEBAR_MENU.map(({ name, path, Icon, dropdown }, index) => (
          <li key={index}>
            {dropdown ? (
              <div
                className={isBookingDropdownOpen ? 'bg-[#1A151F] rounded' : ''}
              >
                <button
                  id={name}
                  onMouseEnter={e =>
                    setHoverId((e.target as HTMLButtonElement).id)
                  }
                  onMouseLeave={() => setHoverId('')}
                  onClick={() =>
                    setIsBookingDropdownOpen(!isBookingDropdownOpen)
                  }
                  className={
                    isBookingDropdownOpen
                      ? 'flex items-center justify-between font-medium rounded gap-2 py-[12px] px-[10px] text-shades-0 w-full' +
                        (location.pathname === path
                          ? ' bg-primary-500 text-shades-100 '
                          : '') +
                        (!showSideBar ? 'justify-center' : '')
                      : 'flex items-center justify-between font-medium rounded gap-2 py-[12px] px-[10px] text-shades-0 hover:bg-primary-500 hover:text-shades-100 w-full' +
                        (location.pathname === path
                          ? ' bg-primary-500 text-shades-100 '
                          : '') +
                        (!showSideBar ? 'justify-center' : '')
                  }
                >
                  <div
                    className={`flex items-center gap-2 ${
                      !showSideBar
                        ? 'flex-col justify-center w-[1.5rem]'
                        : 'gap-[9rem] justify-center'
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      <div className="">
                        {isBookingDropdownOpen ? (
                          <Icon isActive={false} />
                        ) : (
                          <Icon
                            isActive={
                              hoverID === name || location.pathname === path
                            }
                          />
                        )}
                      </div>
                      <span
                        className={
                          'transition-all ' + (showSideBar ? 'block' : 'hidden')
                        }
                      >
                        {name}
                      </span>
                    </div>
                    {isBookingDropdownOpen ? (
                      !showSideBar ? (
                        <div
                          className="flex justify-center w-full"
                          style={{ transform: 'rotate(180deg)' }}
                        >
                          <DownArrow stroke="white" />
                        </div>
                      ) : (
                        <DownArrow stroke="white" />
                      )
                    ) : showSideBar ? (
                      <RightArrow stroke="white" />
                    ) : (
                      <div className="flex justify-center w-full">
                        <DownArrow stroke="white" />
                      </div>
                    )}
                  </div>
                </button>
                {isBookingDropdownOpen && (
                  <ul className="list-none flex flex-col items-center">
                    {dropdown.map((subItem, subIndex) => (
                      <li key={subIndex} className="w-full">
                        <Link
                          id={subItem.name}
                          onMouseEnter={e =>
                            setHoverId((e.target as HTMLButtonElement).id)
                          }
                          onMouseLeave={() => setHoverId('')}
                          to={subItem.path}
                          className={
                            'flex items-center font-medium rounded gap-2 py-[12px] px-[10px] text-shades-0 hover:bg-primary-500 hover:text-shades-100 ' +
                            (location.pathname === subItem.path
                              ? ' bg-primary-500 text-shades-100 '
                              : '') +
                            (!showSideBar ? 'justify-center' : '')
                          }
                        >
                          <div className="">
                            <subItem.Icon
                              isActive={
                                hoverID === subItem.name ||
                                location.pathname === subItem.path
                              }
                            />
                          </div>
                          <span
                            className={
                              'transition-all ' +
                              (showSideBar ? 'block' : 'hidden')
                            }
                          >
                            {subItem.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ) : (
              <Link
                id={name}
                onMouseEnter={e =>
                  setHoverId((e.target as HTMLAnchorElement).id)
                }
                onMouseLeave={() => setHoverId('')}
                to={path}
                className={
                  'flex items-center font-medium rounded gap-2 py-[12px] px-[10px] text-shades-0 hover:bg-primary-500 hover:text-shades-100 ' +
                  (location.pathname === path
                    ? ' bg-primary-500 text-shades-100 '
                    : '') +
                  (!showSideBar ? 'justify-center' : '')
                }
              >
                <div className="">
                  <Icon
                    isActive={hoverID === name || location.pathname === path}
                  />
                </div>
                <span
                  className={
                    'transition-all ' + (showSideBar ? 'block' : 'hidden')
                  }
                >
                  {name}
                </span>
              </Link>
            )}
          </li>
        ))}
      </ul>
      <ul className="list-none flex flex-col gap-2 mt-auto mb-3">
        {ACTION_MENU.map(({ name, action, Icon }, index) => (
          <li key={index}>
            <button
              onClick={action}
              className={
                'flex items-center font-medium rounded gap-2 py-[12px] px-[10px] text-shades-0 hover:bg-destructive-500 hover:text-shades-0 w-full' +
                (!showSideBar ? 'justify-center' : '')
              }
            >
              <div className="">
                <Icon isActive={false} />
              </div>
              <span
                className={
                  'transition-all ' + (showSideBar ? 'block' : 'hidden')
                }
              >
                {name}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
