import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: FemalePatientState = {
  female: {
    publicId: '',
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    dateOfBirth: '',
    age: 0,
    dateOfBirthStr: '',
    address: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    ownerStaff: {
      publicId: '',
      username: '',
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      type: '',
      phoneNo: '',
      signature: '',
      createdBy: '',
      whenCreated: '',
      updatedBy: '',
      whenLastUpdated: '',
      siteExternalId: '',
    },
    createdBy: '',
    whenCreated: '',
    partnerPublicId: '',
    profilePictureUrl: '',
    patientDetails: {
      gpDetails: {
        name: '',
        email: '',
        phoneNo: '',
        contact: '',
        address: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
      },
      nextOfKin: {
        name: '',
        contact: '',
        relation: '',
      },
      allergies: '',
      referralSource: '',
      fundingSource: '',
      weight: 0,
      weightUnit: '',
      height: 0,
      heightUnit: '',
      occupation: '',
      smoking: false,
      tryingSinceYears: 0,
      tryingSinceMonths: 0,
      cpLiveChildren: 0,
      ppLiveChildren: 0,
      obstetricsHistory: '',
      medicalHistory: '',
      surgeryHistory: '',
      medications: '',
      significantGeneticHistory: '',
      smearsCervix: '',
      treatmentOnCervix: '',
      periods: '',
      periodsComment: '',
      rubellaImmune: false,
      primaryDiagnosis: [],
      secondaryDiagnosis: [],
      subfertility: '',
      additionalComments: '',
    },
  },
};

export const femalePatientSlice = createSlice({
  name: 'female',
  initialState,
  reducers: {
    addFemalePatientInformation: (
      state: FemalePatientState,
      action: PayloadAction<PatientInformation>
    ) => {
      state.female = action.payload;
    },
    removeFemalePatientInformation: (state: FemalePatientState) => {
      state.female = initialState.female;
    },
    getFemalePatientInformation: (state: FemalePatientState) => {
      return state;
    },
  },
});

export default femalePatientSlice.reducer;
export const {
  addFemalePatientInformation,
  removeFemalePatientInformation,
  getFemalePatientInformation,
} = femalePatientSlice.actions;
