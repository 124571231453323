import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Login = {
  login: {
    username: null,
    password: null,
    twoFactorReset: null,
    passwordReset: null,
  },
};

export const loginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginDetails: (state: Login, action: PayloadAction<LoginEntities>) => {
      state.login.username = action.payload.username;
      state.login.password = action.payload.password;
      state.login.passwordReset = action.payload.passwordReset;
      state.login.twoFactorReset = action.payload.twoFactorReset;
    },
    removeLoginDetails: () => initialState,
  },
});

export default loginSlice.reducer;
export const { loginDetails, removeLoginDetails } = loginSlice.actions;
