import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Slider,
  Button,
  Input,
  Select,
  buttonVariants,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../../UI';
import { CrossIcon, RightArrow, SearchIcon } from '../../../../../assets/icons';
const SharePatientProfileModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const DUMMY_ACCESSED_PEOPPlE: {
    id: string;
    name: string;
    email: string;
    role: 'Owner' | 'Viewer' | 'Editor';
  }[] = [
    {
      id: '1',
      name: 'John Clerk',
      email: 'john@gmail.com',
      role: 'Owner',
    },
    {
      id: '1',
      name: 'John Clerk',
      email: 'john@gmail.com',
      role: 'Editor',
    },
  ];

  const DUMMY_STAFFS: {
    id: string;
    name: string;
    email: string;
  }[] = [
    {
      id: '1',
      name: 'John clerk',
      email: 'john@gmail.com',
    },
    {
      id: '1',
      name: 'John clerk',
      email: 'john@gmail.com',
    },
    {
      id: '1',
      name: 'John clerk',
      email: 'john@gmail.com',
    },
    {
      id: '1',
      name: 'John clerk',
      email: 'john@gmail.com',
    },
  ];
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[424px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Share patient Profile
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Input
          leadingIcon={<SearchIcon />}
          placeholder="Search for Patient"
          className="rounded-none border-0 border-b"
        />
        <Slider className="flex-1" variant={'vertical'}>
          <p className="font-semibold text-[12px] pl-3 pt-3 text-neutral-500">
            People With Access
          </p>
          {DUMMY_ACCESSED_PEOPPlE.map(people => (
            <PeopleDetails key={people.id} {...people} isAccessPeople />
          ))}
          <p className="font-semibold text-[12px] pl-3 pt-3 text-neutral-500">
            Staff
          </p>
          {DUMMY_STAFFS.map(staff => (
            <PeopleDetails key={staff.id} {...staff} />
          ))}
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button size={'small'} onClick={onClose} className="w-full">
            Add
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const PeopleDetails = ({
  name,
  email,
  isAccessPeople = false,
  role,
}: {
  id?: string;
  name: string;
  email: string;
  isAccessPeople?: boolean;
  role?: 'Owner' | 'Editor' | 'Viewer';
}) => (
  <div className="px-3  py-2 flex justify-between items-center">
    <div className="gap-2 flex">
      <p className="text-neutral-900 text-[14px] font-medium">{name}</p>
      <p className="text-neutral-500 text-[14px] font-medium">{email}</p>
    </div>
    {isAccessPeople ? (
      role === 'Owner' ? (
        <p className={buttonVariants({ variant: 'secondary', size: 'xmall' })}>
          Owner
        </p>
      ) : (
        <Select value={role}>
          <SelectTrigger className="w-[fit-content] gap-2 broder border-neutral-900">
            <SelectValue placeholder="Select role" />
          </SelectTrigger>
          <SelectContent
            align="end"
            className="bg-shades-0 w-[fit-content] z-[9999999]"
          >
            <SelectItem key={'Editor'} value={'Editor'} className="w-[auto]">
              Editor
            </SelectItem>
            <SelectItem key={'Viewer'} value={'Viewer'} className="w-[auto]">
              Viewer
            </SelectItem>
          </SelectContent>
        </Select>
      )
    ) : (
      <Button variant={'outlined'} size={'xmall'}>
        <RightArrow stroke="currentColor" />
      </Button>
    )}
  </div>
);

export default SharePatientProfileModal;
