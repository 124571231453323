import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Slider,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import AutoComplete from '../../../../../components/UI/AutoComplete';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
  createNewSite,
  getSite,
  updateSite,
} from '../../../../../services/api';
import { notify } from '../../../../../components/common';
import {
  addSite,
  updateSite as updateSiteReducer,
} from '../../../../../redux/slice';
import { useDispatch } from 'react-redux';
import { SITE_NOTIFICATIONS } from '../../../../../constants/NotificationConstants';
type AddAndEditModalProps = {
  open: boolean;
  onClose: () => void;
} & (
  | {
      type: 'Create';
    }
  | {
      type: 'Edit';
      selectedSiteId: string | null;
    }
);
const AddAndEditSiteModal = ({
  open,
  onClose,
  ...props
}: AddAndEditModalProps) => {
  const dispatch = useDispatch();
  const [siteName, setSiteName] = useState<string>('');
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const [selectedStaff, setSelectedStaff] = useState<StaffInformation>(
    user.staff
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );

  useEffect(() => {
    const setSiteDetails = async () => {
      if (props.type !== 'Edit') return;
      if (!props.selectedSiteId) {
        notify.error({
          title: SITE_NOTIFICATIONS.SITE_LOAD_ERROR,
        });
        return;
      }
      const [res, error] = await getSite(props.selectedSiteId);
      if (res) {
        const site: Site = res;
        setSiteName(site.name);
        setSelectedStaff(site.ownerStaff);
      } else {
        notify.error({
          title: SITE_NOTIFICATIONS.SITE_DATA_FETCH_ERROR,
          message: error.data,
        });
      }
    };
    setSiteDetails();
  }, []);
  const onSubmit = async () => {
    setIsLoading(true);
    if (props.type === 'Create') {
      if (selectedStaff) {
        const obj = {
          name: siteName,
          ownerStaffPublicId: selectedStaff.publicId,
        };
        const [response, error] = await createNewSite(obj);
        onClose();
        if (response) {
          notify.success({
            title: SITE_NOTIFICATIONS.CREATE_SUCCESS,
            message: `Site - ${siteName} created successfully`,
          });
          dispatch(addSite(response));
          onClose();
        } else {
          notify.error({
            title: SITE_NOTIFICATIONS.CREATE_ERROR,
            message: error.data,
          });
        }
      }
    } else if (props.type == 'Edit') {
      const obj = {
        publicId: props.selectedSiteId,
        name: siteName,
        ownerStaffPublicId: selectedStaff.publicId,
      };
      const [response, error] = await updateSite(obj);
      onClose();
      if (response) {
        notify.success({
          title: SITE_NOTIFICATIONS.UPDATE_SUCCESS,
          message: `Site - ${siteName} updated successfully`,
        });
        dispatch(updateSiteReducer(response));
        onClose();
      } else {
        notify.error({
          title: SITE_NOTIFICATIONS.UPDATE_SUCCESS,
          message: error.data,
        });
      }
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type === 'Edit' ? 'Edit Site' : 'Add Site'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-4">
            <div className="space-y-1">
              <Label htmlFor="siteName">Site Name</Label>
              <Input
                placeholder="Enter site name"
                name="siteName"
                value={siteName}
                onChange={e => setSiteName(e.target.value)}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="headStaff">Head Staff</Label>
              <AutoComplete
                setSelectedValue={values => setSelectedStaff(values[0] || {})}
                selectedValue={[selectedStaff]}
                options={staffs}
                labelField={'fullName'}
                valueField={'publicId'}
                placeholder={'Select Staff'}
              />
            </div>
          </div>
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            variant={'primary'}
            onClick={onSubmit}
            className="w-full"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {props.type === 'Edit' ? 'Save Site' : 'Add Site'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddAndEditSiteModal;
