import React from 'react';

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.83333 12.6667C10.7789 12.6667 13.1667 10.2789 13.1667 7.33333C13.1667 4.38781 10.7789 2 7.83333 2C4.88781 2 2.5 4.38781 2.5 7.33333C2.5 10.2789 4.88781 12.6667 7.83333 12.6667Z"
        stroke="#808080"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5005 13.9995L11.6338 11.1328"
        stroke="#808080"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
