import React from 'react';
import {
  Label,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Input,
  Textarea,
  Slider,
} from '../../../../UI';
import { Field } from '../../../../../types/form/type';
import { DatePicker } from '../../../../common';

const SectionForm = ({
  fields,
  onInput,
  name,
  data,
  validationErrors,
}: {
  fields: Field[];
  onInput: (publicId: string, value: any) => void;
  name: string;
  data: Record<string, any>;
  validationErrors?: Record<string, any>;
}) => {
  const getInputVariant = (field: Field) => {
    switch (field.type) {
      case 'DROPDOWN':
        return (
          <>
            <Label
              className={
                field.required
                  ? 'after:content-["*"] after:text-destructive-500'
                  : ''
              }
            >
              {field.label}
            </Label>
            <Select
              onValueChange={val => onInput(field.publicId, val)}
              name={field.name}
              required={field.required}
              value={data[field.publicId]}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent className="bg-shades-0">
                {field.options.map(option => (
                  <SelectItem
                    key={field.publicId + '-' + option}
                    value={option}
                    className="w-[auto]"
                  >
                    {option}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {/* add error message */}
            {validationErrors && validationErrors[field.publicId] && (
              <p className="text-[12px] text-destructive-500">
                {validationErrors[field.publicId]}
              </p>
            )}
          </>
        );

      case 'NUMBER':
        return (
          <>
            <Label
              className={
                field.required
                  ? 'after:content-["*"] after:text-destructive-500'
                  : ''
              }
            >
              {field.label}
            </Label>
            <Input
              name={field.name}
              id={field.publicId}
              type="number"
              value={data[field.publicId]}
              required={field.required}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onInput(field.publicId, event.target.value)
              }
            />
            {validationErrors && validationErrors[field.publicId] && (
              <p className="text-[12px] text-destructive-500">
                {validationErrors[field.publicId]}
              </p>
            )}
          </>
        );
      case 'TEXT_FIELD':
        return (
          <>
            <Label
              className={
                field.required
                  ? 'after:content-["*"] after:text-destructive-500'
                  : ''
              }
            >
              {field.label}
            </Label>
            <Input
              name={field.name}
              id={field.publicId}
              required={field.required}
              type="text"
              value={data[field.publicId]}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onInput(field.publicId, event.target.value)
              }
            />
            {validationErrors && validationErrors[field.publicId] && (
              <p className="text-[12px] text-destructive-500">
                {validationErrors[field.publicId]}
              </p>
            )}
          </>
        );
      case 'TEXT_AREA':
        return (
          <>
            <Label
              className={
                field.required
                  ? 'after:content-["*"] after:text-destructive-500'
                  : ''
              }
            >
              {field.label}
            </Label>
            <Textarea
              name={field.name}
              id={field.publicId}
              required={field.required}
              value={data[field.publicId]}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                onInput(field.publicId, event.target.value)
              }
            />
            {validationErrors && validationErrors[field.publicId] && (
              <p className="text-[12px] text-destructive-500">
                {validationErrors[field.publicId]}
              </p>
            )}
          </>
        );
      case 'DATE':
        return (
          <>
            <Label
              className={
                field.required
                  ? 'after:content-["*"] after:text-destructive-500'
                  : ''
              }
            >
              {field.label}
            </Label>
            <DatePicker
              initialValue={data[field.publicId]}
              buttonClass="w-full justify-start"
              handleDateChange={(date: Date | null) =>
                onInput(field.publicId, date ? date.toLocaleDateString() : '')
              }
            />
            {validationErrors && validationErrors[field.publicId] && (
              <p className="text-[12px] text-destructive-500">
                {validationErrors[field.publicId]}
              </p>
            )}
          </>
        );
      case 'DATE_TIME':
        return (
          <>
            <Label
              className={
                field.required
                  ? 'after:content-["*"] after:text-destructive-500'
                  : ''
              }
            >
              {field.label}
            </Label>
            <DatePicker
              initialValue={data[field.publicId]}
              variant="dateTime"
              handleDateChange={(date: Date | null) =>
                onInput(field.publicId, date ? date.toLocaleString() : '')
              }
            />
            {validationErrors && validationErrors[field.publicId] && (
              <p className="text-[12px] text-destructive-500">
                {validationErrors[field.publicId]}
              </p>
            )}
          </>
        );
      case 'RADIO':
        return (
          <>
            <Label
              className={
                field.required
                  ? 'after:content-["*"] after:text-destructive-500'
                  : ''
              }
            >
              {field.label}
            </Label>
            <div className="space-y-1">
              {field.options.map(option => (
                <div className="flex gap-2" key={field.publicId + '-' + option}>
                  <input
                    type="radio"
                    name={field.name}
                    value={option}
                    checked={option === data[field.publicId]}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onInput(field.publicId, event.target.value)
                    }
                  />
                  <Label>{option}</Label>
                </div>
              ))}
              {validationErrors && validationErrors[field.publicId] && (
                <p className="text-[12px] text-destructive-500">
                  {validationErrors[field.publicId]}
                </p>
              )}
            </div>
          </>
        );
      case 'CHECKBOX':
        return (
          <div className="flex gap-2">
            <input
              type="checkbox"
              name={field.name}
              checked={data[field.publicId]}
              required={field.required}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onInput(field.publicId, event.target.checked)
              }
            />
            <Label
              className={
                field.required
                  ? 'after:content-["*"] after:text-destructive-500'
                  : ''
              }
            >
              {field.label}
            </Label>
            {validationErrors && validationErrors[field.publicId] && (
              <p className="text-[12px] text-destructive-500">
                {validationErrors[field.publicId]}
              </p>
            )}
          </div>
        );
      // TODO: Duration, Signature
      default:
        return <></>;
    }
  };

  return (
    <div className="flex flex-col gap-6 items-center h-[430px]">
      {name !== '' && (
        <h1 className="font-medium text-4 text-neutral-900">{name}</h1>
      )}
      <Slider variant={'vertical'} className="w-[660px] space-y-5 flex-1">
        {fields.map(field => (
          <div key={field.publicId} className="space-y-2">
            {getInputVariant(field)}
          </div>
        ))}
        <div className="w-full h-[30px]" />
      </Slider>
    </div>
  );
};

export default SectionForm;
