import { Layout } from '../../layout';
import { ROUTES } from '../../constants/NavigationConstants';
import { RootState } from '@/redux/store/store';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface RequireAuthProps {
  allowedRoles: string[];
}

const RequireAuth: React.FC<RequireAuthProps> = ({ allowedRoles }) => {
  const location = useLocation();
  const auth = useSelector<RootState, AuthEntities>(state => state.auth.auth);
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const roles = user.roles;
  const { token, expiresIn } = auth;

  if (!token || expiresIn <= 0) {
    return (
      <Navigate
        to={ROUTES.ROUTE_TO_SIGN_IN}
        state={{ from: location }}
        replace
      />
    );
  }

  const hasAllowedRoles = roles?.some(
    (role: string) => allowedRoles?.includes(role)
  );

  if (hasAllowedRoles) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }

  return (
    <Navigate
      to={ROUTES.ROUTE_TO_UNAUTHORIZED}
      state={{ from: location }}
      replace
    />
  );
};

export default RequireAuth;
