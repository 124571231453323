export const ERROR_MSG = {
  GENERIC: 'Please try again later or contact your admin.',
};

export const ERROR_TITLE = {
  GENERIC_APPTS: 'Failed to fetch appointments',
  GENERIC_DOCS: 'Failed to fetch document',
  GENERIC_PRESC: 'Failed to fetch prescriptions',
  GENERIC_DOWNLOAD_FILE: 'Failed to download file',
  GENERIC_PATIENT_FETCH: 'Failed to fetch patient',
  GENERIC_PARTNER_FETCH: 'Failed to fetch partner',
  GENERIC_PATIENT_SEARCH: 'Failed to search patients',
  GENERIC_PATIENT_CREATE: 'Failed to create patient',
  GENERIC_PATIENT_UPDATE: 'Failed to update patient',
  GENERIC_PATIENT_DELETE: 'Failed to delete patient',
  GENERIC_PATIENT_ALLERGY_FETCH: 'Failed to fetch patient allergies',
  GENERIC_ENABLE_BROWSER_NOTIFICATION: 'Failed to enable notification',
  GENERIC_STAFF_FETCH: 'Failed to fetch the staff',
  GENERIC_STAFF_ADD: 'Failed to add new staff',
  GENERIC_STAFF_UPDATE: 'Failed to update staff',
  GENERIC_STAFF_DELETE: 'Failed to delete staff',
  GENERIC_TEAM_ADD: 'Failed to add new team',
  GENERIC_TEAM_UPDATE: 'Failed to update team',
  GENERIC_TEAM_DELETE: 'Failed to delete team',
  GENERIC_TEAM_FETCH: 'Failed to fetch the team',
  GENERIC_LOCATION_ADD: 'Failed to add new location',
  GENERIC_LOCATION_UPDATE: 'Failed to update location',
  GENERIC_LOCATION_DELETE: 'Failed to delete location',
  GENERIC_PATHWAY_FETCH: 'Failed to fetch the pathway',
  GENERIC_PATHWAY_ADD: 'Failed to add new pathway',
  GENERIC_PATHWAY_UPDATE: 'Failed to update pathway',
  GENERIC_pathway_DELETE: 'Failed to delete pathway',
  GENERIC_SERVICE_ADD: 'Failed to add new service',
  GENERIC_SERVICE_UPDATE: 'Failed to update service',
  GENERIC_SERVICE_DELETE: 'Failed to delete service',
  GENERIC_RESOURCES_FETCH: 'Failed to fetch the resources',
  GENERIC_RESOURCE_ADD: 'Failed to add new resource',
  GENERIC_RESOURCE_UPDATE: 'Failed to update resource',
  GENERIC_RESOURCE_DELETE: 'Failed to delete resource',
};

export const SUCCESS_TITLE = {
  GENERIC_STAFF_ADD: 'Successfully added new staff',
  GENERIC_STAFF_UPDATE: 'Successfully updated staff',
  GENERIC_STAFF_DELETE: 'Successfully deleted staff',
  GENERIC_TEAM_ADD: 'Successfully added new team',
  GENERIC_TEAM_UPDATE: 'Successfully updated team',
  GENERIC_TEAM_DELETE: 'Successfully deleted team',
  GENERIC_LOCATION_ADD: 'Successfully added new location',
  GENERIC_LOCATION_UPDATE: 'Successfully updated location',
  GENERIC_LOCATION_DELETE: 'Successfully deleted location',
  GENERIC_PATHWAY_ADD: 'Successfully added new pathway',
  GENERIC_PATHWAY_UPDATE: 'Successfully updated pathway',
  GENERIC_PATHWAY_DELETE: 'Successfully deleted pathway',
  GENERIC_SERVICE_ADD: 'Successfully added new service',
  GENERIC_SERVICE_UPDATE: 'Successfully updated service',
  GENERIC_SERVICE_DELETE: 'Successfully deleted service',
  GENERIC_RESOURCE_ADD: 'Successfully added new resource',
  GENERIC_RESOURCE_UPDATE: 'Successfully updated resource',
  GENERIC_RESOURCE_DELETE: 'Successfully deleted resource',
};

export const ERROR_NOTIFICATION = {
  TITLE: {
    SIGN_OUT: 'Signed Out',
  },
  MESSAGE: {
    SIGN_OUT: 'Failed to sign out. please try again',
  },
};

export const SUCCESS_NOTIFICATION = {
  TITLE: {
    SIGN_OUT: 'Signed Out',
  },
  MESSAGE: {
    SIGN_OUT: 'You have been signed out successfully',
  },
};

export const SITE_NOTIFICATIONS = {
  CREATE_SUCCESS: 'The site has been created successfully!',
  UPDATE_SUCCESS: 'The site has been updated successfully!',
  DELETE_SUCCESS: 'The site has been deleted successfully!',
  CREATE_ERROR: 'Failed to create the new site.',
  UPDATE_ERROR: 'Failed to update the site.',
  DELETE_ERROR: 'Failed to delete the site.',
  SITE_LOAD_ERROR: 'Unable to load site information.',
  SITE_DATA_FETCH_ERROR: 'Failed to fetch site data.',
  SITE_ALL_FETCH_ERROR: 'Failed to fetch all sites',
};
