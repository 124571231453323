import { ButtonProps } from '../../types/uiComponents/type';
import React from 'react';
import { Button } from '../UI';
import { cn } from '../../utils';
export interface ActionButtonType extends ButtonProps {
  label: string;
}
const GroupActionButton: React.FC<{ actions: ActionButtonType[] }> = ({
  actions,
}) => {
  return (
    <div className="flex items-center rounded-md border border-neutral-100 [&>*:nth-last-child(1)]:border-r-0">
      {actions.map(
        (
          {
            variant,
            size,
            trailingIcon,
            leadingIcon,
            label,
            className,
            onClick,
            ...props
          },
          idx
        ) => (
          <Button
            key={idx}
            variant={variant ? variant : 'link'}
            leadingIcon={leadingIcon}
            className={cn(
              'px-[16px] border-r border-neutral-100 rounded-l-md',
              className
            )}
            size={size ? size : 'medium'}
            trailingIcon={trailingIcon}
            onClick={onClick}
            {...props}
          >
            {label}
          </Button>
        )
      )}
    </div>
  );
};
export default GroupActionButton;
