import {
  addFemalePatientInformation,
  addPartnerInformation,
} from '../redux/slice';
import { addPatientInformation } from '../redux/slice/patientInformation';
import { getPatientByPublicId } from '../services/api/endPoints/patients';
import { calculateAge } from '../utils/data.helper';
import { getReadableDate } from '../utils/date.utl';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CONSTANT } from '../constants/constants';

const useFetchPatient = () => {
  const [, setPatientDetails] = useState<PatientInformation | null>(null);
  const dispatch = useDispatch();

  const fetchPatientDetails = async (
    patientPublicId: string,
    isPartner: boolean
  ) => {
    try {
      const { data } = await getPatientByPublicId(patientPublicId);
      if (data.publicId) {
        data.dateOfBirth = getReadableDate(data.dateOfBirth);
        data.allergies = data.allergies ? data.allergies.split(', ') : [];
        data.age = data.dateOfBirth ? calculateAge(data.dateOfBirth) : '';
        setPatientDetails(data);
        if (data.gender === CONSTANT.FEMALE) {
          dispatch(addFemalePatientInformation(data));
        }
        if (isPartner) {
          dispatch(addPartnerInformation(data));
        } else {
          dispatch(addPatientInformation(data));
        }
        return [data, null] as const;
      } else {
        return [null, data];
      }
    } catch (error) {
      console.error(error);
      return [null, error] as const;
    }
  };

  return fetchPatientDetails;
};

export default useFetchPatient;
