/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Sessions from './Sessions';
import Analytics from './Analytics';
import ActivityFeed from './ActivityFeed';

const ConsultantView = () => {
  return (
    <div>
      {/* <Analytics role="consultant" /> */}
      <div className="custom-height grid gap-3 xl:grid-cols-7 lg:grid-rows-6 md:grid-rows-6 sm:grid-rows-6">
        <div className="xl:col-span-5 lg:row-span-3 sm:row-span-3 space-y-5">
          <Sessions />
        </div>
        <div className="xl:col-span-2 lg:row-span-3 sm:row-span-3 space-y-5">
          <ActivityFeed />
        </div>
      </div>
    </div>
  );
};

export default ConsultantView;
