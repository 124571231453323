/* eslint-disable indent */
import { ColumnDef } from '@tanstack/react-table';
import {
  ClockIcon,
  DirectedArrowIcon,
  Dot,
  OutlinedAddIcon,
} from '../../../../../assets/icons';
import { Button, Slider } from '../../../../UI';
import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../../common';
import format from 'date-fns/format';
import RecommendedModal from './RecommendedModal';
import { useParams } from 'react-router-dom';
import {
  getRecommendations,
  remindRecommendation,
} from '../../../../../services/api';
import { notify } from '../../../../common/Alert';

const Recommended = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openRecomendedModal, setOpenRecommendedModal] =
    useState<boolean>(false);
  const [recommendations, setRecommendations] = useState<RecommendedUIType[]>(
    []
  );
  const [remindLoading, setRemindLoading] = useState<Map<string, boolean>>(
    new Map<string, boolean>()
  );

  const toggleRemindButtonLoading = (btnRowId: string, state: boolean) => {
    const newBtnLoading = new Map<string, boolean>(remindLoading);
    newBtnLoading.set(btnRowId, state);
    setRemindLoading(newBtnLoading);
  };

  const recommendColumnDef: ColumnDef<RecommendedUIType>[] = [
    {
      accessorKey: 'serviceName',
      header: 'Service',
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => {
        return (
          <p
            className={
              'py-1 px-2 flex items-center gap-1 rounded border w-[fit-content] ' +
              (row.original.status === 'Recommended'
                ? 'border-warning-500 bg-warning-50'
                : 'border-success-500 bg-success-50')
            }
          >
            <Dot
              fill={
                row.original.status === 'Recommended' ? '#F59E0B' : '#22C55E'
              }
            />
            <span>{row.original.status}</span>
          </p>
        );
      },
    },
    {
      accessorKey: 'recommendedOn',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Recommended On
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill="#808080" />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill="#808080" />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return (
          <span>
            {format(row.original.recommendedOn, 'dd MMM yyyy h:mm a').toString()}
          </span>
        );
      },
    },
    {
      accessorKey: 'recommendedBy',
      header: 'Recommended By',
    },
    {
      accessorKey: 'lastRemindedOn',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Last Reminded On
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill="#808080" />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill="#808080" />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return (
          <span>
            {row.original.lastRemindedOn &&
              format(
                row.original.lastRemindedOn,
                'dd MMM yyyy h:mm a'
              ).toString()}
          </span>
        );
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const remindRecommendationToPatient = async () => {
          const remindLoadingKey = `Remind_${row.original.id}`;
          toggleRemindButtonLoading(remindLoadingKey, true);
          const [res, err] = await remindRecommendation(row.original.id);
          if (res) {
            notify.success({
              title: 'Reminder sent successfully',
              message: `Reminder sent for ${row.original.serviceName}`,
            });
          } else if (err) {
            notify.error({
              title: 'Failed to send reminder',
              message: err.data,
            });
          }
          toggleRemindButtonLoading(remindLoadingKey, false);
          await getRecommendationsForPatient();
        };

        return (
          <div className="flex justify-start">
            <div className="flex gap-8">
              <Button
                variant={'outlined'}
                className="h-[30px]"
                size={'xmall'}
                trailingIcon={<ClockIcon stroke="#000000" />}
                onClick={remindRecommendationToPatient}
                isLoading={remindLoading.get(`Remind_${row.original.id}`)}
                disabled={row.original.status === 'Booked'}
              >
                Remind
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  const getRecommendationsForPatient = async () => {
    if (id) {
      setIsLoading(true);
      const [res, err] = await getRecommendations('', id);
      if (err) {
        notify.error({
          title: 'Failed to fetch recommendations',
          message: err.data,
        });
      } else if (res) {
        const recommendations: RecommendedUIType[] = res.map(
          (recommend: Recommendation) => ({
            id: recommend.publicId,
            serviceName: recommend.service.name,
            serviceId: recommend.service.publicId,
            status: recommend.status,
            recommendedOn: new Date(recommend.whenCreated),
            lastRemindedOn: recommend.whenLastReminder
              ? new Date(recommend.whenLastReminder)
              : null,
            recommendedBy: recommend.staff.fullName,
          })
        );
        //sort by recommended on
        recommendations.sort(
          (a, b) =>
            b.recommendedOn.getTime() - a.recommendedOn.getTime()
        );
        setRecommendations(recommendations);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRecommendationsForPatient();
  }, []);

  return (
    <div>
      <RecommendedModal
        open={openRecomendedModal}
        recommendedServices={
          recommendations
            ? recommendations
                .filter(recommend => recommend.status == 'Recommended')
                .map((recommend: RecommendedUIType) => recommend.serviceId)
            : []
        }
        onClose={async () => {
          setOpenRecommendedModal(false);
          await getRecommendationsForPatient();
        }}
      />
      <div className="p-3 flex justify-between items-center">
        <h1 className="text-neutral-900 font-medium whitespace-nowrap">
          Recommended Services
        </h1>
        <div className="flex gap-3">
          {/* <Button
            className="inline-flex"
            variant={'link'}
            size={'xmall'}
            trailingIcon={<FilterIcon />}
          >
            Filter by
          </Button> */}
          <Button
            className="inline-flex"
            variant={'primary'}
            size={'xmall'}
            leadingIcon={<OutlinedAddIcon />}
            onClick={() => setOpenRecommendedModal(true)}
          >
            Recommend
          </Button>
        </div>
      </div>
      <Slider variant={'vertical'} className="h-[500px]">
        <DataTable
          columns={recommendColumnDef}
          data={recommendations}
          isLoading={isLoading}
        />
      </Slider>
    </div>
  );
};

export default Recommended;
