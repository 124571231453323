import React from 'react';
import { cn } from '../../utils';
import { VariantProps, cva } from 'class-variance-authority';

const sliderVariants = cva('w-full', {
  variants: {
    variant: {
      vertical: 'overflow-x-scroll flex flex-col',
      horizontal: 'overflow-y-scroll flex flex-row',
    },
  },
  defaultVariants: {
    variant: 'horizontal',
  },
});

interface SliderProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof sliderVariants> {}
const Slider: React.FC<SliderProps> = ({
  children,
  className,
  variant,
  ...props
}) => {
  return (
    <div className={cn(sliderVariants({ variant, className }))} {...props}>
      {children}
    </div>
  );
};

export { Slider };
