import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface NottificationType {
  totalUnread: number;
  hasNew: boolean;
  notifications: Record<string, Record<string, string>[]>;
}
const initialNotificationState: NottificationType = {
  totalUnread: 0,
  hasNew: false,
  notifications: {},
};

export const notificationSlice = createSlice({
  name: 'notfications',
  initialState: initialNotificationState,
  reducers: {
    setInitilaNotifications: (state, action: PayloadAction) => {
      // will be setup once the api result is given
      console.log(state, action);
    },
    addNotification: (
      state: NottificationType,
      action: PayloadAction<Record<string, string>>
    ) => {
      if (!action.payload) {
        return;
      }

      const key = new Date(Number(action.payload.time)).toLocaleDateString(
        'en-UK'
      );
      if (state.notifications[key]) {
        state.notifications[key].unshift(action.payload);
      } else {
        state.notifications[key] = [{ ...action.payload }];
      }
      state.hasNew = true;
      state.totalUnread += 1;
    },
    markAsRead: (
      state: NottificationType,
      action: PayloadAction<Record<string, string>>
    ) => {
      if (!action.payload) {
        return;
      }
      const key = new Date(Number(action.payload.time)).toLocaleDateString(
        'en-UK'
      );
      const notificationId = action.payload.id;
      const notificationindex = state.notifications[key].findIndex(
        notification => notification.id === notificationId
      );
      if (notificationindex < 0) {
        throw Error('Unable to get the notification');
      }
      state.notifications[key][notificationindex].hasRead = 'false';
      if (state.totalUnread > 0) state.totalUnread -= 1;
    },
    checkedNewNotification: (state: NottificationType) => {
      state.hasNew = false;
    },
  },
});

export default notificationSlice.reducer;
export const {
  setInitilaNotifications,
  addNotification,
  markAsRead,
  checkedNewNotification,
} = notificationSlice.actions;
