/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ERROR_TO_DO } from '../../../types';
import { AZURE_URL } from '../requestConstructor';

export const getNotes = async (
  publicId: string,
  patientPublicId: string
) => {
  try {
    const response = await AZURE_URL.get(
      `/note/get?publicId=${publicId}&patientPublicId=${patientPublicId}`
    );
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const deleteNote = async (publicId: string) => {
  try {
    const response = await AZURE_URL.delete(
      `/note/delete?publicId=${publicId}`
    );
    return [response, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const createNote = async (note: NoteCreateRequest) => {
  try {
    const response = await AZURE_URL.post('/note/create', note);
    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    if (error.response.status === 400) {
      return [null, error.response.data];
    } else {
      return [null, error];
    }
  }
};
