/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable quotes */
import { ColumnDef } from '@tanstack/react-table';
import {
  DirectedArrowIcon,
  Dot,
  // OpenEyeIcon2,
  OutlinedAddIcon,
  // SendIcon,
} from '../../../../../assets/icons';
import { Button, Slider /*, buttonVariants */ } from '../../../../UI';
import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../../common';
import { /* Link, */ useParams } from 'react-router-dom';
import { getAppointmentByPatientId } from '../../../../../services/api';
import { getAppointmentReadableDateTime } from '../../../../../utils/date.utl';
import { notify } from '../../../../common/Alert';
import {
  ERROR_MSG,
  ERROR_TITLE,
} from '../../../../../constants/NotificationConstants';

const Appointment = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [appointments, setAppointments] = useState<AppointmentUIType[]>([]);

  const appointmentsColumnDefn: ColumnDef<AppointmentUIType>[] = [
    {
      accessorKey: 'service',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Service
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill="#808080" />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill="#808080" />
            )}
          </button>
        );
      },
    },
    {
      accessorKey: 'date',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Date and Time
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill="#808080" />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill="#808080" />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return (
          <span>
            {row.original.date
              ? getAppointmentReadableDateTime(row.original.date)
              : ''}
          </span>
        );
      },
    },
    {
      accessorKey: 'consultantName',
      header: 'Consultant',
    },
    {
      accessorKey: 'invoiceStatus',
      header: 'Invoice status',
      cell: ({ row }) => {
        return (
          <p
            className={
              'py-1 px-2 flex items-center gap-1 rounded border w-[fit-content] ' +
              (row.original.invoiceStatus === 'Pending'
                ? 'border-warning-500 bg-warning-50'
                : 'border-success-500 bg-success-50')
            }
          >
            <Dot
              fill={
                row.original.invoiceStatus === 'Pending' ? '#F59E0B' : '#22C55E'
              }
            />
            <span>{row.original.invoiceStatus}</span>
          </p>
        );
      },
    },
    // {
    //   id: 'actions',
    //   cell: ({ row }) => {
    //     return (
    //       <div className="flex gap-8">
    //         <div className="flex gap-2">
    //           <Link
    //             className={buttonVariants({
    //               variant: 'outlined',
    //               size: 'xmall',
    //             })}
    //             to={'reportview/' + row.original.id}
    //           >
    //             <OpenEyeIcon2 /> View
    //           </Link>
    //           {/* {row.original.invoiceStatus === 'unpaid' && (
    //             <Button
    //               variant={'outlined'}
    //               className="h-[30px]"
    //               trailingIcon={<SendIcon />}
    //             >
    //               Send Invoice
    //             </Button>
    //           )} */}
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        if (id) {
          setIsLoading(true);
          const [res, err] = await getAppointmentByPatientId(id);
          if (err) {
            notify.error({
              message: err.data,
              title: 'Failed to fetch appointments',
            });
            setIsLoading(false);
            return;
          }
          // Convert fetched appointments to AppointmentType and set into state
          const convertedAppointments: AppointmentUIType[] = res
            .filter((appointment: Appointment) => !appointment.cancelled)
            .map((appointment: Appointment) => ({
              id: appointment.publicId,
              service: appointment.service.name,
              date: new Date(appointment.appointmentTime),
              consultantName: appointment.staff.fullName,
              invoiceStatus: appointment.paymentStatus,
            }));
          // Sort appointments by date
          convertedAppointments.sort(
            (a, b) => b.date.getTime() - a.date.getTime()
          );
          setAppointments(convertedAppointments);
          setIsLoading(false);
        }
      } catch (error) {
        notify.error({
          title: ERROR_TITLE.GENERIC_APPTS,
          message: ERROR_MSG.GENERIC,
        });
        setIsLoading(false);
      }
    };

    fetchAppointments();
  }, []);

  const handleCreateButtonClick = () => {
    window.open('https://www.p4fertility.co.uk', '_blank');
  };

  return (
    <div>
      <div className="p-3 flex justify-between items-center">
        <h1 className="text-neutral-900 font-medium whitespace-nowrap">
          Appointments
        </h1>
        <Button
          className="h-[32px] inline-flex"
          variant={'primary'}
          leadingIcon={<OutlinedAddIcon />}
          onClick={handleCreateButtonClick}
        >
          Create
        </Button>
      </div>
      <Slider variant={'vertical'} className="h-[500px]">
        <DataTable
          columns={appointmentsColumnDefn}
          data={appointments}
          isLoading={isLoading}
        />
      </Slider>
    </div>
  );
};

export default Appointment;
