export const CONSTANT = {
  FEMALE: 'Female',
};

export const RESULT_STATUS = {
  PENDING: 'Pending',
  RESULT_AVAILABLE: 'Results Available',
};

export const PATHWAY_DEADLINE_STATUS = {
  DEADLINE_CROSSED: 'Deadline crossed',
};

export const ALERT = {
  SUCCESS: {},
  FAILURE: {},
};
