'use client';

import * as React from 'react';
import { format } from 'date-fns';
import { CalendarIcon, UpDownArrowIcon } from '../../assets/icons';

import { Button, Label } from '../UI';
import { Calendar } from '../UI';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../UI';

interface DatePickerProps {
  handleDateChange: (date: Date | null) => void;
  variant?: 'date' | 'dateTime';
  buttonClass?: string;
  showOutsideDays?: boolean;
  initialValue?: string | Date | null;
  disableAfterDate?: Date;
}

const DatePicker: React.FC<DatePickerProps> = React.memo(
  ({
    handleDateChange,
    variant = 'date',
    buttonClass = '',
    showOutsideDays,
    initialValue,
    disableAfterDate,
  }) => {
    const [date, setDate] = React.useState<Date | undefined>(new Date());
    const [hour, setHour] = React.useState<string>('00');
    const [min, setMin] = React.useState<string>('00');
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    React.useEffect(() => {
      if (!initialValue) return;
      if (typeof initialValue === 'string') {
        if (variant === 'date') {
          const parts = initialValue.split('/');
          const dateObject = new Date(
            Number(parts[2]),
            Number(parts[1]) - 1,
            Number(parts[0])
          );
          setDate(dateObject);
        } else {
          const dateString = initialValue.split(',')[0].trim();
          const timeString = initialValue.split(',')[1].trim();
          const [day, month, year] = dateString.split('/').map(Number);
          const [hours, min] = timeString.split(':');
          const dateObject = new Date(year, month - 1, day);
          setDate(dateObject);
          setHour(hours);
          setMin(min);
        }
      } else {
        setDate(initialValue);
      }
    }, []);
    const dateHandler = (e?: Date) => {
      if (e) {
        setDate(e);
        handleDateChange(e);
        if (variant == 'date') {
          setIsOpen(false);
        }
      }
    };

    const applyDate = () => {
      if (date !== undefined) {
        const [day, month, year] = date
          .toLocaleDateString()
          .split('/')
          .map(Number);

        // Create a new Date object with the parsed date and time
        const dateObject = new Date(
          year,
          month - 1,
          day,
          Number(hour),
          Number(min)
        );

        handleDateChange(dateObject);
        setIsOpen(false);
      }
    };

    const cancelHandler = () => {
      setIsOpen(false);
    };
    const [popupPosition, setPopupPosition] = React.useState<{
      top?: string;
      bottom?: string;
      left: string;
      transform: string;
    }>({
      top: '50%',
      left: '50%',
      transform: '',
    });
    React.useEffect(() => {
      const handleResize = () => {
        const windowHeight = window.innerHeight;
        const popupHeight =
          document.querySelector('.react-datepicker-popper')?.clientHeight || 0;
        const topSpace =
          window.pageYOffset + document.documentElement.clientTop;
        const bottomSpace = windowHeight - (popupHeight + topSpace);

        if (bottomSpace > 0) {
          setPopupPosition({
            top: `${topSpace + popupHeight > windowHeight ? windowHeight - popupHeight - 16 : topSpace}px`,
            left: '50%',
            transform: 'translateX(-50%)',
          });
        } else {
          setPopupPosition({
            bottom: `${topSpace < popupHeight ? popupHeight + 16 : 16}px`,
            left: '50%',
            transform: 'translateX(-50%)',
          });
        }
      };

      window.addEventListener('resize', handleResize);
      handleResize(); // Call the function initially to set the position

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return (
      <Popover open={isOpen}>
        <PopoverTrigger asChild>
          <div>
            {variant === 'date' && (
              <Button
                className={'px-[6px] py-[8px] ' + buttonClass}
                variant={'outlined'}
                size={'xmall'}
                leadingIcon={<CalendarIcon />}
                onClick={() => setIsOpen(true)}
              >
                {date ? (
                  date.toDateString() === new Date().toDateString() ? (
                    'Today'
                  ) : (
                    format(date, 'PP')
                  )
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            )}
            {variant === 'dateTime' && (
              <Button
                size={'medium'}
                variant="outlined"
                className="border-neutral-100 w-full justify-between"
                trailingIcon={<CalendarIcon />}
                onClick={() => setIsOpen(true)}
              >
                {date ? (
                  format(date, 'PP') +
                  ' at ' +
                  (hour && min ? `${hour}:${min}` : '00:00')
                ) : (
                  <span className="text-neutral-400">dd/mm/yyyy at time</span>
                )}
              </Button>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-3 z-[999999] bg-shades-0"
          style={{
            ...popupPosition,
            zIndex: 999999,
            maxHeight: '80vh',
            overflowY: 'auto',
          }}
        >
          <Calendar
            mode="single"
            selected={date}
            onSelect={e => dateHandler(e)}
            showOutsideDays={showOutsideDays}
            initialFocus
            className="p-0 z-[0]"
            disableAfterDate={disableAfterDate}
          />
          {variant === 'dateTime' && (
            <>
              <div className="border-t border-neutral-100 mt-3">
                <TimePicker
                  min={min}
                  setMin={setMin}
                  hour={hour}
                  setHour={setHour}
                />
              </div>

              <div className="border-t border-neutral-100 flex gap-3 pt-3 mt-3">
                <Button
                  variant={'link'}
                  size={'large'}
                  className="w-full"
                  onClick={cancelHandler}
                >
                  Close
                </Button>

                <Button
                  variant={'primary'}
                  size={'large'}
                  className="w-full"
                  onClick={applyDate}
                >
                  Apply
                </Button>
              </div>
            </>
          )}
        </PopoverContent>
      </Popover>
    );
  }
);

const TimePicker = ({
  hour,
  setHour,
  min,
  setMin,
}: {
  hour: string;
  setHour: (val: string) => void;
  min: string;
  setMin: (val: string) => void;
}) => {
  const hours: string[] = React.useMemo(() => {
    const hoursArray: string[] = [];
    for (let i = 0; i < 24; i++) {
      const formattedHour = i.toString().padStart(2, '0');
      hoursArray.push(formattedHour);
    }
    return hoursArray;
  }, []);
  const mins = React.useMemo(() => {
    const minsArray: string[] = [];
    for (let i = 0; i < 60; i++) {
      const formattedHour = i.toString().padStart(2, '0');
      minsArray.push(formattedHour);
    }
    return minsArray;
  }, []);
  return (
    <div className="flex justify-between gap-3">
      <div className="space-y2 w-full">
        <Label>Hour</Label>
        <Select onValueChange={value => setHour(value)}>
          <SelectTrigger className="w-full" selectIcon={<UpDownArrowIcon />}>
            <SelectValue placeholder={hour} />
          </SelectTrigger>
          <SelectContent className="bg-shades-0 z-[999999]">
            {hours.map(option => (
              <SelectItem key={option} value={option} className="w-[200px]">
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="space-y2 w-full">
        <Label>Minutes</Label>
        <Select onValueChange={value => setMin(value)}>
          <SelectTrigger className="w-full" selectIcon={<UpDownArrowIcon />}>
            <SelectValue placeholder={min} />
          </SelectTrigger>
          <SelectContent className="bg-shades-0 z-[999999]">
            {mins.map(option => (
              <SelectItem key={option} value={option} className="w-[200px]">
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export { DatePicker };
