import React, { FC } from 'react';

interface UpDownArrowIconProps {
  width?: number;
  height?: number;
}

const UpDownArrowIcon: FC<UpDownArrowIconProps> = ({
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.66602 10.002L7.99935 13.3353L11.3327 10.002"
        stroke="#808080"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66602 6.0013L7.99935 2.66797L11.3327 6.0013"
        stroke="#808080"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UpDownArrowIcon;
