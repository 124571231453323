import React, { useState } from 'react';
import { DatePicker } from '../common';
import { DateRange } from 'react-day-picker';

interface DateRangePickerProps {
  dateRange: DateRange;
  setDateRange: (value: DateRange) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  dateRange,
  setDateRange,
}) => {
  const [fromDate, setFromDate] = useState<Date | null>(dateRange.from || null);
  const [toDate, setToDate] = useState<Date | null>(dateRange.to || null);

  const handleFromDateChange = (date: Date | null | undefined) => {
    setFromDate(date ?? null);

    date && toDate && setDateRange({ from: date ?? null, to: toDate });
  };

  const handleToDateChange = (date: Date | null | undefined) => {
    setToDate(date ?? null);
    date && fromDate && setDateRange({ from: fromDate, to: date ?? null });
  };

  return (
    <div className="flex flex-row gap-3">
      <div className="flex flex-col gap-3">
        <p className="text-[14px] text-neutral-900">From Date</p>
        <DatePicker
          key={'fromDate'}
          handleDateChange={handleFromDateChange}
          initialValue={fromDate}
        />
      </div>
      <div className="flex flex-col gap-3">
        <p className="text-[14px] text-neutral-900">To Date</p>
        <DatePicker
          key={'toDate'}
          handleDateChange={handleToDateChange}
          initialValue={toDate}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
