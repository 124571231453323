import React from 'react';
import { cn } from '../../utils';
import { cva } from 'class-variance-authority';
import { BadgeProps } from '../../types/uiComponents/type';

const badgeVariants = cva(
  'px-[10px] md:px-[16px] py-[13px]  w-[fit-content] flex justify-center items-center gap-2 rounded-[4px] text-sm font-medium whitespace-nowrap',
  {
    variants: {
      variant: {
        primary:
          'text-shades-0 bg-primary-500 hover:ouline hover:outline-primary-600 focus:border-[2px] focus:border-primary-600 disabled:bg-neutral-200 disabled:text-neutral-50',
        secondary:
          'text-primary-600 border border-primary-500 hover:bg-primary-50 hover:border hover:border hover:border-primary-600 focus:border-[2px] focus:border-primary-600 disabled:bg-neutral-100 disabled:text-primary-400',
        outlined:
          'text-neutral-700 border border-neutral-700 bg-shades-0 hover:bg-neutral-50 focus:bg-neutral-100 disabled:bg-neutral-400 disabled:text-neutral-200',
        link: 'text-neutral-700 bg-[transparent] disabled:text-neutral-400 rounded-[0px] disabled:cursor-not-allowed',
        destructive:
          'text-shades-0 bg-destructive-500 hover:border hover:border-destructive-400 focus:border focus:border-destructive-400 disabled:bg-destructive-300 disabled:text-destructive-200',
        success:
          'text-shades-0 bg-success-500 hover:border hover:border-success-400 focus:border focus:border-success-400 disabled:text-success-400 disabled:bg-success-200',
      },
      size: {
        large: 'h-[44px]',
        medium: 'h-[40px]',
        small: 'h-[36px]',
        xmall: 'h-[32px]',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'large',
    },
  }
);

const Badge = React.forwardRef<HTMLButtonElement, BadgeProps>(
  (
    {
      size,
      className,
      variant,
      leadingIcon,
      trailingIcon,
      isLoading,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <span
        ref={ref}
        className={cn(badgeVariants({ variant, size, className }))}
        {...props}
      >
        {!isLoading && leadingIcon}
        {children} {/* Render children here */}
        {!isLoading && trailingIcon}
      </span>
    );
  }
);

export { Badge, badgeVariants };
