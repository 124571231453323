/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { CrossIcon, DownArrowIcon } from '../../../../assets/icons';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Slider,
} from '../../../UI';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import AutoComplete from '../../../../components/UI/AutoComplete';
import {
  getAllAllergies,
  searchPatient,
} from '../../../../services/api/endPoints/patients';
import { getFullName } from '../../../../utils/Helper';
import DebouncedSearchInput from '../../../../components/UI/DebouncedSearchInput';
// import { DatePicker } from '../../../../components/common';

const AddPatientModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [selectedPartner, setSelectedPartner] = useState<
    PatientInformation | PatientLite | null
  >();
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const [selectedStaff, setSelectedStaff] = useState<StaffInformation>(
    user.staff
  );
  const [allergies, setAllergies] = useState<string[]>([]);
  const [allAllergies, setAllAllergies] = useState<Allergy[]>([]);

  const setSelectedAllergies = (allergies: Allergy[]) => {
    setAllergies(allergies.map(allergy => allergy.allergy));
  };

  const onPartnerSearch = async (search: string): Promise<PatientLite[]> => {
    try {
      const res = await searchPatient(search);
      const data: PatientLite[] = res.data.map((row: PatientLite) => ({
        ...row,
        fullName: getFullName(row),
        phoneNo: `${row.phoneNo ? row.phoneNo : ''}`,
      }));
      return data;
    } catch (err) {
      console.error(err);
      return []; // Return empty array or handle error appropriately
    }
  };

  const onStaffSearch = async (search: string): Promise<StaffInformation[]> => {
    try {
      return staffs.filter(staff =>
        staff.fullName?.toLowerCase().includes(search.toLowerCase())
      );
    } catch (err) {
      console.error(err);
      return []; // Return empty array or handle error appropriately
    }
  };

  const getAllergies = async () => {
    try {
      const [res, err] = await getAllAllergies();

      if (err) {
        setAllAllergies([]);
        return;
      } else {
        //convert allergies (string[]) to Allergy[]
        const allergies: Allergy[] = res.map((allergy: string) => {
          return { allergy };
        });
        setAllAllergies(allergies);
      }
    } catch (err) {
      setAllAllergies([]);
    }
  };

  useEffect(() => {
    getAllergies();
  }, []);

  return (
    <div>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0  tablet:w-[800px] md:w-[887px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              Add Patient
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Slider className="flex-1" variant={'vertical'}>
            <div className="p-4 space-y-4">
              {/* Basic Information */}
              <h1 className="text-[14px] text-neutral-900 font-medium">
                Basic Information
              </h1>
              <div className="grid grid-cols-7 gap-5">
                <div className="space-y-1">
                  <Label>Title</Label>
                  <Select required name="title">
                    <SelectTrigger
                      className="w-full"
                      selectIcon={<DownArrowIcon />}
                    >
                      <SelectValue placeholder="Title" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[999999]">
                      <SelectItem value="Mr">Mr</SelectItem>
                      <SelectItem value="Ms">Ms</SelectItem>
                      <SelectItem value="Mrs">Mrs</SelectItem>
                      <SelectItem value="Dr">Dr</SelectItem>
                      <SelectItem value="Prof">Prof</SelectItem>
                      <SelectItem value="Sir">Sir</SelectItem>
                      <SelectItem value="Mx">Mx</SelectItem>
                      <SelectItem value="Madam">Madam</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="col-span-3 space-y-1">
                  <Label>First Name</Label>
                  <Input required placeholder="First Name" name="firstName" />
                </div>
                <div className="col-span-3 space-y-1">
                  <Label>Last Name</Label>
                  <Input required placeholder="Last Name" name="lastName" />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <div className="space-y-1">
                  <Label>Gender</Label>
                  <Select required name="gender">
                    <SelectTrigger
                      className="w-full"
                      selectIcon={<DownArrowIcon />}
                    >
                      <SelectValue placeholder="Select Gender" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[999999]">
                      <SelectItem value="Male">Male</SelectItem>
                      <SelectItem value="Female">Female</SelectItem>
                      <SelectItem value="Other">Other</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-1">
                  <Label>Date of Birth</Label>
                  {/* <DatePicker
                    variant="date"
                    buttonClass="w-full"
                    showOutsideDays={false}
                    handleDateChange={(date: Date | undefined) => {
                      console.log(date);
                    }}
                  /> */}
                  <Input
                    required
                    type="date"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    // value=
                    onChange={() => console.log('date')}
                    className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 block w-full"
                  />
                </div>
                <div className="space-y-1">
                  <DebouncedSearchInput
                    label={'Partner'}
                    onSearch={onPartnerSearch}
                    onSelect={option =>
                      setSelectedPartner(option as PatientLite)
                    }
                  />
                </div>
                {staffs && (
                  <div className="space-y-1">
                    {/* <Label>Consulted By</Label>
                    <AutoComplete
                      setSelectedValue={values =>
                        setSelectedStaff(values[0] || {})
                      }
                      selectedValue={[selectedStaff]}
                      options={staffs}
                      labelField={'fullName'}
                      valueField={'publicId'}
                      placeholder={'Select Consultant'}
                      className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 block w-full"
                    /> */}
                    <DebouncedSearchInput
                      required
                      label={'Consultant'}
                      onSearch={onStaffSearch}
                      onSelect={option =>
                        setSelectedStaff(option as StaffInformation)
                      }
                    />
                  </div>
                )}
                <div className="space-y-1">
                  <Label>Allergies</Label>
                  <AutoComplete
                    multi={true}
                    placeholder="Search Allergies"
                    setSelectedValue={setSelectedAllergies}
                    selectedValue={[]}
                    options={allAllergies}
                    labelField={'allergy'}
                    valueField={'allergy'}
                  />
                </div>
              </div>

              <hr />

              {/* Contact Details */}
              <h1 className="text-[14px] text-neutral-900 font-medium">
                Contact Details
              </h1>
              <div className="grid grid-cols-2 gap-5">
                <div className="space-y-1">
                  <Label>Email</Label>
                  <Input placeholder="Email" type="email" />
                </div>
                <div className="space-y-1">
                  <Label>Contact No</Label>
                  <Input placeholder="Phone Number" />
                </div>
              </div>
              <hr />

              {/* Address */}
              <h1 className="text-[14px] text-neutral-900 font-medium">
                Address
              </h1>
              <div className="grid grid-cols-2 gap-5">
                <div className="space-y-1">
                  <Label>Street 1</Label>
                  <Input placeholder="Street 1" />
                </div>
                <div className="space-y-1">
                  {/* Placeholder for Street 2, assuming optional */}
                  <Label>Street 2</Label>
                  <Input placeholder="Street 2" />
                </div>
                <div className="space-y-1">
                  <Label>City</Label>
                  <Input placeholder="City" />
                </div>
                <div className="space-y-1">
                  <Label>State</Label>
                  <Input placeholder="State" />
                </div>
                <div className="space-y-1">
                  <Label>Postal Code</Label>
                  <Input placeholder="Postal Code" />
                </div>
                <div className="space-y-1">
                  <Label>Country</Label>
                  <Input placeholder="Country" />
                </div>
              </div>
            </div>
          </Slider>
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>
            <Button size={'small'} onClick={onClose} className="w-full">
              Done
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddPatientModal;
