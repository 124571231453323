import React, { useState, useMemo } from 'react';
import { DataTable, notify, AlertDialog } from '../../../../common';
import { Button, Slider } from '../../../../UI';
import {
  AddIcon,
  DeleteIcon,
  DirectedArrowIcon,
  // FilterIcon,
  OpenEyeIcon2,
} from '../../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import { Edit2 } from 'lucide-react';
import ViewServiceModal from './ViewServiceModal';
import AddAndEditServiceModal from './AddAndEditServiceModal';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { useDispatch } from 'react-redux';
import { deleteService as deleteServiceRedux } from '../../../../../redux/slice';
import { deleteService } from '../../../../../services/api';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';

const Service = () => {
  const [isAddServiceModalOpen, setIsAddServiceModalOpen] =
    useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [isViewServiceModalOpen, setIsViewServiceModalOpen] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { services } = useSelector<RootState, ServicesState>(
    state => state.services
  );
  const [isEditServiceModalOpen, setIsEditServiceModalOpen] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const servicesColumn: ColumnDef<Service>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: ({ column }) => {
          return (
            <button
              className="flex gap-1 items-center"
              onClick={() =>
                column.toggleSorting(column.getIsSorted() === 'asc')
              }
            >
              Name
              {column.getIsSorted() === 'asc' ? (
                <div className="rotate-180">
                  <DirectedArrowIcon fill="currentColor" />{' '}
                </div>
              ) : (
                <DirectedArrowIcon fill="currentColor" />
              )}
            </button>
          );
        },
      },
      {
        accessorKey: 'type',
        header: 'Type',
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          return (
            <div className="flex justify-end">
              <div className="flex gap-2">
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  className="md:px-2 font-semibold text-[14px] gap-1"
                  trailingIcon={<OpenEyeIcon2 />}
                  onClick={() => {
                    setSelectedService(row.original);
                    setIsViewServiceModalOpen(true);
                  }}
                >
                  View
                </Button>
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  className="md:px-2 font-semibold text-[14px] gap-2"
                  trailingIcon={<Edit2 size={15} />}
                  onClick={() => {
                    setSelectedService(row.original);
                    setIsEditServiceModalOpen(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant={'outlined'}
                  size={'xmall'}
                  className="md:px-2 font-semibold text-[14px] gap-1"
                  trailingIcon={<DeleteIcon />}
                  onClick={() => {
                    setSelectedService(row.original);
                    setIsDeleteModalOpen(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );
  const deleteHandler = async () => {
    if (!selectedService) return;
    setIsDeleting(true);
    const [, err] = await deleteService(selectedService.publicId);
    if (!err) {
      dispatch(deleteServiceRedux({ publicId: selectedService.publicId }));
      notify.success({
        title: SUCCESS_TITLE.GENERIC_SERVICE_DELETE,
        message: `Service - ${name} deleted successfully`,
      });
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_SERVICE_DELETE,
        message: err.data,
      });
    }
    closeDeleteModalHandler();
  };
  const closeDeleteModalHandler = () => {
    setIsDeleting(false);
    setSelectedService(null);
    setIsDeleteModalOpen(false);
  };
  return (
    <>
      {isAddServiceModalOpen && (
        <AddAndEditServiceModal
          open={isAddServiceModalOpen}
          onClose={() => setIsAddServiceModalOpen(false)}
          type="Create"
        />
      )}
      {isEditServiceModalOpen && selectedService && (
        <AddAndEditServiceModal
          open={isEditServiceModalOpen}
          onClose={() => {
            setIsEditServiceModalOpen(false);
            setSelectedService(null);
          }}
          type="Edit"
          service={selectedService}
        />
      )}
      {isViewServiceModalOpen && selectedService && (
        <ViewServiceModal
          open={isViewServiceModalOpen}
          service={selectedService}
          onClose={() => {
            setSelectedService(null);
            setIsViewServiceModalOpen(false);
          }}
        />
      )}
      {isDeleteModalOpen && selectedService && (
        <AlertDialog
          type="delete"
          actionButtonText={!isDeleting ? 'Delete' : 'Deleting'}
          alertTitle={`Deleting ${selectedService.name}`}
          onClose={closeDeleteModalHandler}
          open={isDeleteModalOpen}
          onAction={deleteHandler}
          isActionStarted={isDeleting}
        >
          Are you sure you want to delete {selectedService.name} ?
        </AlertDialog>
      )}
      <div className="px-[12px]  py-[16px] w-full flex justify-between items-center">
        <h2 className="font-semibold text-[16px] text-neutral-900">Service</h2>
        <div className="flex gap-3 items-center justify-center">
          {/* <Button variant={'link'} leadingIcon={<FilterIcon />} size={'small'}>
            Filter
          </Button> */}
          <Button
            variant={'primary'}
            size={'small'}
            onClick={() => setIsAddServiceModalOpen(true)}
            trailingIcon={<AddIcon stroke="currentColor" />}
          >
            Add service
          </Button>
        </div>
      </div>
      <Slider variant={'vertical'} className="flex-1">
        <DataTable columns={servicesColumn} data={services} isLoading={false} />
      </Slider>
    </>
  );
};

export default Service;
