/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Format {
  publicId: string;
  name: string;
  handwrittenFormatUrl?: string;
  printFormatUrl?: string;
}

export enum FormatType {
  MEDICAL_NOTE = 'MEDICAL_NOTE',
  ADMIN_NOTE = 'ADMIN_NOTE',
  PATIENT_DETAILS = 'PATIENT_DETAILS',
  HANDWRITTEN_NOTE = 'HANDWRITTEN_NOTE',
}

export interface FormatGroup {
  publicId: string;
  name: string;
  isDefault?: true;
}

// Type is being modified based on the requirement of the UI
export interface FormFormat {
  group: FormatGroup;
  formats: Format[];
}

export interface FormFieldValue {
  formFormatFieldPublicId: string;
  fieldKey: string;
  fieldValue: string;
}

export interface FilledForm {
  publicId: string;
  draft?: boolean;
  patientFilled?: boolean;
  status?: string;
  formFormat: Format;
  patient: PatientLite;
  staff: StaffLite;
  location?: Location;
  event?: Event;
  handwrittenNoteUrl?: string;
  formFieldValues?: FormFieldValue[];
  formFieldValidationErrors?: { [key: string]: string };
  createdBy: string;
  whenCreated: Date;
  updatedBy?: string;
  whenLastUpdated?: Date;
}

export interface FillFormRequest {
  publicId?: string;
  isDraft?: boolean;
  patientFilled?: boolean;
  status?: string;
  formFormatPublicId?: string;
  patientPublicId?: string;
  staffPublicId?: string;
  locationPublicId?: string;
  eventPublicId?: string;
  handwrittenNoteUrl?: string;
  formFieldValues?: FormFieldValue[];
}

export interface CanvasJSONData {
  state: string;
  files?: string;
}

interface Field {
  publicId: string;
  name: string;
  label: string;
  fieldIndex: number;
  type:
    | 'DROPDOWN'
    | 'TEXT_FIELD'
    | 'NUMBER'
    | 'TEXT_AREA'
    | 'DATE'
    | 'DATE_TIME'
    | 'DURATION'
    | 'RADIO'
    | 'CHECKBOX'
    | 'SIGNATURE';
  options: string[];
  isRequired: boolean;
  validations: any[]; // You can specify validation types if needed
  createdBy: string;
  whenCreated: string; // This should ideally be a Date type, but keeping it as string for simplicity
  required: boolean;
}

export interface Section {
  publicId: string;
  name: string;
  label: string;
  sectionIndex: number;
  isDefault: boolean;
  fields: Field[];
  createdBy: string;
  whenCreated: string;
}

export interface FormFormatDetails {
  publicId: string;
  name: string;
  group: formatGroup;
  sections: Section[];
  createdBy: string;
  whenCreated: string;
}
