import React from 'react';

interface FolderGroupsIconProps {
  stroke?: string;
}

const FoldersGroupIcon: React.FC<FolderGroupsIconProps> = ({
  stroke = 'currentColor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.3333 11.3337C13.687 11.3337 14.0261 11.1932 14.2761 10.9431C14.5262 10.6931 14.6667 10.3539 14.6667 10.0003V6.00033C14.6667 5.6467 14.5262 5.30757 14.2761 5.05752C14.0261 4.80747 13.687 4.66699 13.3333 4.66699H10.7333C10.5103 4.66918 10.2904 4.6154 10.0935 4.51058C9.8967 4.40576 9.72931 4.25324 9.60667 4.06699L9.06667 3.26699C8.94526 3.08264 8.77998 2.93131 8.58567 2.82659C8.39135 2.72187 8.17407 2.66703 7.95333 2.66699H5.33333C4.97971 2.66699 4.64057 2.80747 4.39052 3.05752C4.14048 3.30756 4 3.6467 4 4.00033V10.0003C4 10.3539 4.14048 10.6931 4.39052 10.9431C4.64057 11.1932 4.97971 11.3337 5.33333 11.3337H13.3333Z"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.33325 5.33301V12.6663C1.33325 13.02 1.47373 13.3591 1.72378 13.6092C1.97382 13.8592 2.31296 13.9997 2.66659 13.9997H11.9999"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default FoldersGroupIcon;
