import React, { useState } from 'react';
import { CrossIcon, DownArrowIcon } from '../../../assets/icons';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../UI';
import { DatePicker } from '../../../components/common';
const ReallocateResourceModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  // eslint-disable-next-line
  const [date, setDate] = useState<Date | undefined>(new Date());

  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      setDate(newDate);
    }
  };
  return (
    <div>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0  tablet:w-[32rem] md:w-[32rem] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              Reallocate resource
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <div className="p-[1rem]">
            <div className="border border-neutral-100 p-[0.75rem] rounded">
              <h1 className="text-[14px] text-neutral-900 font-medium">
                Ultrasound scan - Resource Name
              </h1>
              <p className="text-sm text-neutral-400 font-medium">
                15:00 - 15:30 , 30 min
              </p>
              <p className="text-[14px] text-neutral-400 font-medium">₤200</p>
              <p className="text-[14px] text-neutral-400 font-medium">
                Mr. Ronald Richards
              </p>
            </div>
            <div className="mt-2">
              <Label>Resource</Label>
              <Select>
                <SelectTrigger
                  className="w-full"
                  selectIcon={<DownArrowIcon />}
                >
                  <SelectValue placeholder="Resource Name" />
                </SelectTrigger>
                <SelectContent className="bg-shades-0 z-[999999]">
                  <SelectItem value="Mr">Resource 1</SelectItem>
                  <SelectItem value="Ms">Resource 2</SelectItem>
                  <SelectItem value="Mrs">Resource 3</SelectItem>
                  <SelectItem value="Dr">Resource 4</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="pt-2 pb-2 flex flex-col gap-2">
              <div>
                <Label>Select Start Date & Time</Label>
                <DatePicker
                  variant="dateTime"
                  handleDateChange={handleDateChange}
                />
              </div>
              <div>
                <Label>Select End Date & Time (If Applicable)</Label>
                <DatePicker
                  variant="dateTime"
                  handleDateChange={handleDateChange}
                />
              </div>
            </div>
            <div className="mt-2">
              <h1 className="text-sm text-neutral-900 font-medium">
                Reallocation of resource will also reschedule the appointment of
                patient.
              </h1>
            </div>
          </div>

          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              size={'small'}
              onClick={onClose}
              className="w-full"
              variant={'primary'}
            >
              Book and Reschedule
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReallocateResourceModal;
