import React, { FC } from 'react';

const AddIcon: FC<{
  stroke?: string;
}> = ({ stroke = 'white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 4V12M12 8L4 8"
        stroke={stroke}
        strokeWidth="1.13"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddIcon;
