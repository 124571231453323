import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Slider,
  Button,
} from '../../../../UI';
import { CrossIcon, RightArrow } from '../../../../../assets/icons';
import { Link } from 'react-router-dom';
const AppointmentHistoryModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const DUMMY_APPOINTMENTS: {
    name: string;
    date: string; // for now string later convert to date obeject,
    id: string;
  }[] = [
    { name: 'Ultrasonography', date: 'April 15 - 12:30pm', id: '1' },
    { name: 'Ultrasonography', date: 'April 15 - 12:30pm', id: '1' },
    { name: 'Ultrasonography', date: 'April 15 - 12:30pm', id: '1' },
    { name: 'Ultrasonography', date: 'April 15 - 12:30pm', id: '1' },
  ];
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[424px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Appontment History
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          {DUMMY_APPOINTMENTS.map(appointement => (
            <AppointmentItem key={appointement.id} {...appointement} />
          ))}
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

const AppointmentItem = ({
  name,
  date,
  id,
}: {
  name: string;
  date: string;
  id: string;
}) => (
  <div className="flex justify-between px-3 py-[10px]">
    <div>
      <p className="font-medium text-neutral-900 text-[14px]">{name}</p>
      <p className="text-neutral-500 text-[12px]">{date}</p>
    </div>
    <Link
      className="text-neutral-900 text-[14px] font-medium flex items-center gap-2"
      to={`apointment/${id}`}
    >
      <span>View Report</span>
      <RightArrow />
    </Link>
  </div>
);

export default AppointmentHistoryModal;
