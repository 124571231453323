import ResourceBookingLayout from '../components/sections/ResourceBooking/ResourceBookingLayout';
import React from 'react';

const ResourceBooking = () => {
  return (
    <div>
      <ResourceBookingLayout />
    </div>
  );
};

export default ResourceBooking;
