import React, { FC } from 'react';

const Dot: FC<{
  fill?: string;
}> = ({ fill = '#8D5EB0' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="8"
      viewBox="0 0 7 8"
      fill="none"
    >
      <circle cx="3.5" cy="4" r="3.5" fill={fill} />
    </svg>
  );
};

export default Dot;
