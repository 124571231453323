import React, { useState, useEffect } from 'react';
import { useDebounce } from '../../hooks';
import { Input, Label } from '.';
import UserIcon from '../../assets/icons/PersonIcon';

interface DebouncedSearchInputProps {
  label: string;
  required?: boolean;
  onSearch: (searchTerm: string) => Promise<BaseEntityInformation[]>; //replace this any with an interface having fullName and publicId
  onSelect: (selectedOption: BaseEntityInformation) => void;
}

const DebouncedSearchInput: React.FC<DebouncedSearchInputProps> = ({
  label,
  required = false,
  onSearch,
  onSelect,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [options, setOptions] = useState<BaseEntityInformation[]>([]);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const results = await onSearch(debouncedSearchTerm);
        setOptions(results);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    if (debouncedSearchTerm.length > 0) {
      fetchOptions();
    } else {
      if (options.length === 0) {
        setShowOptions(false);
      } else {
        setShowOptions(true);
      }
    }
  }, [debouncedSearchTerm, onSearch]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOptions([]);
    setSearchTerm(value);
    setShowOptions(true);
  };

  const handleOptionSelect = (selectedOption: BaseEntityInformation) => {
    setSearchTerm(selectedOption.fullName || '');
    onSelect(selectedOption);
    setShowOptions(false);
  };

  return (
    <div className="relative space-y-2">
      <Label>{label}</Label>
      <div className="relative">
        <Input
          required={required}
          placeholder={`Search ${label}`}
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={() => setShowOptions(true)}
        />
        {showOptions && (
          <div className="absolute border-neutral-100 w-full max-h-[20vh] z-50 bg-white rounded-md shadow-lg overflow-y-auto ">
            {options.map(option => (
              <div
                key={option.publicId}
                className="flex items-center justify-between  w-full p-2 hover:bg-gray-100 cursor-pointer border-b"
              >
                <div
                  className="flex items-center"
                  onClick={() => handleOptionSelect(option)}
                >
                  <UserIcon />
                  <div className="ml-2">
                    <div className="text-md font-semibold">
                      {option.fullName}
                    </div>
                    <div className="text-[14px] text-neutral-500">
                      {option.publicId}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DebouncedSearchInput;
