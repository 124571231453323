import React, { ReactNode } from 'react';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="flex w-full">
      <Sidebar />
      <div className="ml-[65px] md:ml-0 w-full overflow-x-auto h-[100svh] flex flex-col">
        <Topbar />
        <div className="flex-1 px-[12px] overflow-y-auto pt-3">
          {children}
          <div className="py-3 flex justify-center">
            <p className="text-[14px] text-neutral-500">
              © Swivo Technologies 2024 | Terms of Use | Privacy Policy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
