import React, { FC } from 'react';

interface LeftIconProps {
  stroke?: string;
  width?: string;
  height?: string;
}

const LeftIcon: FC<{
  stroke?: string;
  width?: string;
  height?: string;
}> = ({ stroke = '#0A0A0A', height = '16', width = '16' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10 3.5L6 7.5L10 11.5"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeftIcon;
