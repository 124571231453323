import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Slider,
  Checkbox,
} from '../../../../UI';
import { CrossIcon, DownArrowIcon } from '../../../../../assets/icons';
import {
  getAllSites,
  getStaff,
  updateStaff,
} from '../../../../../services/api';
import { Upload } from 'lucide-react';
import { createStaff } from '../../../../../services/api';
import { notify } from '../../../../../components/common';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { getFullName } from '../../../../../utils/Helper';
import {
  addStaff,
  updateStaff as updateStaffRedux,
} from '../../../../../redux/slice';
import { useDispatch } from 'react-redux';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';

const AddAndEditStaffModal = ({
  open,
  onClose,
  ...props
}: AddAndEditModalProps) => {
  const [step, setStep] = useState<number>(1);
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const [consultantMangerOptions, setConsultantMangerOptions] = useState<
    Record<string, string>
  >({});
  const [siteOptions, setSiteOptions] = useState<Record<string, string>>({});
  const fileRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [data, setData] = useState<StaffFormInputType>({
    title: '',
    firstName: '',
    email: '',
    phoneNo: '',
    type: 'Superadmin',
    siteExternalId: '',
    lastName: '',
    username: '',
    password: '',
    secretaryPublicIds: [],
    twoFactorEnabled: false,
  });
  const [oldData, setOldData] = useState<StaffInformation | null>(null);

  const [imageURL, setImageURL] = useState<string>();
  const stepActionHandler = async () => {
    if (step == 2 || props.type === 'Edit') {
      await handleSubmit();
      return;
    }
    setStep(2);
  };

  useEffect(() => {
    const setStaffDetails = async () => {
      if (props.type !== 'Edit') return;
      if (!props.selectedStaffId) {
        notify.error({
          title: 'Unable to load staff information',
          message: 'Username not available. Please reload the page',
        });
        return;
      }
      const [res, error] = await getStaff(props.selectedStaffId);
      if (res) {
        const staff: StaffInformation = res;
        console.log(staff);
        const data: StaffFormInputType = {
          title: staff.title,
          firstName: staff.firstName,
          lastName: staff.lastName,
          email: staff.email,
          phoneNo: staff.phoneNo,
          type: staff.type,
          siteExternalId: staff.siteExternalId,
          username: staff.username,
          password: '',
          secretaryPublicIds: staff.secretaries
            ? staff.secretaries.map(value => value.publicId)
            : [],
        };
        setData(data);
        setOldData(staff);
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_STAFF_FETCH,
          message: error.data,
        });
      }
    };
    setStaffDetails();
    const staffOptions: Record<string, string> = {};
    staffs.map(staff => {
      staffOptions[staff.publicId] = getFullName(staff);
    });
    setConsultantMangerOptions(staffOptions);
    // Once the site tab code is implemented we can replace the code -getSites() with redux site state
    const getSites = async () => {
      const [response, error] = await getAllSites();
      if (response) {
        const results: Record<string, string> = {};
        response.map((element: Site) => {
          results[element.externalId] = element.name;
        });
        setSiteOptions(results);
      } else {
        console.log(error);
      }
    };
    getSites();
  }, []);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const fileInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setData(prev => ({
        ...prev,
        profileImg: files[0],
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageURL(reader.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const getRoles = (type: string) => {
      switch (type) {
        case 'Admin':
          return ['ROLE_ADMIN'];
        case 'Superadmin':
          return ['ROLE_SUPERADMIN'];
        case 'Consultant':
          return ['ROLE_CONSULTANT'];
        case 'Secretary':
          return ['ROLE_CONSULTANT'];
      }
    };
    const requestData = {
      ...data,
      roles: getRoles(data.type),
    };
    if (props.type === 'Create') {
      const [response, error] = await createStaff(requestData);
      if (response) {
        dispatch(addStaff(response));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_STAFF_ADD,
          message: `New staff - ${getFullName(response)} added successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_STAFF_ADD,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    } else if (props.type == 'Edit') {
      const [response, error] = await updateStaff({
        publicId: props.selectedStaffId,
        ...requestData,
      });
      if (response) {
        dispatch(updateStaffRedux(response));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_STAFF_UPDATE,
          message: `Staff - ${oldData ? getFullName(oldData) : data.username} updated successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_STAFF_UPDATE,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(false);
    onClose();
  };
  const consultManagerSelect = (selectValues: string[]) => {
    setData(prev => ({
      ...prev,
      secretaryPublicIds: [...selectValues],
    }));
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[70vw] md:w-[544px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type === 'Create' ? 'Add staff' : 'Update staff'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-4">
            {step === 1 ? (
              <>
                {' '}
                <div className="flex gap-4">
                  {imageURL ? (
                    <img
                      src={imageURL}
                      className="h-[80px] w-[80px] object-fit rounded-full"
                    />
                  ) : (
                    <div className="h-[80px] w-[80px] rounded-full bg-shades-100/[0.2]" />
                  )}
                  <div className="space-y-2 flex flex-col justify-center">
                    <input
                      type="file"
                      hidden
                      ref={fileRef}
                      onChange={fileInputHandler}
                      accept="image/png, image/jpeg, image/jpg"
                    />
                    <Button
                      variant={'outlined'}
                      size={'xmall'}
                      trailingIcon={<Upload size={15} />}
                      onClick={() => fileRef?.current?.click()}
                    >
                      Upload image
                    </Button>
                    <p className="text-[14px] text-neutral-500">
                      400 X 400 px jpg or png format
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-7 gap-3">
                  <div className="space-y-1">
                    <Label htmlFor="title">Title</Label>
                    <Select
                      required
                      name="title"
                      onValueChange={value =>
                        setData(prev => ({ ...prev, title: value }))
                      }
                      value={data.title}
                    >
                      <SelectTrigger
                        className="w-full"
                        selectIcon={<DownArrowIcon />}
                      >
                        <SelectValue placeholder="Title" />
                      </SelectTrigger>
                      <SelectContent className="bg-shades-0 z-[999999]">
                        <SelectItem value="Mr.">Mr</SelectItem>
                        <SelectItem value="Ms.">Ms</SelectItem>
                        <SelectItem value="Mrs.">Mrs</SelectItem>
                        <SelectItem value="Sir">Sir</SelectItem>
                        <SelectItem value="Mix">Mx</SelectItem>
                        <SelectItem value="Madam">Madam</SelectItem>
                        <SelectItem value="Dr.">Dr</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="col-span-3 space-y-1">
                    <Label htmlFor="firstName">First name</Label>
                    <Input
                      placeholder="Enter first name"
                      id="firstName"
                      name="firstName"
                      onChange={handleInput}
                      value={data.firstName}
                    />
                  </div>
                  <div className="col-span-3 space-y-1">
                    <Label htmlFor="lastName">Last name</Label>
                    <Input
                      placeholder="Enter last name"
                      id="lastName"
                      name="lastName"
                      onChange={handleInput}
                      value={data.lastName}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    placeholder="Enter email"
                    name="email"
                    onChange={handleInput}
                    type="email"
                    disabled={props.type === 'Edit'}
                    value={data.email}
                  />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="phoneNo">Phone number</Label>
                  <Input
                    placeholder="Enter phone number"
                    id="phoneNo"
                    name="phoneNo"
                    type="tel"
                    onChange={handleInput}
                    value={data.phoneNo}
                  />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="type">Staff type</Label>
                  <Select
                    required
                    name="type"
                    onValueChange={value =>
                      setData(prev => ({ ...prev, type: value }))
                    }
                    value={data.type}
                  >
                    <SelectTrigger
                      className="w-full"
                      selectIcon={<DownArrowIcon />}
                    >
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[999999]">
                      <SelectItem value="Admin">Admin</SelectItem>
                      <SelectItem value="Superadmin">Superadmin</SelectItem>
                      <SelectItem value="Consultant">Consultant</SelectItem>
                      <SelectItem value="Secretary">Secretary</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-1">
                  {siteOptions && (
                    <>
                      <Label htmlFor="site">Staff site</Label>
                      <Select
                        required
                        name="siteExternalId"
                        value={data.siteExternalId}
                        onValueChange={value =>
                          setData(prev => ({
                            ...prev,
                            siteExternalId: value,
                          }))
                        }
                      >
                        <SelectTrigger
                          className="w-full"
                          selectIcon={<DownArrowIcon />}
                        >
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                        <SelectContent className="bg-shades-0 z-[999999]">
                          {Object.keys(siteOptions).map(key => (
                            <SelectItem value={key} key={key}>
                              {siteOptions[key]}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>{' '}
                    </>
                  )}
                </div>
                <div className="space-y-1">
                  <Label htmlFor="consultantManager">Consultant manager</Label>
                  <MultipleSelect
                    options={consultantMangerOptions}
                    selectedValues={data.secretaryPublicIds}
                    setSelectedValues={consultManagerSelect}
                  />
                </div>{' '}
              </>
            ) : (
              props.type === 'Create' && (
                <>
                  <div className="space-y-1">
                    <Label htmlFor="username">Username</Label>
                    <Input
                      placeholder="Enter username"
                      id="username"
                      name="username"
                      onChange={handleInput}
                      value={data.username}
                    />
                  </div>
                  <div className="space-y-1">
                    <Label htmlFor="password">Password</Label>
                    <Input
                      placeholder="Enter password"
                      id="password"
                      name="password"
                      type="password"
                      onChange={handleInput}
                      value={data.password}
                    />
                  </div>
                  <div>
                    <Checkbox
                      id="isTwoFactorEnable"
                      onCheckedChange={checked =>
                        setData(prev => ({
                          ...prev,
                          twoFactorEnabled: !!checked,
                        }))
                      }
                    />{' '}
                    Enable two Factor Authentication
                  </div>
                </>
              )
            )}
          </div>
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            onClick={stepActionHandler}
            className="w-full"
            isLoading={isLoading}
            disabled={isLoading}
            variant={'primary'}
          >
            {props.type === 'Create' && step === 1
              ? 'Continue'
              : props.type === 'Edit'
                ? 'Save staff'
                : 'Add staff'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const MultipleSelect = ({
  options,
  selectedValues,
  setSelectedValues,
}: {
  options: Record<string, string>;
  selectedValues: string[];
  setSelectedValues: (value: string[]) => void;
}) => {
  const onOptionSelect = (value: string) => {
    if (value === 'Select') return;
    const newSelectedValues = [...selectedValues];
    const selectIndex = newSelectedValues.findIndex(
      selectValue => selectValue === value
    );
    if (selectIndex >= 0) {
      newSelectedValues.splice(selectIndex, 1);
      setSelectedValues(newSelectedValues);
    } else {
      newSelectedValues.push(value);
      setSelectedValues(newSelectedValues);
    }
  };
  return (
    <>
      <Select
        required
        defaultValue="Select"
        name="multiselect"
        onValueChange={onOptionSelect}
      >
        <SelectTrigger className="w-full" selectIcon={<DownArrowIcon />}>
          <SelectValue placeholder="Select" />
        </SelectTrigger>
        <SelectContent className="bg-shades-0 z-[999999]">
          <SelectItem value={'Select'}>Select</SelectItem>
          {Object.keys(options).map(key => (
            <SelectItem value={key} key={key}>
              {options[key]}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {selectedValues.length > 0 && (
        <div className="border border-neutral-100 rounded-lg border-collapse p-3 space-y-3">
          {selectedValues.map(value => (
            <div key={value} className="w-full flex justify-between ">
              <p className="text-[14px]">{options[value]}</p>
              <Button
                onClick={() => onOptionSelect(value)}
                variant={'link'}
                size={'xmall'}
              >
                <CrossIcon />
              </Button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default AddAndEditStaffModal;
