import React from 'react';

interface LabIconProps {
  stroke?: string;
}

const LabIcon: React.FC<LabIconProps> = ({ stroke = 'currentColor' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M14.4999 4.66667L5.04657 14.12C4.69221 14.4704 4.21358 14.6664 3.71519 14.6652C3.21681 14.6639 2.73917 14.4656 2.38657 14.1133C2.0332 13.7596 1.83472 13.28 1.83472 12.78C1.83472 12.28 2.0332 11.8004 2.38657 11.4467L11.8332 2"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.1667 1.33301L15.1667 5.33301"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.50008 10.667H3.16675"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default LabIcon;
