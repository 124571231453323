import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button, Slider } from '../UI';
import {
  ArrowUpIcon,
  ClockIcon,
  LocationIcon,
  OutlinedUserIcon,
} from '../../assets/icons';

const BoardLoader = () => {
  return (
    <div>
      <div className="border border-neutral-100 rounded-lg">
        <div className={'p-2  flex items-center justify-between  border-b'}>
          <h1 className="font-semibold text-[16px] text-neutral-900">
            Patient Journey
          </h1>
          <Button
            trailingIcon={<ArrowUpIcon />}
            variant={'link'}
            size={'small'}
          >
            {'Collapse'}
          </Button>
        </div>
        <div>
          <div className="flex gap-3 p-3">
            <div
              className={
                ' py-1 px-1.5 rounded-[112px] flex items-center gap-2 cursor-pointer '
              }
            >
              <span className={'font-medium text-[14px] flex gap-2'}>
                <Skeleton width={170} height={30} />{' '}
                <Skeleton width={170} height={30} />
                <Skeleton width={170} height={30} />
              </span>
            </div>
          </div>
          <Slider variant={'horizontal'} className="p-3">
            {Array.from({ length: 4 }, (_, index) => (
              <div key={index} className="flex gap-4 mr-3">
                <div className="h-[367px] w-[300px] p-3 rounded-lg border border-neutral-100 bg-neutral-50 space-y-2 flex flex-col">
                  <div className="flex gap-2 items-center">
                    <p className="font-medium text-[14px]">
                      <Skeleton width={170} height={25} />
                    </p>
                  </div>
                  <Slider variant={'vertical'} className="flex-grow gap-2">
                    {Array.from({ length: 2 }, (_, index) => (
                      <div
                        key={index}
                        className="cursor-grab p-4 rounded border border-neutral-100 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.08)] bg-shades-0"
                      >
                        <p className="font-medium text text-sm text-neutral-900">
                          <Skeleton width={170} height={25} />
                        </p>
                        <div className="grid grid-cols-2">
                          <p className="flex items-center font-medium text text-sm text-neutral-600">
                            <OutlinedUserIcon />
                            <span className="ml-2">
                              <Skeleton width={80} height={25} />
                            </span>
                          </p>
                          <p className="flex items-center font-medium text text-sm text-neutral-600">
                            <LocationIcon />{' '}
                            <span className="ml-2">
                              <Skeleton width={80} height={25} />
                            </span>
                          </p>
                        </div>
                        <p className="flex items-center gap-2 font-medium text text-sm text-neutral-600">
                          <ClockIcon /> <Skeleton width={40} height={25} /> -
                          <Skeleton width={40} height={25} />
                        </p>
                      </div>
                    ))}{' '}
                  </Slider>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default BoardLoader;
