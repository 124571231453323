import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const LabTestLoader = () => {
  return (
    <div>
      <SkeletonTheme borderRadius="0.5rem" duration={4}>
        <Skeleton width={650} height={35} />
        <div className="flex flex-wrap space-x-1">
          <Skeleton width={300} height={45} />
          <Skeleton width={100} height={45} />{' '}
          <Skeleton width={80} height={45} />{' '}
          <Skeleton width={120} height={45} />
          <Skeleton width={200} height={45} />
          <Skeleton width={200} height={45} />
          <Skeleton width={200} height={45} />
          <Skeleton width={150} height={45} />
          <Skeleton width={220} height={45} />
          <Skeleton width={270} height={45} />
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default LabTestLoader;
