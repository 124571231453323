import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Button,
} from '../UI';
import { CrossIcon } from '../../assets/icons';
import { Slider } from '../UI';
const AlertDialog = ({
  open,
  onClose,
  onAction,
  alertTitle,
  alertIcon,
  type,
  actionButtonText,
  isActionStarted = false,
  children,
}: {
  open: boolean;
  onClose: () => void;
  onAction: () => void;
  type: 'delete' | 'default' | 'warning';
  alertTitle: string;
  alertIcon?: ReactNode;
  actionButtonText: string;
  isActionStarted?: boolean;
  children: ReactNode;
}) => {
  const getButtonVariant = (type: 'delete' | 'default' | 'warning') => {
    switch (type) {
      case 'default':
        return 'primary';
      case 'delete':
        return 'destructive';
      case 'warning':
        return 'warning';
    }
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[70vw] md:w-[30%] max-h-[70%] flex flex-col gap-0">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <div className="flex gap-1">
            {!!alertIcon && alertIcon}
            <h1 className="text-[14px] text-neutral-900 font-medium">
              {alertTitle}
            </h1>
          </div>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider variant={'vertical'} className="p-2 min-h-[70px] flex-1">
          {children}
        </Slider>
        <DialogFooter className="flex gap-3 w-full ">
          <Button
            variant={'link'}
            size={'medium'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'medium'}
            variant={getButtonVariant(type)}
            onClick={() => {
              onAction();
            }}
            className="w-full"
            isLoading={isActionStarted}
            disabled={isActionStarted}
          >
            {actionButtonText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AlertDialog;
