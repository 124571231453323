import { RootState } from '@/redux/store/store';
import {
  AdminView,
  ConsultantManagerView,
  ConsultantView,
} from '../components/sections';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAllLocations, getAllServices, getAllStaff } from '../services/api';
import { addLocations, addServices, addStaffs } from '../redux/slice';
import { getFullName } from '../utils/Helper';
import { PAGE_TITLE } from '../constants/TitleConstants';

const Overview: React.FC = () => {
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const locationsState = useSelector<RootState, Location[]>(
    state => state.locations.locations
  );
  const servicesState = useSelector<RootState, Service[]>(
    state => state.services.services
  );
  const staffsState = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const dispatch = useDispatch();
  const getRoleBasedDisplay = (role: UserType) => {
    switch (role) {
      case 'Admin':
        return <AdminView />;
      case 'Superadmin':
        return <ConsultantView />;
      case 'ConsultantManager':
        return <ConsultantManagerView />;
      case 'Consultant':
        return <ConsultantView />;
    }
  };
  useEffect(() => {
    document.title = PAGE_TITLE.OVERVIEW;
    const getInitialData = async () => {
      console.log('getInitialData');
      try {
        if (servicesState.length === 0) {
          const [services] = await getAllServices();
          dispatch(addServices(services));
        }
        if (locationsState.length === 0) {
          const [locations] = await getAllLocations();
          dispatch(addLocations(locations));
        }
        if (staffsState.length === 0) {
          const [staffs] = await getAllStaff();
          const allStaffs = staffs.map((staff: StaffInformation) => ({
            ...staff,
            fullName: getFullName(staff),
          }));

          dispatch(addStaffs(allStaffs));
        }
      } catch (error) {
        console.error('Error during login:', error);
      }
      console.log('getInitialData done');
    };

    getInitialData();
  }, []);

  // return (
  //   <Layout>
  //     <Topbar />
  //     {user && getRoleBasedDisplay('consultantManager')}
  //     {/* {getRoleBasedDisplay('admin')} */}
  //   </Layout>
  // );
  return <>{getRoleBasedDisplay(user.staff.type)}</>;
};

export default Overview;
