import React, { useEffect, useState } from 'react';
import { Button } from '../../../../UI';
import {
  DownloadIcon,
  LeftArrowIcon,
  PrinterIcon,
} from '../../../../../assets/icons';
import LoaderIcon from '../../../../../assets/icons/Loader.svg';
import PdfViewer from '../../../../common/PdfViewer';
import { useNavigate, useParams } from 'react-router-dom';
import { getDocument } from '../../../../../services/api';
import { useDownloadFile } from '../../../../../hooks/useDownloadFile';
import { usePrintFile } from '../../../../../hooks/usePrintFile';
import { notify } from '../../../../common/Alert';
import {
  ERROR_MSG,
  ERROR_TITLE,
} from '../../../../../constants/NotificationConstants';

const DocumentView = () => {
  const { fileId } = useParams();
  const [loading, isLoading] = useState<boolean>(false);
  const [file, setFile] = useState<Document>();
  const [fileObjUrl, setFileObjUrl] = useState<string>();
  const navigate = useNavigate();
  const { getFileSrc, downloadFile } = useDownloadFile();
  const printFile = usePrintFile();

  useEffect(() => {
    const downloadFileBlob = async () => {
      try {
        if (!fileId) return;
        isLoading(true);
        const [getDocRes, getDocErr] = await getDocument(fileId, '', '');
        if (getDocErr) {
          notify.error({
            message: getDocErr.data,
            title: 'Failed to fetch document',
          });
          isLoading(false);
          return;
        }
        if (!getDocRes || getDocRes.length === 0) {
          notify.error({
            message: ERROR_MSG.GENERIC,
            title: ERROR_TITLE.GENERIC_DOCS,
          });
          isLoading(false);
          return;
        }
        const file = getDocRes[0];
        if (!file || !file.url || file.type === 'Folder') {
          isLoading(false);
          return;
        }

        setFile(file);
        const [fileObjUrl, fileDownloadErr] = await getFileSrc(file.url);
        if (fileDownloadErr) {
          notify.error({
            message: fileDownloadErr.data,
            title: ERROR_TITLE.GENERIC_DOCS,
          });
          isLoading(false);
          return;
        }

        if (fileObjUrl) setFileObjUrl(fileObjUrl);
      } catch (error) {
        notify.error({
          message: ERROR_MSG.GENERIC,
          title: ERROR_TITLE.GENERIC_DOCS,
        });
      }
      isLoading(false);
    };

    downloadFileBlob();

    return () => {
      if (fileObjUrl) window.URL.revokeObjectURL(fileObjUrl);
    };
  }, []);

  const downloadFileToDevice = () => {
    if (file && file.url) {
      downloadFile(file.url, file.name);
    }
  };

  const isImage = (url?: string) => {
    return url && url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
  };

  const isPdf = (url?: string) => {
    return url && url.toLowerCase().match(/\.(pdf)$/) != null;
  };

  const isSupportedFile = (url?: string) => {
    return isImage(url) || isPdf(url);
  };

  return (
    <>
      <div className="border-b border-neutral-100 p-3 flex justify-between items-center">
        <Button
          variant={'outlined'}
          size={'small'}
          leadingIcon={<LeftArrowIcon />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <h1 className="text-neutral-900 font-medium whitespace-nowrap">
          {file?.name}
        </h1>
        <div className="flex gap-3">
          <Button
            variant="outlined"
            size={'small'}
            leadingIcon={<DownloadIcon />}
            disabled={!file?.url || loading}
            onClick={downloadFileToDevice}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            size={'small'}
            leadingIcon={<PrinterIcon />}
            onClick={() => printFile(fileObjUrl)}
            disabled={!isSupportedFile(file?.url) || loading || !fileObjUrl}
          >
            Print
          </Button>
        </div>
      </div>
      <div className="flex item-center justify-center overflow-y-auto">
        {loading && (
          <div className="flex flex-col justify-center items-center h-[500px]">
            <img src={LoaderIcon} className="w-[40px] h-[40px] animate-spin" />
          </div>
        )}
        {!loading && fileObjUrl && isPdf(file?.url) && (
          <PdfViewer pdfUrl={fileObjUrl} />
        )}
        {!loading && fileObjUrl && isImage(file?.url) && (
          <img src={fileObjUrl} alt={file?.name} />
        )}
        {!loading && fileObjUrl && !isSupportedFile(file?.url) && (
          <div className="flex flex-col justify-center items-center h-[500px]">
            <h1 className="text-neutral-900 font-medium">
              File type not supported
            </h1>
            <p className="text-neutral-500">Please download the file to view</p>
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentView;
