import { LinkIcon, LockIcon, OutlinedAddIcon } from '../../../assets/icons';
import { Button } from '../../UI';
import React from 'react';

const ActionBar = () => {
  console.log(LinkIcon, LockIcon, OutlinedAddIcon, Button);
  return (
    <div className="flex gap-3 w-full my-5 flex items-center">
      <p className="font-medium text-neutral-600">Quick Actions</p>
      <div className="flex items-center rounded-md border border-neutral-100">
        <Button
          variant="link"
          leadingIcon={<LockIcon />}
          className="px-[16px] border-r border-neutral-100 rounded-l-md"
          size={'medium'}
        >
          Reset Password
        </Button>
        <Button
          variant="link"
          leadingIcon={<LinkIcon />}
          className="px-[16px] border-r border-neutral-100 text-[14px]"
          size={'medium'}
        >
          Reset Password
        </Button>
        <Button
          variant="link"
          leadingIcon={<OutlinedAddIcon stroke="#0A0A0A" />}
          className="px-[16px] rounded-r-md"
          size={'medium'}
        >
          Add action
        </Button>
      </div>
    </div>
  );
};

export default ActionBar;
