import React from 'react';

type LoaderIcon2Props = {
  stroke?: string;
};

const LoaderIcon2: React.FC<LoaderIcon2Props> = ({
  stroke = 'currentColor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="16"
    height="16"
    className="animate-spin"
  >
    <circle
      cx="8"
      cy="8"
      r="7"
      fill="none"
      stroke={stroke}
      strokeWidth="1.5"
      strokeDasharray="12.57"
      transform="rotate(45 8 8)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 8 8"
        to="360 8 8"
        dur="2s"
        repeatCount="indefinite"
      />
    </circle>
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
  </svg>
);

export default LoaderIcon2;
