export const usePrintFile = (): ((fileObjUrl?: string) => void) => {
  const printFile = (fileObjUrl?: string) => {
    //TODO: Not working on iPad
    if (fileObjUrl) {
      const iframe = document.createElement('iframe');
      document.body.appendChild(iframe);

      iframe.style.display = 'none';
      iframe.src = fileObjUrl;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
    }
  };

  return printFile;
};
