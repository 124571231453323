import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import { Button } from '../../UI';
import { CameraIcon, LeftArrowIcon, RightArrow } from '../../../assets/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import Cropper, { ReactCropperElement } from 'react-cropper';
import './constants/cropper.css';
import Camera from './Camera';
import { useScanner } from './ScannerContextProvider';

const Scanner = () => {
  const webcamRef = useRef<Webcam>(null);
  const cropperRef = useRef<ReactCropperElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    onInstantEdit,
    onInstantReatake,
    onSaveImage,
    imageToBeModified,
    images,
  } = useScanner();

  const capture = () => {
    const imageSrc = webcamRef.current?.getScreenshot() || '';
    onInstantEdit(imageSrc);
  };

  const save = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) onSaveImage(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <>
      <div className="p-3">
        <div className="custom-height flex flex-col h-full border rounded border-neutral-100">
          <div className="flex-none relative flex justify-center items-center p-3 border-b border-b-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-medium">
              Scan Documents
            </h1>
            <Button
              className="absolute left-3 top-2"
              leadingIcon={<LeftArrowIcon />}
              variant={'outlined'}
              size={'xmall'}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </div>
          <div className="flex-auto overflow-y-auto flex flex-1 flex-col items-center justify-center gap-2">
            {!imageToBeModified ? (
              <Camera ref={webcamRef} />
            ) : (
              <Cropper
                src={imageToBeModified}
                // Cropper.js options
                initialAspectRatio={12 / 16}
                guides={false}
                ref={cropperRef}
                dragMode={'none'}
                zoomable={false}
              />
            )}
          </div>
          <div className="flex-none w-full flex justify-between p-3 border-t border-neutral-100 items-center">
            {images.length > 0 ? (
              <div className="relative">
                <img
                  className="w-[64px] h-[65px] object-cover"
                  src={images[images.length - 1].croppedImg}
                  alt="image"
                />
                <div className="rounded-full bg-black text-white h-[20px] w-[20px] absolute top-[-5px] right-[-5px] flex items-center justify-center">
                  <p>{images.length}</p>
                </div>
              </div>
            ) : (
              <div className="w-[64px] h-[64px] bg-neutral-100"></div>
            )}
            {!imageToBeModified ? (
              <>
                {' '}
                <Button
                  variant={'outlined'}
                  className="rounded-full w-[64px] h-[64px] p-0"
                  onClick={capture}
                >
                  <CameraIcon />
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    console.log('Going to preview: ', location.state);
                    navigate('preview', { state: location.state });
                  }}
                  disabled={images.length === 0}
                  variant={'primary'}
                  trailingIcon={<RightArrow />}
                >
                  Done
                </Button>
              </>
            ) : (
              <div className="flex gap-3">
                <Button
                  variant={'outlined'}
                  size={'small'}
                  onClick={onInstantReatake}
                >
                  Retake
                </Button>
                <Button
                  size={'small'}
                  onClick={save}
                  trailingIcon={<RightArrow />}
                >
                  Continue
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Scanner;
