import React from 'react';

import { Navigate, Route } from 'react-router-dom';
// import Progress from './Progress/Progress';
// import AdministrativeNotes from './AdministrativeNotes/AdministrativeNotes';
import MedicalNotes from './MedicalNotes/MedicalNotes';
import CreateMedicalNotes from './MedicalNotes/CreateMedicalNotes';
import MedicalReportView from './MedicalNotes/MedicalReportView';
import Appointment from './Appointment/Appointment';
import AppointmentReportView from './Appointment/AppointmentReportView';
import Labs from './Labs/Labs';
import LabTestForm from './Labs/LabTestForm';
import Prescriptions from './Prescription/Prescriptions';
import PrescriptionForm from './Prescription/PrescriptionForm';
import Documents from './Documents/Documents';
import Recommended from './Recommended/Recommended';
import Notes from './Notes/Notes';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import PrescriptionView from './Prescription/PrescriptionView';
import DocumentView from './Documents/DocumentView';
import HandWrittenNotes from './HandWrittenNotes/HandWrittenNotes';
import { FormatType } from '../../../../types/form/type.d';
import LabResults from './Labs/LabResults';
import { PATIENT_ROUTES } from '../../../../constants/NavigationConstants';

const PatientInfoTabsRouting = () => {
  const patient = useSelector<RootState, PatientInformation>(
    state => state.patient.patient
  );
  return (
    <>
      <Route
        index
        element={<Navigate to={PATIENT_ROUTES.ADMIN_FORMS.HOME} replace />}
      />
      {/* <Route path="progress" element={<Progress />} /> */}
      <Route
        path={PATIENT_ROUTES.ADMIN_FORMS.HOME}
        element={
          <MedicalNotes
            key={FormatType.ADMIN_NOTE}
            type={FormatType.ADMIN_NOTE}
          />
        }
      />
      <Route
        path={`${PATIENT_ROUTES.ADMIN_FORMS.CREATE}/:formatId`}
        element={<CreateMedicalNotes />}
      />
      <Route
        path={`${PATIENT_ROUTES.ADMIN_FORMS.VIEW}/:publicId`}
        element={<MedicalReportView />}
      />
      <Route
        path={PATIENT_ROUTES.MEDICAL_REPORTS.HOME}
        element={<MedicalNotes key={FormatType.MEDICAL_NOTE} />}
      />
      <Route
        path={`${PATIENT_ROUTES.MEDICAL_REPORTS.CREATE}/:formatId`}
        element={<CreateMedicalNotes />}
      />
      <Route
        path={`${PATIENT_ROUTES.MEDICAL_REPORTS.VIEW}/:publicId`}
        element={<MedicalReportView />}
      />
      <Route
        path={PATIENT_ROUTES.HANDWRITTEN_NOTES.HOME}
        element={
          <MedicalNotes
            key={FormatType.ADMIN_NOTE}
            type={FormatType.ADMIN_NOTE}
          />
        }
      />
      <Route
        path={`${PATIENT_ROUTES.HANDWRITTEN_NOTES.CREATE}/:formatId`}
        element={<HandWrittenNotes />}
      />
      <Route path={PATIENT_ROUTES.RECOMMENDED.HOME} element={<Recommended />} />
      <Route path={PATIENT_ROUTES.APPOINTMENT.HOME} element={<Appointment />} />
      <Route
        path={`${PATIENT_ROUTES.APPOINTMENT.VIEW}/:reportId`}
        element={<AppointmentReportView />}
      />
      <Route path={PATIENT_ROUTES.LABS.HOME} element={<Labs />} />
      <Route
        path={PATIENT_ROUTES.LABS.ADD}
        element={<LabTestForm patient={patient} />}
      />
      <Route path={PATIENT_ROUTES.LABS.VIEW} element={<LabResults />} />
      <Route
        path={PATIENT_ROUTES.PRESCRIPTION.HOME}
        element={<Prescriptions />}
      />
      <Route
        path={PATIENT_ROUTES.PRESCRIPTION.CREATE}
        element={<PrescriptionForm />}
      />
      <Route
        path={`${PATIENT_ROUTES.PRESCRIPTION.VIEW}/:prescriptionId`}
        element={<PrescriptionView />}
      />
      {/* TODO: Need to add id based routing */}
      <Route path={PATIENT_ROUTES.DOCUMENTS.HOME} element={<Documents />} />
      <Route
        path={`${PATIENT_ROUTES.DOCUMENTS.VIEW}/:fileId`}
        element={<DocumentView />}
      />
      <Route path={PATIENT_ROUTES.NOTES.HOME} element={<Notes />} />
    </>
  );
};

export default PatientInfoTabsRouting;
