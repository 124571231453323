import { BASIC_COLORS } from '../../constants/ColorConstants';
import React from 'react';

const MedicalNoteIcon = ({
  stroke = BASIC_COLORS.THEME_BLACK,
}: {
  stroke?: string;
}) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3335 14.5H13.3335C13.6871 14.5 14.0263 14.3595 14.2763 14.1095C14.5264 13.8594 14.6668 13.5203 14.6668 13.1667V11.8333H6.66683V13.1667C6.66683 13.5203 6.52635 13.8594 6.2763 14.1095C6.02626 14.3595 5.68712 14.5 5.3335 14.5ZM5.3335 14.5C4.97987 14.5 4.64074 14.3595 4.39069 14.1095C4.14064 13.8594 4.00016 13.5203 4.00016 13.1667V3.83333C4.00016 3.47971 3.85969 3.14057 3.60964 2.89052C3.35959 2.64048 3.02045 2.5 2.66683 2.5C2.31321 2.5 1.97407 2.64048 1.72402 2.89052C1.47397 3.14057 1.3335 3.47971 1.3335 3.83333V5.83333H4.00016"
        stroke={stroke}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6665 11.8333V3.83333C12.6665 3.47971 12.526 3.14057 12.276 2.89052C12.0259 2.64048 11.6868 2.5 11.3332 2.5H2.6665"
        stroke={stroke}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99984 5.83301H6.6665"
        stroke={stroke}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99984 8.5H6.6665"
        stroke={stroke}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MedicalNoteIcon;
