import { cn } from '../../utils';
import React from 'react';
const Loader = ({ className = '' }: { className?: string }) => {
  return (
    <div className="flex justify-center items-center">
      <div
        className={cn(
          'h-8 w-8 animate-spin rounded-full border-4 border-current !border-e-transparent',
          className
        )}
      ></div>
    </div>
  );
};

export { Loader };
