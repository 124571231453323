import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  Slider,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
import { getFullName } from '../../../../../utils/Helper';
import { getAllSites, getStaff } from '../../../../../services/api';
import Skeleton from 'react-loading-skeleton';
import { notify } from '../../../../../components/common';
import { ERROR_TITLE } from '../../../../../constants/NotificationConstants';

const ViewStaff = ({ open, onClose, selectedStaffId }: ViewStaffModalProps) => {
  const [staff, setStaff] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const getStaffDetails = async () => {
      if (!selectedStaffId) {
        return;
      }
      setIsLoading(true);
      // Once the site tab code is implemented we can replace the code with redux site state
      const [response] = await getAllSites();
      setIsLoading(true);
      const [res, error] = await getStaff(selectedStaffId);
      if (res) {
        const getSiteValue = (siteExternalId: string) => {
          const siteData = response.find(
            (siteData: Site) => siteData.externalId === siteExternalId
          );
          if (!siteData) return 'No site is added';
          return siteData.name;
        };
        const data: {
          label: string;
          value: string;
        }[] = [
          { label: 'Public ID', value: res.publicId },
          { label: 'Name', value: getFullName(res) },
          { label: 'Email', value: res.email },
          { label: ' Phone number', value: res.phoneNo },
          { label: 'Staff type', value: res.type },
          {
            label: 'Site',
            value: response
              ? getSiteValue(res.siteExternalId)
              : 'Unable to get the information',
          },
          { label: 'Username', value: res.username },
        ];
        setStaff(data);
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_STAFF_FETCH,
          message: error.data,
        });
      }
      setIsLoading(false);
    };
    getStaffDetails();
  }, []);
  const closeHandler = () => {
    onClose();
  };
  if (!selectedStaffId) {
    onClose();
    return <></>;
  }

  const renderInformation = (label: string, value: string) => {
    return (
      <>
        <div className="cols-span-1 ">
          <p className="text-[14px] font-semibold text-neutral-500">{label}</p>
        </div>
        {
          <div className="cols-span-1">
            <p className="text-[14px] font-semibold text-neutral-900">
              {value}
            </p>
          </div>
        }
      </>
    );
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Staff detail
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={closeHandler}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          {isLoading ? (
            <div className="grid grid-cols-2 p-4  gap-2 ">
              {Array.from({ length: 7 }, (_, index) => {
                return (
                  <>
                    <div key={index} className="cols-span-1 flex items-center">
                      <Skeleton width={150} height={20} />
                    </div>
                    <div className="cols-span-1 flex items-center">
                      <Skeleton width={150} height={20} />
                    </div>
                  </>
                );
              })}
            </div>
          ) : staff ? (
            <div className="grid grid-cols-2  p-4  gap-2 ">
              {staff.map(({ label, value }) => renderInformation(label, value))}
            </div>
          ) : (
            <div className="h-[400px] flex items-center justify-center">
              <p className="text-[14px] text-neutral-900 font-semibold">
                No information found
              </p>
            </div>
          )}
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

export default ViewStaff;
