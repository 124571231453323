/* eslint-disable quotes */
import { format } from 'date-fns';

const getNextDate = (date: Date) => {
  const nextDate = new Date(date);
  nextDate.setDate(date.getDate() + 1);
  return nextDate;
};

const getPreviousDate = (date: Date) => {
  const previousDate = new Date(date);
  previousDate.setDate(date.getDate() - 1);
  return previousDate;
};

const getTimestamp = () => {
  const currentDate = new Date();
  const timestampInMs = currentDate.getTime();
  return timestampInMs;
};

const addDaysToDateTime = (
  dateTimeString: Date,
  days: number
): { newDate: string; isPast: boolean } => {
  // Convert the dateTimeString to a Date object
  const startDate = new Date(dateTimeString);

  // Calculate the end date by adding days to the start date
  const endDate = new Date(startDate.getTime());
  endDate.setDate(endDate.getDate() + days);

  // Check if the endDate is greater than the current date (Date.now())
  const isPast = endDate.getTime() < Date.now();

  // Get the locale options for date and time formatting
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',

    hour12: false,
  };

  // Convert the Date object to a formatted string using the locale options
  const formattedDateTime = endDate.toLocaleString(undefined, options);

  // Return an object with the new date value and a boolean indicating if it's in the past or not
  return {
    newDate: formattedDateTime,
    isPast: isPast,
  };
};

const formatToDateTimeCalendar = (dateString: string): string => {
  try {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC', // Use the desired time zone here
    };

    return new Intl.DateTimeFormat('en-UK', options).format(date).toUpperCase();
  } catch (err) {
    console.error(dateString, err);
    return '';
  }
};

const addMinutesToDateTime = (
  dateString: string,
  additionalMin: number
): string => {
  try {
    const date = new Date(dateString);
    const newDate = new Date(date.getTime() + additionalMin * 60000); // 60000 milliseconds in a minute

    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC', // Use the desired time zone here
    };

    return new Intl.DateTimeFormat('en-UK', options)
      .format(newDate)
      .toUpperCase();
  } catch (err) {
    console.error(dateString, err);
    return '';
  }
};
const getHumanReadableTime = (time: string | number): string => {
  const inputDate = new Date(time);
  // Calculate the difference in milliseconds
  // const timeDifference = inputDate.getTime() - now.getTime();

  // Convert milliseconds to days
  // const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // if (daysDifference === 0) {
  //   return 'Today';
  // } else if (daysDifference === -1) {
  //   return 'Yesterday';
  // } else if (daysDifference === 1) {
  //   return 'Tomorrow';
  // } else if (daysDifference > 1 && daysDifference <= 10) {
  //   return `In ${daysDifference} days`;
  // } else {
  const options: Intl.DateTimeFormatOptions = {
    // year: 'numeric',
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('en-UK', options).format(inputDate);
  // }
};

const getReadableDate = (dateString: string): string => {
  try {
    const inputDate = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      // weekday: 'short',
      month: 'short',
      day: 'numeric',
    };

    return new Intl.DateTimeFormat('en-UK', options).format(inputDate) || '';
    // const dateArray = dateString.split('-');
    // const date = new Date(dateString);
    // if (dateArray[0] > '1960') {
    //   return `${date.getDate()} ${date.toLocaleString('default', {
    //     month: 'short',
    //   })} ${date.getFullYear()}`;
    // } else {
    //   return '';
    // }
  } catch (err) {
    console.log(dateString, err);
    return '';
  }
};

const getAppointmentReadableDateTime = (date: Date): string => {
  return format(date, "d MMM yyyy 'at' h:mm aa");
};

const getSimpleDate = (date: Date): string => {
  return format(date, 'd MMM yyyy');
};
const formatDateTime = (start: Date, end: Date) => {
  const startDateTime = format(start, 'EEE, MMM d • HH:mm');
  const endTime = format(end, 'HH:mm');
  return `${startDateTime} - ${endTime}`;
};

export {
  getReadableDate,
  getHumanReadableTime,
  addMinutesToDateTime,
  formatToDateTimeCalendar,
  addDaysToDateTime,
  getTimestamp,
  getPreviousDate,
  getNextDate,
  getSimpleDate,
  getAppointmentReadableDateTime,
  formatDateTime,
};
