import React from 'react';
import Select from 'react-dropdown-select';

interface Option {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface AutoCompleteProps<T extends Option> {
  setSelectedValue: ((values: T[]) => void) | undefined; // Ensure setSelectedValue is not undefined
  selectedValue: T[];
  options: T[];
  multi?: boolean;
  labelField: string;
  valueField: string;
  placeholder: string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'outlined'
    | 'link'
    | 'destructive'
    | 'success';
  size?: 'large' | 'medium' | 'small' | 'xsmall';
  className?: string;
  keepSelectedInList?: boolean;
}

const AutoComplete = <T extends Option>({
  setSelectedValue,
  selectedValue = [],
  options,
  labelField,
  valueField,
  placeholder,
  multi = false,
  keepSelectedInList = true,
  ...props
}: AutoCompleteProps<T>) => {
  const handleChange = (values: T[]) => {
    if (setSelectedValue) {
      setSelectedValue(values);
    }
  };

  return (
    <Select
      multi={multi}
      options={options}
      labelField={labelField}
      valueField={valueField}
      placeholder={placeholder}
      onChange={handleChange}
      values={selectedValue} // Pass selectedValue to the values prop
      keepSelectedInList={keepSelectedInList}
      dropdownHandle={false}
      {...props}
    />
  );
};

export default AutoComplete;
