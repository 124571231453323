/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Input,
  Slider,
} from '../../../../UI';
import { CrossIcon, SearchIcon } from '../../../../../assets/icons';
import LoaderIcon from '../../../../../assets/icons/Loader.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { addServices } from '../../../../../redux/slice';
import {
  createRecommendationBulk,
  getAllServices,
} from '../../../../../services/api';
import { useParams } from 'react-router-dom';
import { notify } from '../../../../common/Alert';

const RecommendedModal = ({
  open,
  recommendedServices,
  onClose,
}: {
  open: boolean;
  recommendedServices: string[];
  onClose: () => void;
}) => {
  const { id } = useParams();
  const [searchResult, setSearchResult] = useState<Service[]>();
  const [loading, isLoading] = useState<boolean>(false);
  const [doneBtnLoading, isDoneBtnLoading] = useState<boolean>(false);
  const servicesState = useSelector<RootState, Service[]>(
    state => state.services.services
  );
  const [checkedServices, setCheckedServices] = useState<string[]>([]);
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const getServices = async () => {
      isLoading(true);
      if (servicesState.length === 0) {
        const [res, err] = await getAllServices();
        if (err) {
          notify.error({
            message: err.data,
            title: 'Failed to fetch services',
          });
        } else if (res) {
          dispatch(addServices(res));
        }
      }
      setSearchResult(servicesState);
      setCheckedServices([]);
      isLoading(false);
    };

    getServices();
  }, []);

  const searchServices = (event: any) => {
    const { value } = event.target;
    if (value.length < 1) {
      setSearchResult(servicesState);
      return;
    }
    if (value.length < 3) {
      return;
    }
    const filtered = servicesState.filter(service =>
      service.name.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResult(filtered);
  };

  const handleCheckboxChange = (publicId: string, checked: boolean) => {
    if (checked) {
      setCheckedServices(prevState => [...prevState, publicId]);
    } else {
      setCheckedServices(prevState =>
        prevState.filter(item => item !== publicId)
      );
    }
  };

  const createRecommendations = async () => {
    if (id) {
      isDoneBtnLoading(true);
      const filteredCheckedServices = checkedServices.filter(
        serviceId => !recommendedServices.includes(serviceId)
      );
      const request: RecommendationCreateRequest = {
        patientPublicId: id,
        servicePublicIds: filteredCheckedServices,
        staffPublicId: user.staff.publicId,
      };

      const [res, err] = await createRecommendationBulk(request);
      onClose();
      if (err) {
        notify.error({
          title: 'Failed to create recommendations',
          message: err.data,
        });
      } else if (res) {
        notify.success({
          title: 'Recommendations created successfully',
          message: 'Patient will receive the recommendations in an email',
        });
      }
      isDoneBtnLoading(false);
    }
  };

  return (
    <div>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0 w-[50%] md:w-[40%] max-h-[70%] flex flex-col gap-0">
          <DialogHeader className="flex justify-between items-center">
            <h1 className="text-[14px] text-neutral-900 font-medium">
              Recommended Service
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Input
            leadingIcon={<SearchIcon />}
            className="w-full focus:border-neutral-100 rounded-none border-t border-b border-r-0 border-l-0"
            placeholder="Search services"
            onChange={searchServices}
          />
          {loading && (
            <img
              src={LoaderIcon}
              className="w-[150px] h-[150px] animate-spin"
            />
          )}
          {!loading && searchResult && (
            <SearchResult
              services={searchResult}
              recommendedServices={recommendedServices}
              handleCheckboxChange={handleCheckboxChange}
            />
          )}
          <DialogFooter className="flex gap-3 w-full ">
            <Button
              variant={'link'}
              size={'medium'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              size={'medium'}
              onClick={createRecommendations}
              isLoading={doneBtnLoading}
              className="w-full"
            >
              Recommend
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const SearchResult: React.FC<{
  services: Service[];
  recommendedServices: string[];
  handleCheckboxChange: (publicId: string, checked: boolean) => void;
}> = ({
  services: services,
  recommendedServices,
  handleCheckboxChange: handleCheckboxChange,
}) => {
  return (
    <Slider variant={'vertical'} className="flex-1">
      <div className="p-[12px]">
        <div className="space-y-2">
          {services &&
            services.length > 0 &&
            services.map(service => (
              <RecommendedService
                key={service.publicId}
                publicId={service.publicId}
                disabled={recommendedServices.includes(service.publicId)}
                name={service.name}
                handleCheckboxChange={handleCheckboxChange}
              />
            ))}
          {(!services || services.length < 1) && <span>No Results</span>}
        </div>
      </div>
    </Slider>
  );
};

const RecommendedService = ({
  publicId,
  name,
  disabled,
  handleCheckboxChange,
}: {
  publicId: string;
  name: string;
  disabled: boolean;
  handleCheckboxChange: (publicId: string, checked: boolean) => void;
}) => (
  <li className="cursor-pointer flex items-center justify-between space-y-3">
    <div className="flex items-center gap-2">
      {disabled && (
        <p className="text-[14px] font-medium text-neutral-400">{name}</p>
      )}
      {!disabled && (
        <p className="text-[14px] font-medium text-neutral-700">{name}</p>
      )}
    </div>
    <Checkbox
      onCheckedChange={checked => {
        handleCheckboxChange(publicId, checked as boolean);
      }}
      disabled={disabled}
    />
  </li>
);

export default RecommendedModal;
