import React, { useEffect, useState } from 'react';
import { Slider } from '../../../UI';
import StageColum from './StageColum';
import {
  getEventByPatientID,
  getEventsByPathwayId,
  updateEventStage,
} from '../../../../services/api/endPoints/events';
import { useParams } from 'react-router-dom';
import {
  addMinutesToDateTime,
  formatToDateTimeCalendar,
} from '../../../../utils/date.utl';
import { getTimeByServiceName } from '../../../../utils/Helper';
import { notify } from '../../../../components/common';

const PathStages = ({ patientPathway }: { patientPathway: PathwayLite }) => {
  const { id } = useParams();
  const [events, setEvents] = useState<BoardEvent[]>([]);
  const [eventToUpdateId, setEventToUpdateId] = useState<string>();
  useEffect(() => {
    const getEvents = async (pathway: PathwayLite) => {
      let response;
      if (id) {
        [response] = await getEventByPatientID(id);
      } else {
        [response] = await getEventsByPathwayId(pathway.publicId);
      }
      if (response) {
        const data: BoardEvent[] = [];

        response.forEach((element: EventLite) => {
          data.push({
            name: element.service.name,
            patient: element.patient,
            servicePublicId: element.service.publicId,
            staff: element.staff,
            booking: element.booking,
            currentStage: element.currentStage,
            eventPublicId: element.publicId,
            assignee: element.assignee,
            assigneeTeam: element.assigneeTeam,
            whenLastStageUpdated: element.whenLastStageUpdated,
            startTime: formatToDateTimeCalendar(
              element.booking?.appointmentTime.toString() ||
                new Date().toString()
            ),
            endTime: addMinutesToDateTime(
              element.booking?.appointmentTime.toString() ||
                new Date().toString(),
              getTimeByServiceName(element.service.name)
            ),
          });
        });

        setEvents(data);
      } else {
        notify.error({
          title: 'Failed to fetch data',
        });
      }
    };
    getEvents(patientPathway);
  }, [id, patientPathway]);

  const setEventsHandler = (
    prevEvents: BoardEvent[],
    stage: StageLite
  ): BoardEvent[] => {
    return prevEvents?.map((event: BoardEvent) => {
      if (event.eventPublicId === eventToUpdateId) {
        return {
          ...event,
          currentStage: stage,
        };
      }
      return event;
    });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (
    e: React.DragEvent<HTMLDivElement>,
    stage: StageLite
  ) => {
    e.preventDefault();
    if (eventToUpdateId) {
      const updateStageObj: UpdateEventRequest = {
        publicId: eventToUpdateId,
        currentStagePublicId: stage.publicId,
      };
      const [response] = await updateEventStage(updateStageObj);
      if (response) {
        setEvents((prevEvents: BoardEvent[]) =>
          setEventsHandler(prevEvents, stage)
        );

        notify.success({
          title: `Moved to stage ${stage.name}`,
        });
      } else {
        notify.error({
          title: `Failed to move to stage ${stage.name}`,
        });
      }
    }
  };

  const removeArchivedEvent = (archivedEvent: BoardEvent) => {
    setEvents(prevEvents =>
      prevEvents?.filter(
        (event: BoardEvent) =>
          event.eventPublicId !== archivedEvent.eventPublicId
      )
    );
  };
  return (
    <Slider variant={'horizontal'} className="p-3">
      <div className="flex gap-4">
        {patientPathway.pathwayStages.map(stage => (
          <div
            key={stage.publicId}
            onDragOver={handleDragOver}
            onDrop={e => handleDrop(e, stage)}
          >
            <StageColum
              stage={stage}
              events={events}
              setEventToUpdateId={setEventToUpdateId}
              removeArchivedEvent={removeArchivedEvent}
            />
          </div>
        ))}
      </div>
    </Slider>
  );
};

export default PathStages;
