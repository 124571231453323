import React from 'react';
import LabTestForm from '../components/sections/Patient/PatientInfoTabs/Labs/LabTestForm';

const CreateTest = () => {
  return (
    <div className="w-full rounded-md border border-neutral-100">
      <LabTestForm />
    </div>
  );
};

export default CreateTest;
