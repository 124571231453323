export default function RightArrow({
  stroke = 'currentColor',
  width = '16',
  height = '17',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M7 5.633L10 8.633L7 11.633"
        stroke={stroke}
        stroke-width="1.13"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
