import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Slider,
  Select,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from '../../../../UI';
import {
  CheckIcon,
  CrossIcon,
  DownArrowIcon,
} from '../../../../../assets/icons';
import { getAllPathways } from '../../../../../services/api/endPoints/pathways';
import { createService, updateService } from '../../../../../services/api';
import { notify } from '../../../../../components/common';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { useDispatch } from 'react-redux';
import {
  addService,
  updateService as updateServiceRedux,
} from '../../../../../redux/slice';
const AddAndEditServiceModal = ({
  open,
  onClose,
  ...props
}: AddAndEditServiceModalProps) => {
  const [data, setData] = useState<ServiceFormInputType>({
    name: '',
    pathwayPublicId: '',
    type: '',
    duration: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [pathways, setPathways] = useState<PathwayLite[]>();
  const [pathwayError, setPathwayError] = useState<string | null>(null);
  const durationOptions: { value: number; label: string }[] = useMemo(
    () => [
      {
        value: 15,
        label: '15 mins',
      },
      {
        value: 30,
        label: '30 mins',
      },
      {
        value: 45,
        label: '45 mins',
      },
      {
        value: 60,
        label: '60 mins',
      },
    ],
    []
  );
  const serviceTypes: { value: string; label: string }[] = useMemo(
    () => [
      {
        value: 'Test',
        label: 'Test',
      },
      {
        value: 'Treatment',
        label: 'Treatment',
      },
      {
        value: 'Consultation',
        label: 'Consultation',
      },
    ],
    []
  );

  useEffect(() => {
    const getPathways = async () => {
      const [response, error] = await getAllPathways();
      if (response) {
        setPathways(response);
      } else {
        setPathwayError(error.data);
      }
    };
    getPathways();
    if (props.type === 'Edit') {
      const initialData: ServiceFormInputType = {
        name: props.service.name,
        pathwayPublicId: props.service.pathway.publicId,
        type: props.service.type,
        duration: props.service.duration ? props.service.duration : 0,
      };
      setData(initialData);
    }
  }, []);
  const handleSubmit = async () => {
    setIsLoading(true);
    if (props.type === 'Create') {
      const newServiceData: CreateServiceRequest = {
        ...data,
      };
      const [res, error] = await createService(newServiceData);
      if (res) {
        dispatch(addService(res));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_SERVICE_ADD,
          message: `Service - ${res.name} added successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_SERVICE_ADD,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    } else if (props.type === 'Edit') {
      const updateServiceData: UpdateServiceRequest = {
        ...data,
        publicId: props.service.publicId,
      };
      const [res, error] = await updateService(updateServiceData);
      if (res) {
        dispatch(updateServiceRedux(res));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_SERVICE_UPDATE,
          message: `Service - ${props.service.name} update successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_SERVICE_UPDATE,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(false);
    onClose();
  };
  const inputHandler = (name: keyof ServiceFormInputType, value: any) => {
    console.log(name, value);
    setData(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type === 'Create' ? 'Add service' : 'Edit service'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-3">
            <div className="space-y-1">
              <Label htmlFor="name">Service name</Label>
              <Input
                placeholder="Enter service name"
                name="name"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  inputHandler('name', e.target.value)
                }
                value={data.name}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="type">Service type</Label>
              <Select
                required
                name="type"
                value={data.type}
                onValueChange={value => inputHandler('type', value)}
              >
                <SelectTrigger
                  className="w-full"
                  selectIcon={<DownArrowIcon />}
                >
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent className="bg-shades-0 z-[999999]">
                  {serviceTypes.map(serviceType => (
                    <SelectItem
                      value={serviceType.value}
                      key={serviceType.value}
                    >
                      {serviceType.value}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-1">
              <Label htmlFor="type">Pathway</Label>
              <Select
                required
                name="pathwayPublicId"
                onValueChange={value => inputHandler('pathwayPublicId', value)}
                value={data.pathwayPublicId}
              >
                <SelectTrigger
                  className="w-full"
                  selectIcon={<DownArrowIcon />}
                >
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent className="bg-shades-0 z-[999999]">
                  {!pathwayError && pathways ? (
                    pathways.map(pathway => (
                      <SelectItem
                        value={pathway.publicId}
                        key={pathway.publicId}
                      >
                        {pathway.name}
                      </SelectItem>
                    ))
                  ) : (
                    <p className="p-4 text-center">{pathwayError}</p>
                  )}
                </SelectContent>
              </Select>
            </div>
            <DurationOptions
              value={data.duration}
              inputHandler={inputHandler}
              durationOptions={durationOptions}
            />
          </div>
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            onClick={handleSubmit}
            className="w-full"
            variant={'primary'}
            disabled={isLoading}
            isLoading={isLoading}
          >
            {props.type === 'Create' ? 'Add service' : 'Save service'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const DurationOptions = ({
  inputHandler,
  durationOptions,
  value,
}: {
  inputHandler: (name: keyof ServiceFormInputType, value: any) => void;
  durationOptions: { value: number; label: string }[];
  value: number;
}) => {
  const showOptionsContainerRef = useRef<HTMLDivElement>(null);
  const [showDurationOptions, setShowDurationOptions] =
    useState<boolean>(false);
  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (
        showOptionsContainerRef.current &&
        !showOptionsContainerRef.current.contains(event.target as Node)
      ) {
        setShowDurationOptions(false);
      }
    };

    if (showDurationOptions) {
      window.addEventListener('click', handleClickOutside);
    } else {
      window.removeEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [showDurationOptions]);
  return (
    <div className="space-y-1">
      <Label htmlFor="duration">Duration</Label>
      <div className="space-y-1 relative" ref={showOptionsContainerRef}>
        <div className="flex gap-2 items-center">
          <Input
            placeholder="Enter duration in minute"
            name="duration"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              inputHandler('duration', e.target.value)
            }
            onFocus={() => setShowDurationOptions(true)}
            value={value}
            type="number"
            wrapperClass="w-full"
          />
          <p className="text-[14px] font-semibold">mins</p>
        </div>
        {showDurationOptions && (
          <ul className="p-3 flex flex-col gap-2 border border-neutral-100 rounded fixed w-[95%] bg-shades-0">
            {durationOptions.map(duration => (
              <li
                key={duration.value}
                onClick={() => {
                  setShowDurationOptions(false);
                  inputHandler('duration', duration.value);
                }}
                className="cursor-pointer flex justify-between"
              >
                {duration.label}
                {value.toString() === duration.value.toString() && (
                  <CheckIcon />
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AddAndEditServiceModal;
