import { ColumnDef } from '@tanstack/react-table';
import {
  DeleteIcon,
  DirectedArrowIcon,
  DotMenu,
  OutlinedAddIcon,
} from '../../../../../assets/icons';
import { Button, Slider } from '../../../../UI';
import React, { ReactNode, useEffect, useState } from 'react';
import { ActionDropdown, DataTable, AlertDialog } from '../../../../common';
import AddNoteModal from './AddNoteModal';
import { useParams } from 'react-router-dom';
import { deleteNote, getNotes } from '../../../../../services/api';
import { notify } from '../../../../common/Alert';
import { getAppointmentReadableDateTime } from '../../../../../utils/date.utl';

const Notes = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openAddNoteModal, setOpenAddNoteModal] = useState<boolean>(false);
  const [notes, setNotes] = useState<NoteUIType[]>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedDeleteNote, setSelectedDeleteNote] =
    useState<NoteUIType | null>(null);
  const [isDeleteModalOpen, setIsDeleModalOpen] = useState<boolean>(false);
  const removeNote = (idToRemove: string) => {
    const updatedNotes = notes.filter(notes => notes.id !== idToRemove);
    setNotes(updatedNotes);
  };

  const notesColumnDef: ColumnDef<NoteUIType>[] = [
    {
      accessorKey: 'createdAt',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Created At
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill="#808080" />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill="#808080" />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        return (
          <span>
            {getAppointmentReadableDateTime(row.original.createdAt).toString()}
          </span>
        );
      },
    },

    {
      accessorKey: 'title',
      header: 'Title',
    },
    {
      accessorKey: 'note',
      header: 'Note',
      cell: ({ row }) => {
        return <div dangerouslySetInnerHTML={{ __html: row.original.note }} />;
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const dropDownMenu: {
          label: string;
          Icon: ReactNode;
          action: () => void;
        }[] = [
          {
            label: 'Delete',
            Icon: <DeleteIcon stroke="#EF4444" />,
            action: () => {
              setSelectedDeleteNote(row.original);
              setIsDeleModalOpen(true);
            },
          },
        ];
        return (
          <div className="flex justify-end">
            <div className="flex gap-8">
              <ActionDropdown
                side="bottom"
                align="end"
                sideOffset={3}
                alignOffset={18}
                drownDownItems={dropDownMenu}
              >
                <button className="w-8 h-8 p-0 outline-none border-none">
                  <span className="sr-only">Open menu</span>
                  <DotMenu />
                </button>
              </ActionDropdown>
            </div>
          </div>
        );
      },
    },
  ];

  const deleteNoteForPatient = async () => {
    if (!selectedDeleteNote) return;
    setIsDeleting(true);
    const [res, err] = await deleteNote(selectedDeleteNote.id);
    if (err) {
      notify.error({
        title: 'Failed to delete note',
        message: err.data,
      });
    } else if (res) {
      notify.success({
        title: 'Note deleted successfully',
      });
      removeNote(selectedDeleteNote.id);
    }
    closeDeleteModalHandler();
  };
  const closeDeleteModalHandler = () => {
    setSelectedDeleteNote(null);
    setIsDeleting(false);
    setIsDeleModalOpen(false);
  };
  const getNotesForPatient = async () => {
    if (id) {
      setIsLoading(true);
      const [res, err] = await getNotes('', id);
      if (err) {
        notify.error({
          title: 'Failed to fetch notes',
          message: err.data,
        });
      }
      const notes: NoteUIType[] = res.map((note: Note) => ({
        id: note.publicId,
        createdAt: new Date(note.whenCreated),
        title: note.title,
        note: note.note,
      }));
      //sort by created at
      notes.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
      setNotes(notes);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotesForPatient();
  }, []);

  return (
    <div>
      <AddNoteModal
        open={openAddNoteModal}
        searchForPatient={false}
        callbackFunction={getNotesForPatient}
        onClose={() => {
          setOpenAddNoteModal(false);
        }}
        refreshNotes={getNotesForPatient}
      />
      {isDeleteModalOpen && selectedDeleteNote && (
        <AlertDialog
          type="delete"
          open={isDeleteModalOpen}
          onClose={closeDeleteModalHandler}
          onAction={deleteNoteForPatient}
          alertTitle={`Deleting - ${selectedDeleteNote.title}`}
          isActionStarted={isDeleting}
          actionButtonText={isDeleting ? 'Deleting' : 'Delete'}
        >
          Are you sure you want to delete - {selectedDeleteNote.title} ?
        </AlertDialog>
      )}
      <div className="p-3 flex justify-between items-center">
        <h1 className="text-neutral-900 font-medium whitespace-nowrap">
          Quick Notes
        </h1>
        <Button
          className="inline-flex"
          variant={'primary'}
          size={'xmall'}
          leadingIcon={<OutlinedAddIcon />}
          onClick={() => setOpenAddNoteModal(true)}
        >
          Add a note
        </Button>
      </div>
      <Slider variant={'vertical'} className="h-[500px]">
        <DataTable
          columns={notesColumnDef}
          data={notes}
          isLoading={isLoading}
        />
      </Slider>
    </div>
  );
};

export default Notes;
