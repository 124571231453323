import React from 'react';
import Sessions from './Sessions';
import Analytics from './Analytics';
import ActivityFeed from './ActivityFeed';
const ConsultantManagerView = () => {
  return (
    <div>
      <Analytics role="consultantantManager" />
      <div className="mt-5 grid grid-cols-5 gap-3">
        <div className="col-span-3 space-y-5">
          <Sessions />
        </div>
        <div className="col-span-2">
          <ActivityFeed />
        </div>
      </div>
    </div>
  );
};

export default ConsultantManagerView;
