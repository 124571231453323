import React, { useState, useMemo, useEffect } from 'react';
import { AlertDialog, DataTable, notify } from '../../../../common';
import { Button, Slider } from '../../../../UI';
import {
  AddIcon,
  DeleteIcon,
  //   FilterIcon,
} from '../../../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import AddAndEditResourceModal from './AddAndEditResourceModal';
import { deleteResource, getAllResources } from '../../../../../services/api';
import { useDispatch } from 'react-redux';
import {
  addResources,
  deleteResource as deleteResourceRedux,
} from '../../../../../redux/slice';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { Edit2 } from 'lucide-react';

const Resources = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAddResourceModalOpen, setIsAddResourceModalOpen] =
    useState<boolean>(false);
  const [isEditResourceModalOpen, setIsEditResourceModalOpen] =
    useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<Resource | null>(
    null
  );
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { resources } = useSelector<RootState, ResourceState>(
    state => state.resources
  );
  const dispatch = useDispatch();
  useEffect(() => {
    const getResources = async () => {
      setIsLoading(true);
      const [res] = await getAllResources();
      if (res) {
        dispatch(addResources(res));
      }
      setIsLoading(false);
    };
    getResources();
  }, []);

  const resourcesColumn: ColumnDef<Resource>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'name',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'location.name',
        header: 'Location',
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          return (
            <div className="flex justify-end">
              <div className="flex gap-2">
                <Button
                  variant={'outlined'}
                  className="h-[30px]"
                  trailingIcon={<Edit2 size={15} />}
                  onClick={() => {
                    setSelectedResource(row.original);
                    setIsEditResourceModalOpen(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant={'outlined'}
                  className="h-[30px]"
                  trailingIcon={<DeleteIcon />}
                  onClick={() => {
                    setSelectedResource(row.original);
                    setIsDeleteModalOpen(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const deleteHandler = async () => {
    if (!selectedResource) return;
    setIsDeleting(true);
    const [, err] = await deleteResource(selectedResource.publicId);
    if (!err) {
      dispatch(deleteResourceRedux({ publicId: selectedResource.publicId }));
      notify.success({
        title: SUCCESS_TITLE.GENERIC_STAFF_DELETE,
        message: `Resource - ${selectedResource.name} deleted successfully`,
      });
    } else {
      notify.error({
        title: ERROR_TITLE.GENERIC_RESOURCE_DELETE,
        message: err.data,
      });
    }
    closeDeleteModalHandler();
  };
  const closeDeleteModalHandler = () => {
    setIsDeleting(false);
    setSelectedResource(null);
    setIsDeleteModalOpen(false);
  };
  return (
    <>
      {isAddResourceModalOpen && (
        <AddAndEditResourceModal
          open={isAddResourceModalOpen}
          type="Create"
          onClose={() => setIsAddResourceModalOpen(false)}
        />
      )}
      {isEditResourceModalOpen && selectedResource && (
        <AddAndEditResourceModal
          open={isEditResourceModalOpen}
          type="Edit"
          resource={selectedResource}
          onClose={() => {
            setSelectedResource(null);
            setIsEditResourceModalOpen(false);
          }}
        />
      )}

      {isDeleteModalOpen && selectedResource && (
        <AlertDialog
          type="delete"
          actionButtonText={!isDeleting ? 'Delete' : 'Deleting'}
          alertTitle={`Deleting ${selectedResource.name}`}
          onClose={closeDeleteModalHandler}
          open={isDeleteModalOpen}
          onAction={deleteHandler}
          isActionStarted={isDeleting}
        >
          Are you sure you want to delete {selectedResource.name} ?
        </AlertDialog>
      )}
      <div className="px-[12px]  py-[16px] w-full flex justify-between items-center ">
        <h2 className="font-semibold text-[16px] text-neutral-900">Resource</h2>
        <div className="flex gap-3 items-center justify-center">
          {/* <Button variant={'link'} leadingIcon={<FilterIcon />} size={'xmall'}>
            Filter
          </Button> */}
          <Button
            variant={'primary'}
            size={'small'}
            onClick={() => setIsAddResourceModalOpen(true)}
            trailingIcon={<AddIcon stroke="currentColor" />}
          >
            Add resource
          </Button>
        </div>
      </div>
      <Slider variant={'vertical'} className="flex-1">
        <DataTable
          columns={resourcesColumn}
          data={resources}
          isLoading={isLoading}
        />
      </Slider>
    </>
  );
};

export default Resources;
