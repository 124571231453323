/* eslint-disable @typescript-eslint/no-explicit-any */
import { AZURE_URL } from '../requestConstructor';

export const uploadFile = async (data: any) => {
  try {
    const response = await AZURE_URL.post('/storage/upload', data);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
export const downloadFileByUrl = async (data: { url: string }) => {
  try {
    const response = await AZURE_URL.get('/storage/download', {
      params: data,
      responseType: 'blob',
    });
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response.data];
  }
};
