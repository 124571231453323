import React, { useState } from 'react';
import ActionDropdown, {
  dropDownMenuItem,
} from '../../../components/common/ActionDropdown';
import { Button } from '../../../components/UI';
import { DownArrow } from '../../../assets/icons';
import ResourceBookingFilter from './ResourceBookingFilter';
import BookResourceModal from './BookResourceModal';

const RightTopNav = () => {
  const [openAddPatientModal, setOpenPatientModal] = useState<boolean>(false);
  const addDropdownItems: dropDownMenuItem[] = [
    {
      label: 'Block Time',
      Icon: null,
      action: () => {},
    },
    {
      label: 'Resource Booking',
      Icon: null,
      action: () => setOpenPatientModal(true),
    },
  ];
  return (
    <div>
      <div className="flex flex-row items-center justify-between px-5 py-4 border-b border-neutral-100">
        <div className="flex flex-row gap-8">
          <h1 className="text-large not-italic font-medium">
            Resource Booking Calendar
          </h1>
        </div>
        <div className="flex ">
          <ResourceBookingFilter
            returnFunction={() => {
              'Hello';
            }}
          />
          <ActionDropdown
            side="bottom"
            align="start"
            sideOffset={2}
            drownDownItems={addDropdownItems}
          >
            <div>
              <Button
                trailingIcon={<DownArrow />}
                variant={'primary'}
                className="w-[75px] h-[34px] gap-1"
              >
                Add
              </Button>
            </div>
          </ActionDropdown>
        </div>
      </div>
      <BookResourceModal
        open={openAddPatientModal}
        onClose={() => setOpenPatientModal(false)}
      />
    </div>
  );
};

export default RightTopNav;
