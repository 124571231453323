import React, { FC } from 'react';

const NotePenIcon: FC<{
  stroke?: string;
}> = ({ stroke = '#0A0A0A' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M2.66602 9.50065V3.16732C2.66602 2.8137 2.80649 2.47456 3.05654 2.22451C3.30659 1.97446 3.64573 1.83398 3.99935 1.83398H9.66602L13.3327 5.50065V13.834C13.3327 14.1876 13.1922 14.5267 12.9422 14.7768C12.6921 15.0268 12.353 15.1673 11.9993 15.1673H8.33268"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33398 1.83398V5.83398H13.334"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.94602 8.90616C7.07602 8.77616 7.23037 8.67303 7.40023 8.60267C7.5701 8.53231 7.75216 8.49609 7.93602 8.49609C8.11988 8.49609 8.30194 8.53231 8.4718 8.60267C8.64166 8.67303 8.79601 8.77616 8.92602 8.90616C9.05602 9.03617 9.15915 9.19052 9.22951 9.36038C9.29987 9.53025 9.33609 9.71231 9.33609 9.89617C9.33609 10.08 9.29987 10.2621 9.22951 10.4319C9.15915 10.6018 9.05602 10.7562 8.92602 10.8862L5.29935 14.4995L2.66602 15.1662L3.32602 12.5328L6.94602 8.90616Z"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotePenIcon;
