/* eslint-disable @typescript-eslint/no-explicit-any */
import { AZURE_URL } from '../requestConstructor';

export const registerToPushNotification = async (token: string) => {
  try {
    const response = await AZURE_URL.post(
      `/notification/register?token=${token}`
    );
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const sendNotification = async (data: string) => {
  console.log(data);
};
