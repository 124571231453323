import React from 'react';
import Skeleton from 'react-loading-skeleton';
import defaultImage from '../../assets/images/360_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg';

const PatientInfoPageLoader = () => {
  const showInfo = (
    label: string,
    width: number,
    height: number,
    rows: number = 1,
    isLastRow: boolean = false,
    reduceBy: number = 10
  ) => (
    <>
      <p className="font-medium text-[14px] text-neutral-500">{label}</p>
      <div className="font-medium text-[14px] text-neutral-900">
        {Array.from({ length: rows }, (_, index) => (
          <Skeleton
            key={index}
            width={isLastRow && index === rows - 1 ? width - reduceBy : width}
            height={height}
          />
        ))}
      </div>
    </>
  );
  return (
    <div className="grid grid-cols-5 rounded-[8px] border border-neutral-100">
      <div className="col-span-2 border-r border-neutral-100">
        <div className="gird grid-rows-2">
          <div className="px-3 py-4 flex gap-4 items-center justify-start border-b border-neutral-100">
            <img
              src={defaultImage}
              alt="patient-profile"
              className="rounded-full h-[100px] w-[100px]"
            />
            <div className="space-y-2">
              <p className="font-semibold text-[16px] text-neutral-900">
                <Skeleton width={200} height={30} />
              </p>
              <p className="">
                {/* {patient.publicId} */}
                <Skeleton width={100} height={23} borderRadius={5} />
              </p>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="border-r border-b border-neutral-100 px-3 py-4">
              {showInfo('Age', 50, 20)}
            </div>
            <div className="border-r border-b border-neutral-100 px-3 py-4">
              {showInfo('DOB', 50, 20)}
            </div>
            <div className="border-b border-neutral-100 px-3 py-4">
              {showInfo('Gender', 50, 20)}
            </div>
            <div className="border-r border-neutral-100 px-3 py-4">
              {showInfo('BMI', 50, 20)}
            </div>
            <div className="border-r border-neutral-100 px-3 py-4">
              {showInfo('Height', 50, 20)}
            </div>
            <div className="px-3 py-4">{showInfo('Weight', 50, 20)}</div>
          </div>
        </div>
      </div>
      <div className="col-span-3">
        <h1 className="py-4 px-3 font-medium text-[14px]">
          General Information
        </h1>
        <div className="grid grid-cols-3">
          <div className="px-3 py-6">
            {showInfo('Next Appointment', 100, 20)}
          </div>
          <div className="px-3 py-6">{showInfo('Consulted by', 150, 20)}</div>
          <div className="px-3 py-6">{showInfo('Allergies', 80, 20)}</div>
          <div className="px-3 py-6">
            {showInfo('Address', 250, 20, 2, true, 80)}
          </div>
          <div className="px-3 py-6">{showInfo('Email', 150, 20)}</div>
          <div className="px-3 py-6">{showInfo('Contact', 100, 20)}</div>
        </div>
      </div>
    </div>
  );
};

export default PatientInfoPageLoader;
