import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { useLocation } from 'react-router-dom';
import {
  // EventIcon,
  NoteIcon,
  OutlinedAddIcon,
  PersonIcon,
  Notification,
  // CommentsIcon,
} from '../../assets/icons';
import { ActionDropdown, SearchModal } from '../../components/common';
import {
  Button,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from '../../components/UI';

import { getFullName } from '../../utils/Helper';
import AddNoteModal from '../../components/sections/Patient/PatientInfoTabs/Notes/AddNoteModal';
import defaultImage from '../../assets/images/360_F_118019822_6CKXP6rXmVhDOzbXZlLqEM2ya4HhYzSV.jpg';
import { ActivityFeed } from '../../components/sections';
import {
  NottificationType,
  checkedNewNotification,
} from '../../redux/slice/notificationSlice';
import { useDispatch } from 'react-redux';
import { ROUTES } from '../../constants/NavigationConstants';

const Topbar: React.FC = () => {
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const { hasNew, totalUnread } = useSelector<RootState, NottificationType>(
    state => state.notifications
  );
  const dispatch = useDispatch();
  const [openAddNoteModal, setOpenAddNoteModal] = useState<boolean>(false);
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] =
    useState<boolean>(false);
  const location = useLocation();
  const createOptionMenu: {
    label: string;
    Icon: ReactNode;
    action: () => void;
  }[] = [
    {
      label: 'Add Patient',
      Icon: <PersonIcon />,
      action: () => {},
    },
    // {
    //   label: 'Add Event',
    //   Icon: <EventIcon />,
    //   action: () => {},
    // },
    {
      label: 'Add Note',
      Icon: <NoteIcon />,
      action: () => {
        setOpenAddNoteModal(true);
      },
    },
  ];
  // const role: 'admin' | 'consultantManager' | 'consultant' = 'admin';
  const pageName = useMemo(() => {
    if (location.pathname.includes(ROUTES.ROUTE_TO_PATIENT)) {
      return 'Patients';
    }
    if (location.pathname.includes(ROUTES.ROUTE_TO_PATIENT_PROFILE)) {
      return 'Patients';
    }
    if (location.pathname.includes(ROUTES.LABS.BASE)) {
      return 'Labs';
    }
    if (location.pathname === ROUTES.ROUTE_TO_OVERVIEW) {
      return 'Overview';
    }
    if (location.pathname.includes(ROUTES.ADMIN.BASE)) {
      return 'Admin';
    }
    if (location.pathname === ROUTES.ROUTE_TO_RESOURCE_BOOKING) {
      return 'Resource Booking';
    }
  }, [location]);
  useEffect(() => {
    // if (open);
    const outsideClickHandler = (e: MouseEvent) => {
      const notificationContent = document.getElementById(
        'notification-content'
      );
      const notificationIcon = document.getElementById('notification-button');
      if (
        (e.target as HTMLElement).id !== 'notification-content' &&
        (e.target as HTMLElement).id !== 'notification-button' &&
        !notificationContent?.contains(e.target as Node) &&
        !notificationIcon?.contains(e.target as Node)
      ) {
        if (isNotificationPanelOpen) setIsNotificationPanelOpen(false);
      }
    };
    if (isNotificationPanelOpen) {
      window.addEventListener('click', outsideClickHandler);
    }
    return () => {
      window.removeEventListener('click', outsideClickHandler);
    };
  }, [isNotificationPanelOpen]);
  const notificationButtonClickHandler = () => {
    if (hasNew) dispatch(checkedNewNotification());
    setIsNotificationPanelOpen(prev => !prev);
  };
  return (
    <>
      <AddNoteModal
        open={openAddNoteModal}
        searchForPatient={true}
        onClose={() => {
          setOpenAddNoteModal(false);
        }}
      />
      <div className="w-full px-[16px] border-b border-neutral-100  flex justify-between items-center py-[12px]">
        {/* Left Controls : Heading | Search bar */}
        <div className="flex items-center gap-5">
          <h1 className="font-medium text-[24px] font-shades-100">
            {pageName?.toString()}
          </h1>
          <SearchModal />
        </div>
        {/* Right Controls : Create Button | Notifcations | Profile */}
        <div className="flex gap-4 items-center">
          <ActionDropdown drownDownItems={createOptionMenu}>
            <div>
              <Button
                variant={'primary'}
                className="w-[98px] h-[40px]"
                trailingIcon={<OutlinedAddIcon />}
              >
                Create
              </Button>
            </div>
          </ActionDropdown>
          {/* Divider */}
          <div className="h-[30px] w-[2px] bg-neutral-100"></div>
          {/* TODO: Separate Notifaction Center for complex controls */}
          <div className="flex items-center gap-2">
            <NotificationTooltip open={isNotificationPanelOpen}>
              <NotificationButton
                onNotificationButtonClick={notificationButtonClickHandler}
                hasNew={hasNew}
                totalUnread={totalUnread}
              />
            </NotificationTooltip>
            {/* <button>
              <img
                src={CommentsIcon}
                style={{ height: '25px', width: '25px' }}
              />
            </button> */}
          </div>

          <div className="h-[30px] w-[2px] bg-neutral-100"></div>
          <Profile
            name={getFullName(user.staff)}
            role={user.staff.type}
            profilePic={defaultImage}
          />
        </div>
      </div>
    </>
  );
};

const NotificationTooltip = ({
  open,

  children,
}: {
  open: boolean;

  children: ReactNode;
}) => (
  <TooltipProvider disableHoverableContent>
    <Tooltip open={open}>
      <TooltipTrigger asChild>
        <div>{children}</div>
      </TooltipTrigger>
      <TooltipContent
        id="notification-content"
        className="z-[99999] h-[500px] p-0 border-0 w-[400px]"
        side="top"
      >
        <ActivityFeed />
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

const Profile: React.FC<{
  name: string;
  role: string;
  profilePic: string;
}> = ({ name, role, profilePic }) => {
  return (
    <div className="flex gap-2 items-center">
      <img src={profilePic} className="rounded-full w-[32px] h-[32px]" />
      <div className="flex flex-col">
        <p className="text-shades-100 text-[14px]">{name}</p>
        <p className="text-neutral-500 text-[14px] captalized">{role}</p>
      </div>
    </div>
  );
};

const NotificationButton = ({
  onNotificationButtonClick,
  totalUnread,
  hasNew,
}: {
  onNotificationButtonClick: () => void;
  hasNew: boolean;
  totalUnread: number;
}) => (
  <button
    id="notification-button"
    onClick={onNotificationButtonClick}
    className="relative"
  >
    <img src={Notification} style={{ height: '25px', width: '25px' }} />
    {!hasNew ? (
      totalUnread > 0 && (
        <div className="absolute top-0 right-[1.8px] z-[9999] w-[13px] h-[13px] flex items-center justify-center rounded-full bg-destructive-500">
          <p className="text-[10px] font-semibold text-white">{totalUnread}</p>
        </div>
      )
    ) : (
      <div className="absolute top-0 right-[1.8px] z-[9999] w-[10px] h-[10px] flex items-center justify-center rounded-full bg-destructive-500"></div>
    )}
  </button>
);

export default Topbar;
