import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: TreatmentsState = { treatments: [] };

export const treatmentsSlice = createSlice({
  name: 'treatments',
  initialState,
  reducers: {
    addTreatments: (state, action: PayloadAction<Treatment[]>) => {
      state.treatments = action.payload;
    },
  },
});

export default treatmentsSlice.reducer;
export const { addTreatments } = treatmentsSlice.actions;
