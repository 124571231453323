import React, { ReactNode } from 'react';

// import MedicalNotes from './MedicalNotes';
// import CreateMedicalNotes from './CreateMedicalNotes';
// import MedicalReportView from './MedicalReportView';

// import AdministrativeNotes from './AdministrativeNotes';
// import Progress from './Progress';

// import Appointment from './Appointment';
// import AppointmentReportView from './AppointmentReportView';

// import Labs from './Labs';
// import LabTestForm from './LabTestForm';

// import Prescriptions from './Prescriptions';
// import PrescritionForm from './PrescriptionForm';
import {
  Calendar2Icon,
  FoldersGroupIcon,
  LabIcon,
  MedicineIcon,
  NoteIcon,
  NoteRolling2Icon,
  NoteRollingIcon,
  Pen2Icon,
  PersonIcon,
  // ProgressIcon,
} from '../../../../assets/icons';
import { NavLink, Outlet } from 'react-router-dom';
import { PATIENT_ROUTES } from '../../../../constants/NavigationConstants';
// import Documents from './Documents';
// import Recomended from './Recomended';

const PatientInfoTabs = () => {
  return (
    <div className="border border-neutral-100 rounded-lg border-collapse">
      <div className="w-full">
        <div className="h-10 flex justify-start gap-2 overflow-x-auto border-b border-neutral-100 bg-neutral-50 rounded-t-lg">
          {/* <NavItem to="progress">
            <ProgressIcon /> Progress
          </NavItem> */}
          <NavItem to={PATIENT_ROUTES.ADMIN_FORMS.HOME}>
            <NoteRollingIcon /> Admin Forms
          </NavItem>
          <NavItem to={PATIENT_ROUTES.MEDICAL_REPORTS.HOME}>
            <NoteRolling2Icon /> Medical Reports
          </NavItem>
          <NavItem to={PATIENT_ROUTES.HANDWRITTEN_NOTES.HOME}>
            <Pen2Icon /> Handwritten Notes
          </NavItem>
          <NavItem to={PATIENT_ROUTES.RECOMMENDED.HOME}>
            <PersonIcon stroke="currentColor" /> Recommend
          </NavItem>
          <NavItem to={PATIENT_ROUTES.APPOINTMENT.HOME}>
            <Calendar2Icon /> Appointment
          </NavItem>
          <NavItem to={PATIENT_ROUTES.LABS.HOME}>
            <LabIcon /> Labs
          </NavItem>
          <NavItem to={PATIENT_ROUTES.PRESCRIPTION.HOME}>
            <MedicineIcon /> Prescriptions
          </NavItem>
          <NavItem to={PATIENT_ROUTES.DOCUMENTS.HOME}>
            <FoldersGroupIcon /> Documents
          </NavItem>
          <NavItem to={PATIENT_ROUTES.NOTES.HOME}>
            <NoteIcon stroke="currentColor" /> Notes
          </NavItem>
        </div>
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

const NavItem = ({ to, children }: { to: string; children: ReactNode }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        [
          'h-full inline-flex items-center justify-center gap-2 whitespace-nowrap px-3 py-2 text-sm font-medium transition-all',
          isActive ? 'border-b border-primary-500 text-primary-500 ' : '',
        ].join(' ')
      }
    >
      {children}
    </NavLink>
  );
};

export default PatientInfoTabs;
