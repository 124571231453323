import React, { useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '../../../../UI';
import { Button } from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';

const DocumentUploadModal = ({
  open,
  onClose,
  onUpload,
}: {
  open: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, isLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    setFile(selectedFile);
  };
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const uploadFile = async () => {
    if (!file) return;
    onUpload(file);
    isLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[60%] md:w-[50%] max-h-[70%] flex flex-col gap-0">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-medium">
            Upload Documents
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <div className="p-2">
          <input
            type="file"
            hidden
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          <div
            className="w-full h-[243px] border-dashed border-neutral-200 flex items-center justify-center cursor-pointer"
            onClick={handleUploadClick}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <p className="text-[14px] text-neutral-500 font-medium">
              {!file ? 'Drag a file here or ' : 'Added '}
              <span className="text-primary-500">
                {!file ? 'Browse files' : file.name}
              </span>
            </p>
          </div>
        </div>
        <DialogFooter className="flex gap-3 w-full ">
          <Button
            variant={'link'}
            size={'medium'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'medium'}
            onClick={uploadFile}
            className="w-full"
            disabled={!file}
            isLoading={loading}
          >
            Upload
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentUploadModal;
