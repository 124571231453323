import React from 'react';
import RightTopNav from './RightTopNav';
import Calendar from './Calendar';

const ResourceBookingLayout = () => {
  return (
    <div className="border border-neutral-100 rounded-lg w-full">
      <RightTopNav />
      <div className="p-2">
        <Calendar />{' '}
      </div>
    </div>
  );
};

export default ResourceBookingLayout;
