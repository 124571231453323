/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import PathStages from './PathStages';
import { StageType } from './PatientJourney';
import { getAllPathways } from '../../../../services/api/endPoints/pathways';
import BoardLoader from '../../../../components/loaders/BoardLoader';
const Pathway = () => {
  const [pathwayType, setPathwayType] = useState<number>(0);
  const [pathways, setPathways] = useState<PathwayLite[]>();
  const [selectedPathway, setSelectedPathway] = useState<PathwayLite>();
  const PathwayTypes: {
    label: string;
    total: number;
  }[] = [
    {
      label: 'Appointment Pathway',
      total: 10,
    },
    {
      label: 'IVF Pathway',
      total: 5,
    },
  ];

  const getPathways = async () => {
    const [response, error] = await getAllPathways();
    setSelectedPathway(response[0]);
    setPathways(response);
    // getEvents(response[1]);
  };
  useEffect(() => {
    getPathways();
  }, []);
  useEffect(() => {
    if (pathways) {
      setSelectedPathway(pathways[pathwayType]);
    }
  }, [pathwayType, pathways]);

  return (
    <div>
      {pathways ? (
        <div className="flex gap-3 p-3">
          {pathways?.map((pathway, index) => (
            <PathwayTypeChip
              label={pathway.name}
              // total={pathway.pathwayStages.length}
              index={index}
              key={index}
              setPathwayType={setPathwayType}
              isActive={pathwayType === index}
            />
          ))}
        </div>
      ) : (
        <BoardLoader />
      )}

      {selectedPathway && <PathStages patientPathway={selectedPathway} />}
    </div>
  );
};

const PathwayTypeChip = ({
  label,
  // total,
  isActive,
  setPathwayType,
  index,
}: {
  label: string;
  // total: number;
  setPathwayType: (index: number) => void;
  index: number;
  isActive: boolean;
}) => (
  <div
    key={index}
    onClick={() => setPathwayType(index)}
    className={
      ' py-1 px-1.5 rounded-[112px] flex items-center gap-2 cursor-pointer ' +
      (isActive
        ? 'border border-primary-500 bg-primary-100'
        : 'border border-neutral-100 bg-neutral-50')
    }
  >
    <span
      className={
        'font-medium text-[14px] ' +
        (isActive ? ' text-primary-700  ' : 'text-neutral-400')
      }
    >
      {label}
    </span>
    {/* <span
      className={
        'px-[4px] rounded-full text-[12px] font-medium ' +
        (isActive
          ? ' bg-primary-500 border border-primary-600 text-shades-0 '
          : 'border border-neutral-100 bg-shades-0 text-nutral-100')
      }
    >
      {total}
    </span> */}
  </div>
);

export default Pathway;
