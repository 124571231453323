import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Input,
  Label,
  Button,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';
const CreateFolderModal = ({
  open,
  onClose,
  title,
  placeHolder,
  loading,
  onSave,
}: {
  open: boolean;
  onClose: () => void;
  title?: string;
  placeHolder?: string;
  loading?: boolean;
  // eslint-disable-next-line
  onSave?: (name: string) => void;
}) => {
  const [name, setName] = useState<string>('');
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0 w-[30%] max-h-[70%] flex flex-col gap-0">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-medium">
            {title ? title : ' Create Folder'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <div className="p-2 space-y-1">
          <Label>Name</Label>
          <Input
            placeholder={`${placeHolder ? placeHolder : 'Folder Name'}`}
            onChange={e => setName(e.target.value)}
            value={name}
          />
        </div>
        <DialogFooter className="flex gap-3 w-full ">
          <Button
            variant={'link'}
            size={'medium'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'medium'}
            onClick={() => onSave && onSave(name)}
            className="w-full"
            isLoading={loading}
          >
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateFolderModal;
