import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AuthState = {
  auth: {
    token: null,
    expiresIn: 0,
    tokenType: '',
    refreshToken: null,
    refreshTokenExpiresIn: 0,
    whenExpires: 0,
    whenRefreshTokenExpires: 0,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state: AuthState, action: PayloadAction<AuthEntities>) => {
      state.auth.token = action.payload.token;
      state.auth.expiresIn = action.payload.expiresIn;
      state.auth.tokenType = action.payload.tokenType;
      state.auth.refreshToken = action.payload.refreshToken;
      state.auth.refreshTokenExpiresIn = action.payload.refreshTokenExpiresIn;
      state.auth.whenExpires = action.payload.whenExpires;
      state.auth.whenRefreshTokenExpires =
        action.payload.whenRefreshTokenExpires;
    },
    logout: () => initialState,
    getAuth: (state: AuthState) => {
      return state;
    },
  },
});

export default authSlice.reducer;
export const { login, logout, getAuth } = authSlice.actions;
