import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: StaffState = { staffs: [] };

export const staffsSlice = createSlice({
  name: 'staffs',
  initialState,
  reducers: {
    addStaffs: (state, action: PayloadAction<StaffInformation[]>) => {
      state.staffs = action.payload;
    },
    deleteStaff: (state, action: PayloadAction<{ publicId: string }>) => {
      state.staffs = state.staffs.filter(
        staff => staff.publicId !== action.payload.publicId
      );
    },
    addStaff: (state, action: PayloadAction<StaffInformation>) => {
      state.staffs.push(action.payload);
    },
    updateStaff: (state, action: PayloadAction<StaffInformation>) => {
      const staffIndex = state.staffs.findIndex(
        staff => staff.publicId === action.payload.publicId
      );
      state.staffs[staffIndex] = action.payload;
    },
  },
});

export default staffsSlice.reducer;
export const { addStaffs, deleteStaff, addStaff, updateStaff } =
  staffsSlice.actions;
