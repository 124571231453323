import React, { FC } from 'react';

const DotMenu: FC<{
  stroke?: string;
}> = ({ stroke = '#0A0A0A' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.00065 8.66732C8.36884 8.66732 8.66732 8.36884 8.66732 8.00065C8.66732 7.63246 8.36884 7.33398 8.00065 7.33398C7.63246 7.33398 7.33398 7.63246 7.33398 8.00065C7.33398 8.36884 7.63246 8.66732 8.00065 8.66732Z"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00065 3.99935C8.36884 3.99935 8.66732 3.70087 8.66732 3.33268C8.66732 2.96449 8.36884 2.66602 8.00065 2.66602C7.63246 2.66602 7.33398 2.96449 7.33398 3.33268C7.33398 3.70087 7.63246 3.99935 8.00065 3.99935Z"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00065 13.3333C8.36884 13.3333 8.66732 13.0349 8.66732 12.6667C8.66732 12.2985 8.36884 12 8.00065 12C7.63246 12 7.33398 12.2985 7.33398 12.6667C7.33398 13.0349 7.63246 13.3333 8.00065 13.3333Z"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DotMenu;
