import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Slider,
} from '../../../../UI';
import { CrossIcon, DownArrowIcon } from '../../../../../assets/icons';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import {
  createNewTeam,
  getTeam,
  updateTeam,
} from '../../../../../services/api';
import { getFullName } from '../../../../../utils/Helper';
import { notify } from '../../../../../components/common';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
import { useDispatch } from 'react-redux';
import {
  addTeam,
  updateTeam as updateTeamRedux,
} from '../../../../../redux/slice';
const AddAndEditTeamModal = ({
  open,
  onClose,
  ...props
}: AddAndEditTeamModalProps) => {
  const [data, setData] = useState<TeamFormInputType>({
    name: '',
    teamLeaderPublicId: '',
    teamMembersPublicIds: [],
    locationPublicId: '',
  });
  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const { locations } = useSelector<RootState, LocationState>(
    state => state.locations
  );
  const [staffOptions, setStaffOptions] = useState<Record<
    string,
    string
  > | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [oldTeamName, setOldTeamName] = useState<string | null>(null);
  useEffect(() => {
    const getTeamDetails = async () => {
      if (props.type !== 'Edit') return;
      setIsFetching(true);
      const [res, error] = await getTeam(props.selectedTeamId);
      if (res) {
        const team: Team = res;
        const data: TeamFormInputType = {
          name: team.name,
          teamLeaderPublicId: team.teamLeader.publicId,
          teamMembersPublicIds: team.teamMembers.map(member => member.publicId),
          locationPublicId: team.location.publicId,
        };
        setData(data);
        setOldTeamName(team.name);
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_TEAM_FETCH,
          message: error.data,
        });
      }
      setIsFetching(false);
    };
    getTeamDetails();
    const staffOption: Record<string, string> = {};
    staffs.map(staff => {
      staffOption[staff.publicId] = getFullName(staff);
    });
    setStaffOptions(staffOption);
  }, []);
  const teamMemberSelect = (selectValues: string[]) => {
    setData(prev => ({
      ...prev,
      teamMembersPublicIds: [...selectValues],
    }));
  };
  const addTeamHandler = async () => {
    setIsLoading(true);
    if (props.type === 'Create') {
      const newTeamData: CreateTeamRequest = {
        ...data,
      };
      const [res, error] = await createNewTeam(newTeamData);
      if (res) {
        const { publicId, name, teamLeader, location }: Team = res;
        dispatch(addTeam({ publicId, name, teamLeader, location }));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_TEAM_ADD,
          message: `New team - ${res.name} added successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_TEAM_ADD,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    } else if (props.type === 'Edit') {
      const updatedTeamData: UpdateTeamRequest = {
        publicId: props.selectedTeamId,
        ...data,
      };
      const [res, error] = await updateTeam(updatedTeamData);
      if (res) {
        const { publicId, name, teamLeader, location }: Team = res;
        dispatch(updateTeamRedux({ publicId, name, teamLeader, location }));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_TEAM_UPDATE,
          message: `Update team - ${oldTeamName} updated successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_TEAM_UPDATE,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(false);
    onClose();
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type === 'Create' ? 'Add team' : 'Edit team'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-3">
            <div className="space-y-1">
              <Label htmlFor="name">Team name</Label>
              <Input
                placeholder="Enter team name"
                name="name"
                value={data.name}
                onChange={e =>
                  setData(prev => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
                disabled={props.type === 'Edit' && isFetching}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="locationPublicId">Select location</Label>
              <Select
                required
                name="locationPublicId"
                value={data.locationPublicId}
                onValueChange={value =>
                  setData(prev => ({ ...prev, locationPublicId: value }))
                }
              >
                <SelectTrigger
                  className="w-full"
                  selectIcon={<DownArrowIcon />}
                  disabled={props.type === 'Edit' && isFetching}
                >
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent className="bg-shades-0 z-[999999]">
                  {locations.map(location => (
                    <SelectItem
                      key={location.publicId}
                      value={location.publicId}
                    >
                      {location.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-1">
              {staffOptions && (
                <>
                  {' '}
                  <Label htmlFor="teamLeaderPublicId">Select team lead</Label>
                  <Select
                    required
                    name="teamLeaderPublicId"
                    value={data.teamLeaderPublicId}
                    onValueChange={value =>
                      setData(prev => ({ ...prev, teamLeaderPublicId: value }))
                    }
                  >
                    <SelectTrigger
                      className="w-full"
                      selectIcon={<DownArrowIcon />}
                      disabled={props.type === 'Edit' && isFetching}
                    >
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[999999]">
                      {staffs.map(staff => (
                        <SelectItem key={staff.publicId} value={staff.publicId}>
                          {getFullName(staff)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>{' '}
                </>
              )}
            </div>
            <div className="space-y-1">
              {staffOptions && (
                <>
                  <Label htmlFor="teamMembers">Add team members</Label>
                  <MultipleSelect
                    options={staffOptions}
                    selectedValues={data.teamMembersPublicIds}
                    setSelectedValues={teamMemberSelect}
                    disabled={props.type === 'Edit' && isFetching}
                  />
                </>
              )}
            </div>
          </div>
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            variant={'primary'}
            onClick={addTeamHandler}
            className="w-full"
            isLoading={isLoading}
            disabled={isLoading}
          >
            {props.type === 'Create' ? 'Add team' : 'Save team'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const MultipleSelect = ({
  options,
  selectedValues,
  setSelectedValues,
  disabled,
}: {
  options: Record<string, string>;
  selectedValues: string[];
  setSelectedValues: (value: string[]) => void;
  disabled: boolean;
}) => {
  const onOptionSelect = (value: string) => {
    if (value === 'Select') return;
    const newSelectedValues = [...selectedValues];
    const selectIndex = newSelectedValues.findIndex(
      selectValue => selectValue === value
    );
    if (selectIndex >= 0) {
      newSelectedValues.splice(selectIndex, 1);
      setSelectedValues(newSelectedValues);
    } else {
      newSelectedValues.push(value);
      setSelectedValues(newSelectedValues);
    }
  };
  return (
    <>
      <Select
        required
        defaultValue="Select"
        name="multiselect"
        onValueChange={onOptionSelect}
      >
        <SelectTrigger
          disabled={disabled}
          className="w-full"
          selectIcon={<DownArrowIcon />}
        >
          <SelectValue placeholder="Select" />
        </SelectTrigger>
        <SelectContent className="bg-shades-0 z-[999999]">
          <SelectItem value={'Select'}>Select</SelectItem>
          {Object.keys(options).map(key => (
            <SelectItem value={key} key={key}>
              {options[key]}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {selectedValues.length > 0 && (
        <div className="border border-neutral-100 rounded-lg border-collapse p-3 space-y-3">
          {selectedValues.map(value => (
            <div key={value} className="w-full flex justify-between ">
              <p className="text-[14px]">{options[value]}</p>
              <Button
                onClick={() => onOptionSelect(value)}
                variant={'link'}
                size={'xmall'}
              >
                <CrossIcon />
              </Button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default AddAndEditTeamModal;
