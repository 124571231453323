import React, { useEffect } from 'react';
import RequestedTests from '../components/sections/Patient/PatientInfoTabs/Labs/RequestedTests';
import { PAGE_TITLE } from '../constants/TitleConstants';

const Labs = () => {
  useEffect(() => {
    document.title = PAGE_TITLE.LABS;
  }, []);

  return <RequestedTests />;
};

export default Labs;
