import React, { useEffect } from 'react';
import LoginImg from '../assets/images/LoginImg.png';
import Logo from '../assets/icons/Logo';
import { UpdatePasswordForm } from '../components/sections';
import { PAGE_TITLE } from '../constants/TitleConstants';

const ForgetPassword = () => {
  useEffect(() => {
    document.title = PAGE_TITLE.RESET_PASSWORD;
  }, []);

  return (
    <main className="flex min-h-screen">
      <section className="flex justify-center items-center w-full md:w-1/2">
        <div className="h-[max-content] flex flex-col justify-center w-[70%] sm:w-[55%] md:w-[65%]  gap-y-8 items-center md:items-start border border-neutral-100 rounded-[8px] p-[24px] md:p-0 md:border-0 bg-primary-gradient-login md:bg-none">
          <Logo />
          <div>
            <h2 className="text-4xl font-medium text-neutral-900">
              Update your Password
            </h2>
            <p className="mt-3 text-sm text-neutral-500 text-center md:text-left">
              Change your default password to new one.
            </p>
          </div>
          <UpdatePasswordForm />
          <p className="text-sm text-neutral-400">
            Forgot your password?{' '}
            <span className="cursor-pointer text-neutral-600">
              Contact admin
            </span>
          </p>
        </div>
      </section>
      <img src={LoginImg} alt="loginImg" className="hidden md:block md:w-1/2" />
    </main>
  );
};

export default ForgetPassword;
