import { createSlice } from '@reduxjs/toolkit';
const initialState: LoaderState = {
  loader: {
    isLoading: false,
  },
};

export const loadingSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loading: (state: LoaderState) => {
      state.loader.isLoading = true;
    },
    doneLoading: (state: LoaderState) => {
      state.loader = initialState.loader;
    },
  },
});

export default loadingSlice.reducer;
export const { loading, doneLoading } = loadingSlice.actions;
