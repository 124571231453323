import { ColumnDef } from '@tanstack/react-table';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  DirectedArrowIcon,
  OutlinedAddIcon,
  SearchIcon,
} from '../../../../assets/icons';
import { DataTable } from '../../../common';
import {
  Button,
  Input,
  Slider,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../UI';
import AddPatientModal from './AddPatientModal';
import {
  getPatients,
  searchPatient,
} from '../../../../services/api/endPoints/patients';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getFullName } from '../../../../utils/Helper';
import { getReadableDate } from '../../../../utils/date.utl';
import { ROUTES } from '../../../../constants/NavigationConstants';
import { notify } from '../../../common/Alert';
import {
  ERROR_MSG,
  ERROR_TITLE,
} from '../../../../constants/NotificationConstants';
import { Permission } from '../../../../types/enums';

const AllPatient = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tableData, setTableData] = useState<PatientLite[]>([]);
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>('');
  const [openAddPatientModal, setOpenPatientModal] = useState<boolean>(false);

  const getTooltip = (trigger: ReactNode) => {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>{trigger}</TooltipTrigger>
          <TooltipContent>
            <p>You do not have permission to view this patient</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  const patientColumn: ColumnDef<PatientLite>[] = [
    {
      accessorKey: 'publicId',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Patient ID
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill="#808080" />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill="#808080" />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        if (
          row.original.patientPermission?.detailsPermission &&
          Number(
            Permission[row.original.patientPermission?.detailsPermission]
          ) > Permission.none
        ) {
          return (
            <Link
              to={`${ROUTES.ROUTE_TO_PATIENT_PROFILE}${row.original.publicId}`}
              state={{ from: ROUTES.ROUTE_TO_PATIENT }}
            >
              <p className="px-1.5 py-0.75 rounded-[20px] border border-primary-500 bg-primary-50 font-medium text-primary-500 inline-block">
                {row.original.publicId}
              </p>
            </Link>
          );
        } else {
          return getTooltip(
            <div>
              <p className="px-1.5 py-0.75 rounded-[20px] border border-gray-500 bg-gray-50 font-medium text-gray-500 inline-block">
                {row.original.publicId}
              </p>
            </div>
          );
        }
      },
    },
    {
      accessorKey: 'fullName',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Patient Name
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill="#808080" />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill="#808080" />
            )}
          </button>
        );
      },
      cell: ({ row }) => {
        if (
          row.original.patientPermission?.detailsPermission &&
          Number(
            Permission[row.original.patientPermission?.detailsPermission]
          ) > Permission.none
        ) {
          return (
            <Link
              to={`${ROUTES.ROUTE_TO_PATIENT_PROFILE}${row.original.publicId}`}
              state={{ from: ROUTES.ROUTE_TO_PATIENT }}
            >
              {row.original.fullName}
            </Link>
          );
        } else {
          return getTooltip(
            <div>
              <p className="text-gray-500"> {row.original.fullName} </p>
            </div>
          );
        }
      },
    },

    {
      accessorKey: 'dateOfBirth',
      header: 'Date of Birth',
      cell: ({ row }) => {
        if (
          row.original.patientPermission?.detailsPermission &&
          Number(
            Permission[row.original.patientPermission?.detailsPermission]
          ) > Permission.none
        ) {
          return getReadableDate(row.original.dateOfBirth);
        } else {
          return getTooltip(
            <div>
              <p className="text-gray-500">
                {getReadableDate(row.original.dateOfBirth)}
              </p>
            </div>
          );
        }
      },
    },
    {
      accessorKey: 'email',
      header: 'Email ID',
      cell: ({ row }) => {
        if (
          row.original.patientPermission?.detailsPermission &&
          Number(
            Permission[row.original.patientPermission?.detailsPermission]
          ) > Permission.none
        ) {
          return row.original.email;
        } else {
          return getTooltip(
            <div>
              <p className="text-gray-500"> {row.original.email} </p>
            </div>
          );
        }
      },
    },
    {
      accessorKey: 'phoneNo',
      header: 'Contact Number',
      cell: ({ row }) => {
        if (
          row.original.patientPermission?.detailsPermission &&
          Number(
            Permission[row.original.patientPermission?.detailsPermission]
          ) > Permission.none
        ) {
          return row.original.phoneNo;
        } else {
          return getTooltip(
            <div>
              <p className="text-gray-500"> {row.original.phoneNo} </p>
            </div>
          );
        }
      },
    },
    // {
    //   id: 'actions',
    //   cell: () => {
    //     const dropDownMenu: {
    //       label: string;
    //       Icon: ReactNode;
    //       action: () => void;
    //     }[] = [
    //       {
    //         label: 'Update details',
    //         Icon: <PenIcon />,
    //         action: () => {},
    //       },
    //       {
    //         label: 'Share Profile',
    //         Icon: <ShareIcon />,
    //         action: () => {},
    //       },
    //       {
    //         label: 'Delete',
    //         Icon: <DeleteIcon stroke="#EF4444" />,
    //         action: () => {},
    //       },
    //     ];
    //     return (
    //       <div className="flex justify-end">
    //         <ActionDropdown
    //           side="bottom"
    //           align="end"
    //           sideOffset={2}
    //           alignOffset={18}
    //           drownDownItems={dropDownMenu}
    //         >
    //           <button className="w-8 h-8 p-0 outline-none border-none">
    //             <span className="sr-only">Open menu</span>
    //             <DotMenu />
    //           </button>
    //         </ActionDropdown>
    //       </div>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    const handleSearch = async () => {
      setIsLoading(true);
      await searchPatient(search)
        .then(res => {
          const data: PatientLite[] = [];
          res.data.forEach((row: PatientLite) => {
            data.push({
              ...row,
              fullName: getFullName(row),
              phoneNo: `${row.phoneNo ? row.phoneNo : ''}`,
            });
          });
          setTableData(data);
          setIsLoading(false);
        })
        .catch(err => {
          notify.error({
            title: ERROR_TITLE.GENERIC_PATIENT_SEARCH,
            message: err.data,
          });
          setIsLoading(false);
        });
    };

    if (search.length > 3) {
      handleSearch();
    }
  }, [dispatch, search]);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        setIsLoading(true);
        const [res, err] = await getPatients();
        if (err) {
          notify.error({
            title: ERROR_TITLE.GENERIC_PATIENT_SEARCH,
            message: err.data,
          });
          setIsLoading(false);
          return;
        }
        const data: PatientLite[] = [];
        res.data.forEach((row: PatientLite) => {
          data.push({
            ...row,
            fullName: getFullName(row),
            phoneNo: row.phoneNo ?? '',
          });
        });
        setTableData(data);
        setIsLoading(false);
      } catch (error) {
        notify.error({
          title: ERROR_TITLE.GENERIC_PATIENT_SEARCH,
          message: ERROR_MSG.GENERIC,
        });
        setIsLoading(false);
      }
    };
    fetchPatients();
  }, []);

  return (
    <>
      <AddPatientModal
        open={openAddPatientModal}
        onClose={() => setOpenPatientModal(false)}
      />
      <div className="custom-height w-full rounded-md border border-neutral-100 flex flex-col">
        <div className="flex-none px-[12px] py-[16px] w-full flex justify-between items-center ">
          <Input
            leadingIcon={<SearchIcon />}
            className="w-[400px] focus:border-neutral-100"
            placeholder="Search for patients"
            value={search}
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          <div className="flex gap-3 items-center justify-center">
            {/* <Button
              variant={'link'}
              leadingIcon={<FilterIcon />}
              size={'xmall'}
            >
              Filter
            </Button> */}
            <Button
              variant={'secondary'}
              size={'xmall'}
              trailingIcon={<OutlinedAddIcon stroke="#8D5EB0" />}
              onClick={() => setOpenPatientModal(true)}
            >
              Add Patient
            </Button>
          </div>
        </div>

        <Slider className="flex-auto" variant={'vertical'}>
          <DataTable
            columns={patientColumn}
            data={tableData}
            tableBodyRowClass={'border-b'}
            isLoading={isLoading}
          />
        </Slider>
      </div>
    </>
  );
};

export default AllPatient;
