/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';

import SectionForm from './SectionForm';
import {
  ActiveStepIcon,
  LeftArrowIcon,
  DisableStepIcon,
  RightArrow,
  SuccessStepIcon,
  CrossStepIcon,
} from '../../../../../assets/icons';
import { Button, Loader } from '../../../../UI';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getFormFormat,
  formFilled,
  getFilledForms,
  updateFormFilled,
} from '../../../../../services/api';
import {
  FillFormRequest,
  FilledForm,
  FormFormatDetails,
} from '../../../../../types/form/type';
import { notify } from '../../../../common/Alert';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { STEPPER_COLORS } from '../../../../../constants/ColorConstants';

const MedicalFormNotes = () => {
  const [currStep, setCurrentStep] = useState<number>(0);
  const { formatId, id } = useParams();
  const [formFormatData, setFormatData] = useState<FormFormatDetails>();
  const [sectionsName, setSetSectionsName] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<Record<string, any>>({});
  const [filledForm, setFilledForm] = useState<FilledForm | null>(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isDrafting, setIsDrafting] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] =
    useState<Record<string, any>>();
  const [errorSteps, setErrorSteps] = useState<number[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector<RootState, UserEntities>(state => state.user.user);

  useEffect(() => {
    const getFormFormatData = async () => {
      if (!formatId) return;
      setIsLoading(true);
      setValidationErrors({});
      setErrorSteps([]);

      let [response, error] = [null, null];
      let [formvalueResponse, formValueResponseError] = [null, null];
      if (!location.state.editMode) {
        [response, error] = await getFormFormat(formatId);
      } else {
        if (!id) return;
        const res = await Promise.all([
          getFormFormat(formatId),
          getFilledForms(id, formatId, location.state.filledFormPublicId),
        ]);
        response = res[0][0];
        error = res[0][1];
        formvalueResponse = res[1][0];
        formValueResponseError = res[1][1];
      }

      if (error || formValueResponseError) {
        console.log(error, formValueResponseError);
      } else {
        if (response) {
          const formData: Record<string, string> = {};
          const sections: string[] = [];
          for (const section of (response as FormFormatDetails).sections) {
            sections.push(section.name);
            for (const field of section.fields) {
              formData[field.publicId] = '';
              // if in edit mode intitiallize the value
              if (formvalueResponse && location.state.editMode) {
                formData[field.publicId] = formvalueResponse[
                  'formFieldValuesMap'
                ][field.publicId]
                  ? formvalueResponse['formFieldValuesMap'][field.publicId]
                  : '';
              }
            }
          }
          setData(formData);
          setFormatData(response);
          setSetSectionsName(sections);
          setFilledForm(formvalueResponse);
        }
      }

      setIsLoading(false);
    };
    getFormFormatData();
  }, []);

  const goNext = async () => {
    if (formFormatData && currStep == formFormatData.sections.length - 1) {
      // Some API call may be
      setIsFormSubmitting(true);
      try {
        await submitForm();
        notify.success({
          title:
            (!location.state.editMode ? 'Saved - ' : 'Edited - ') +
            location.state.formFormatName,
        });
        backHandler();
      } catch (error) {
        notify.error({
          title: 'Failed to save',
          message:
            error instanceof Error
              ? error.message
              : 'Please try again or contact admin.',
        });
      } finally {
        setIsFormSubmitting(false);
      }
      return;
    }
    setCurrentStep(prevStep => prevStep + 1);
  };
  const goPrev = () => {
    if (currStep == 0) {
      return;
    }
    setCurrentStep(prevStep => prevStep - 1);
  };

  const onInputHandler = (publicId: string, value: any) => {
    setData(prevData => ({
      ...prevData,
      [publicId]: value,
    }));
  };

  const saveDraft = async () => {
    setIsDrafting(true);
    try {
      await submitForm(true);
      notify.success({
        message: 'Draft is saved successfully',
        title: 'Drafted - ' + location.state.formFormatName,
      });
      backHandler();
    } catch (error) {
      notify.error({
        message: 'Unable to save the draft',
        title: 'Failed Draft - ' + location.state.formFormatName,
      });
    } finally {
      setIsDrafting(false);
    }
  };

  const submitForm = async (isDraft: boolean = false) => {
    if (!formFormatData) return;
    const formFieldValues = [];
    for (const formFormatFieldPublicId in data) {
      formFieldValues.push({
        formFormatFieldPublicId: formFormatFieldPublicId,
        fieldKey: formFormatFieldPublicId,
        fieldValue: data[formFormatFieldPublicId],
      });
    }
    let response;
    let error;
    if (!location.state.editMode) {
      const formData: FillFormRequest = {
        isDraft: isDraft,
        patientFilled: false,
        patientPublicId: id,
        formFormatPublicId: formFormatData.publicId,
        formFieldValues: formFieldValues,
        staffPublicId: user.staff.publicId,
      };
      [response, error] = await formFilled(formData);
    } else {
      if (!filledForm) return;
      const formData: FillFormRequest = {
        publicId: filledForm.publicId,
        isDraft: isDraft,
        staffPublicId: user.staff.publicId,
        formFieldValues: formFieldValues,
      };
      [response, error] = await updateFormFilled(formData);
    }

    if (response) {
      if (response.formFieldValidationErrors) {
        setValidationErrors(response.formFieldValidationErrors);
        findSectionsWithErrors(response.formFieldValidationErrors);
        throw new Error(
          'Validation error. Please check the fields and try again.'
        );
      }
      setFilledForm(response);
    } else {
      if (error.data && error.data.formFieldValidationErrors) {
        setValidationErrors(error.data.formFieldValidationErrors);
        findSectionsWithErrors(error.data.formFieldValidationErrors);
        throw new Error(
          'Validation error. Please check the fields and try again.'
        );
      } else {
        throw error;
      }
    }
  };

  const findSectionsWithErrors = (validationErrors: Record<string, any>) => {
    const errorSteps = [];
    if (!formFormatData) return;
    for (const section of formFormatData.sections) {
      for (const field of section.fields) {
        if (validationErrors[field.publicId]) {
          errorSteps.push(sectionsName.indexOf(section.name));
          break;
        }
      }
    }

    setErrorSteps(errorSteps);
  };

  const backHandler = () => {
    navigate(location.state.from, {
      state: {
        formFormatPublicId: formatId,
      },
    });
  };
  return (
    <>
      {!isLoading ? (
        <>
          {' '}
          <div className="pt-4 px-4 space-y-[28px]">
            {sectionsName.length > 1 && (
              <Stepper
                activeStep={currStep}
                steps={sectionsName}
                errorSteps={errorSteps}
              />
            )}
            <div>
              {formFormatData ? (
                <SectionForm
                  fields={formFormatData?.sections[currStep].fields}
                  onInput={onInputHandler}
                  name={
                    formFormatData?.sections.length > 1
                      ? formFormatData?.sections[currStep].name
                      : ''
                  }
                  data={data}
                  validationErrors={validationErrors}
                />
              ) : (
                <div className="h-[500px] flex items-center justify-center font-semibold text-[20px]">
                  Unable to fetch format <br /> Please try agin later
                </div>
              )}
            </div>
          </div>
          <div className="py-4 px-3 flex justify-between border-t border-neutral-100">
            <div className="flex gap-4">
              {/* <Button variant={'link'} size={'small'}>
                Reset note
              </Button> */}
              <Button
                variant={'outlined'}
                size={'small'}
                onClick={saveDraft}
                isLoading={isDrafting}
              >
                Save as Draft
              </Button>
            </div>
            <div className="flex gap-4">
              <Button
                variant={currStep === 0 ? 'link' : 'outlined'}
                leadingIcon={<LeftArrowIcon />}
                disabled={currStep == 0}
                size={'small'}
                onClick={goPrev}
              >
                Previous
              </Button>
              <Button
                size={'small'}
                variant={
                  currStep === sectionsName.length - 1 ? 'primary' : 'secondary'
                }
                trailingIcon={<RightArrow />}
                onClick={goNext}
                isLoading={isFormSubmitting}
              >
                {currStep === sectionsName.length - 1 ? 'Save Report' : 'Next'}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="h-[500px] w-full flex justify-center items-center">
          <Loader />
        </div>
      )}
    </>
  );
};

const Stepper = ({
  activeStep,
  steps,
  errorSteps,
}: {
  activeStep: number;
  steps: string[];
  errorSteps?: number[];
}) => {
  return (
    <div className="flex items-center justify-center relative w-full">
      {steps.map((step, idx) => (
        <div
          key={idx}
          className={
            `flex flex-col items-center justify-center relative w-[224px] ${errorSteps?.includes(idx) ? 'step-item-error' : 'step-item'} ` +
            `${activeStep >= idx + 1 && errorSteps?.includes(idx) ? 'step-active-error' : activeStep >= idx + 1 ? 'step-active' : ''} `
          }
        >
          {activeStep == idx ? (
            <ActiveStepIcon
              stroke={
                errorSteps?.includes(activeStep)
                  ? STEPPER_COLORS.FAILURE
                  : STEPPER_COLORS.ACTIVE
              }
            />
          ) : activeStep > idx ? (
            errorSteps?.includes(idx) ? (
              <CrossStepIcon />
            ) : (
              <SuccessStepIcon />
            )
          ) : (
            <DisableStepIcon />
          )}
          <p className="text-[14px] text-neutral-900 font-medium">{step}</p>
        </div>
      ))}
    </div>
  );
};

export default MedicalFormNotes;
