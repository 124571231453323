import React from 'react';
import { Button } from '../../../../UI';
import { DownloadIcon, LeftArrowIcon } from '../../../../../assets/icons';
import reportImg from '../../../../../assets/images/ReportView.png';
import { useNavigate } from 'react-router-dom';
const AppointmentReportView = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="border-b border-neutral-100 p-3 flex justify-between items-center">
        <Button
          variant={'outlined'}
          size={'small'}
          leadingIcon={<LeftArrowIcon />}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <h1 className="text-neutral-900 font-medium whitespace-nowrap">
          Appontment File
        </h1>
        <div className="flex gap-3">
          <Button
            variant="outlined"
            size={'small'}
            leadingIcon={<DownloadIcon />}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            size={'small'}
            leadingIcon={<DownloadIcon />}
          >
            Print
          </Button>
        </div>
      </div>
      <div className="flex item-center justify-center p-8">
        {/* Currently showing the image only untli API implemented */}
        <img className="w-[497px] height-[699px]" src={reportImg} />
      </div>
    </>
  );
};

export default AppointmentReportView;
