import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Excalidraw,
  convertToExcalidrawElements,
} from '@excalidraw/excalidraw';
import { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';
import { Button } from '../../../../UI';
import {
  FullScreenIcon,
  LeftArrowIcon,
  MinimizeIcon,
} from '../../../../../assets/icons';
import {
  BinaryFiles,
  ExcalidrawImperativeAPI,
} from '@excalidraw/excalidraw/types/types';
import {
  formFilled,
  getHandWrittenFormFormat,
  updateFormFilled,
  uploadFile,
} from '../../../../../services/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LoaderIcon from '../../../../../assets/icons/Loader.svg';
import { throttle } from 'lodash';
import { notify } from '../../../../../components/common/Alert';
import { v4 as uuidv4 } from 'uuid';
import { ExcalidrawElementSkeleton } from '@excalidraw/excalidraw/types/data/transform';
import { ERROR_MSG } from '../../../../../constants/NotificationConstants';
import { RootState } from '@/redux/store/store';
import { useSelector } from 'react-redux';
import { getFilledForm } from '../../../../../services/api/endPoints/form/filled';
import {
  CanvasJSONData,
  FillFormRequest,
  FilledForm,
} from '../../../../../types/form/type';
import { downloadFileByUrl } from '../../../../../services/api/endPoints/files.api';

const HandWrittenNotes = () => {
  const [currentState, setCurrentState] = useState<
    readonly ExcalidrawElement[]
  >([]);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const canvasContainer = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageFileData, setImageFileData] = useState<BinaryFiles | undefined>(
    undefined
  );
  const [elementSkeleton, setElementSkeleton] = useState<
    ExcalidrawElementSkeleton[] | null
  >(null);
  const location = useLocation();
  const { id, formatId } = useParams();
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const navigate = useNavigate();
  const [isStateUpdated, setIsStateUpdated] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDrafting, setIsDrafting] = useState<boolean>(false);
  const [filledForm, setFilledForm] = useState<FilledForm | null>(null);
  const [excalidrawAPI, setExcalidrawAPI] =
    useState<ExcalidrawImperativeAPI | null>(null);

  useEffect(() => {
    const init = async () => {
      // persisting nav state
      if (!localStorage.getItem('location_state')) {
        localStorage.setItem('location_state', JSON.stringify(location.state));
      } else {
        if (!location.state)
          location.state = JSON.parse(localStorage.getItem('location_state')!);
      }
      // API works
      try {
        if (!formatId) throw Error('Unable to get the form');
        setIsLoading(true);
        const [res, error] = await getHandWrittenFormFormat(formatId);

        if (error) {
          throw Error(error as string);
        }

        //check in local storage
        const value = localStorage.getItem(getLocalStorageKey());
        if (value) {
          const canvasData: CanvasJSONData = JSON.parse(value);
          const state = JSON.parse(canvasData.state);
          if (state && state.length > 0) {
            setElementSkeleton(state);
            setCurrentState(state);

            if (canvasData.files) {
              const files = JSON.parse(canvasData.files);
              setImageFileData({
                notes: {
                  mimeType: 'image/png',
                  dataURL: res as string & {
                    _brand: 'DataURL';
                  },
                  id: 'notes' as string & { _brand: 'FileId' },
                  created: Date.now(),
                },
                ...files,
              });
            } else {
              setImageFileData({
                notes: {
                  mimeType: 'image/png',
                  dataURL: res as string & {
                    _brand: 'DataURL';
                  },
                  id: 'notes' as string & { _brand: 'FileId' },
                  created: Date.now(),
                },
              });
            }
            return;
          }
        }

        if (location.state.editMode) {
          const [res, error] = await getFilledForm(
            location.state.filledFormPublicId
          );
          if (error) {
            notify.error({
              title: `Unable to load ${location.state.formFormatName}`,
              message: error,
            });
            return;
          }
          setFilledForm(res);
          if (res && res.handwrittenNoteUrl) {
            const [res1, error1] = await downloadFileByUrl({
              url: res.handwrittenNoteUrl,
            });
            if (error1) {
              notify.error({
                title: 'Unable to download the notes',
                message: error1,
              });
              return;
            }
            //parse response blob to json
            const reader = new FileReader();
            reader.readAsText(res1 as Blob);
            reader.onload = function () {
              const canvasData: CanvasJSONData = JSON.parse(
                reader.result as string
              );
              setElementSkeleton(JSON.parse(canvasData.state));
              setCurrentState(JSON.parse(canvasData.state));
              if (canvasData.files) {
                const files = JSON.parse(canvasData.files);
                setImageFileData({
                  notes: {
                    mimeType: 'image/png',
                    dataURL: res as string & {
                      _brand: 'DataURL';
                    },
                    id: 'notes' as string & { _brand: 'FileId' },
                    created: Date.now(),
                  },
                  ...files,
                });
              } else {
                setImageFileData({
                  notes: {
                    mimeType: 'image/png',
                    dataURL: res as string & {
                      _brand: 'DataURL';
                    },
                    id: 'notes' as string & { _brand: 'FileId' },
                    created: Date.now(),
                  },
                });
              }
            };
            return;
          }
        }

        setImageFileData({
          notes: {
            mimeType: 'image/png',
            dataURL: res as string & {
              _brand: 'DataURL';
            },
            id: 'notes' as string & { _brand: 'FileId' },
            created: Date.now(),
          },
        });

        const img = new Image();

        img.src = res as string;

        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          setElementSkeleton([
            {
              type: 'image',
              x: 50,
              y: 50,
              fileId: 'notes' as string & { _brand: 'FileId' },
              width: imgWidth,
              height: imgHeight,
              locked: true,
            },
          ]);
        };
      } catch (e) {
        notify.error({
          title: 'Unable to get the formats',
          message: e instanceof Error ? e.message : ERROR_MSG.GENERIC,
        });
      } finally {
        setIsLoading(false);
      }
    };
    init();
  }, []);

  const toggleFullScreen = () => {
    if (isFullScreen) {
      setIsFullScreen(false);
    } else {
      setIsFullScreen(true);
    }
  };

  const submitForm = async (
    handwrittenNoteUrl: string,
    isDraft: boolean = false
  ) => {
    let [response, error] = [null, null];

    if (!location.state.editMode) {
      const formData: FillFormRequest = {
        isDraft: isDraft,
        patientFilled: false,
        patientPublicId: id,
        formFormatPublicId: formatId,
        handwrittenNoteUrl: handwrittenNoteUrl,
        staffPublicId: user.staff.publicId, // TODO: Need to change to dynmic value
      };
      [response, error] = await formFilled(formData);
    } else {
      const formData: FillFormRequest = {
        publicId: location.state.filledFormPublicId,
        isDraft: isDraft,
        patientFilled: false,
        staffPublicId: user.staff.publicId,
        locationPublicId: location.state.locationPublicId,
        handwrittenNoteUrl: handwrittenNoteUrl,
      };
      [response, error] = await updateFormFilled(formData);
    }
    if (response) {
      return;
    } else if (error) {
      throw new Error('Unable to submit the form');
    }
  };

  const fileUploader = async () => {
    const form = new FormData();
    //if edit mode, then use handwrittenNOeUrl from filledForm
    if (
      location.state.editMode &&
      filledForm &&
      filledForm.handwrittenNoteUrl
    ) {
      //extract containerName and fileName from url
      const { containerName, fileName } = extractContainerAndFileName(
        filledForm.handwrittenNoteUrl
      )!;
      console.log(containerName, fileName);
      form.append('containerName', containerName);
      form.append('fileName', fileName);
    } else {
      form.append('containerName', `${id}/${formatId}`);
      const fileName = uuidv4();
      form.append('fileName', `${fileName}.json`);
    }

    const canvasData: CanvasJSONData = {
      state: JSON.stringify(currentState),
      files:
        excalidrawAPI && excalidrawAPI.getFiles()
          ? JSON.stringify(excalidrawAPI.getFiles())
          : undefined,
    };

    const blob = new Blob([JSON.stringify(canvasData)], {
      type: 'application/json',
    });
    form.append('file', blob);
    const [res, error] = await uploadFile(form);
    if (error) throw Error('Unable to upload file');
    return res;
  };

  const extractContainerAndFileName = (
    url: string
  ): { containerName: string; fileName: string } | null => {
    const parts = url.split('/');

    // Remove 'storage' if it's present
    if (parts.length > 1 && parts[0] === 'storage') {
      parts.shift(); // Remove the first element
    }

    // Extract the container name and file name
    if (parts.length >= 2) {
      const fileName = parts.pop()!;
      const containerName = parts.join('/');
      return { containerName, fileName };
    } else {
      // If the URL is in incorrect format
      return null;
    }
  };

  const backHandler = () => {
    // localStorage.removeItem(getLocalStorageKey());
    localStorage.removeItem('location_state');
    navigate(location.state.from, {
      state: {
        formFormatPublicId: formatId,
        showingAll: location.state.showingAll,
      },
    });
  };

  const saveHandler = async () => {
    try {
      setIsSaving(true);
      const res = await fileUploader();
      await submitForm(res);
      notify.success({
        message: 'Your work is saved successfully',
        title: 'Saved - ' + location.state.formFormatName,
      });
      localStorage.removeItem(getLocalStorageKey());
      backHandler();
    } catch (error) {
      console.log(error);
      notify.error({
        message: error instanceof Error ? error.message : ERROR_MSG.GENERIC,
        title: 'Failed to save - ' + location.state.formFormatName,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const draftHandler = async () => {
    try {
      setIsDrafting(true);
      const res = await fileUploader();
      await submitForm(res, true);
      notify.success({
        message: 'Draft is saved successfully',
        title: 'Drafted - ' + location.state.formFormatName,
      });
      localStorage.removeItem(getLocalStorageKey());
      backHandler();
    } catch (error) {
      notify.error({
        message: error instanceof Error ? error.message : ERROR_MSG.GENERIC,
        title: 'Failed to save draft - ' + location.state.formFormatName,
      });
    } finally {
      setIsDrafting(false);
    }
  };

  const updateCanvasState = (elements: readonly ExcalidrawElement[]) => {
    setCurrentState(elements);
    const canvasData: CanvasJSONData = {
      state: JSON.stringify(elements),
      files: JSON.stringify(excalidrawAPI?.getFiles()),
    };
    localStorage.setItem(getLocalStorageKey(), JSON.stringify(canvasData));
    setIsStateUpdated(true);
  };

  const getLocalStorageKey = () => {
    if (location.state.editMode) {
      return id + '#' + location.state.filledFormPublicId;
    }
    return id + '#' + formatId;
  };

  const throttleUpdate = useMemo(() => throttle(updateCanvasState, 1000), []);

  const canvasWriteHandler = (elements: readonly ExcalidrawElement[]) => {
    setIsStateUpdated(false);
    throttleUpdate(elements);
  };

  return (
    <>
      <div className="relative border-b border-neutral-100 p-3 flex justify-center items-center">
        <Button
          variant={'outlined'}
          size={'small'}
          leadingIcon={<LeftArrowIcon />}
          onClick={backHandler}
          className="absolute left-5 top-1.5"
        >
          Back
        </Button>

        <h1 className="text-neutral-900 font-medium whitespace-nowrap">
          {location.state.formFormatName}
        </h1>
      </div>

      <div
        className={`flex flex-col ${
          isFullScreen ? 'custom-height' : 'h-[520px]'
        }`}
      >
        {!isLoading ? (
          imageFileData ? (
            <>
              <div className="flex-auto" ref={canvasContainer}>
                <Excalidraw
                  excalidrawAPI={api => setExcalidrawAPI(api)}
                  initialData={{
                    files: imageFileData,
                    elements: convertToExcalidrawElements(elementSkeleton),
                  }}
                  onChange={canvasWriteHandler}
                />
              </div>
              <div className="flex-none py-4 px-3 flex justify-between border-t border-neutral-100">
                <div className="flex gap-2">
                  <Button
                    variant={'outlined'}
                    size={'small'}
                    onClick={draftHandler}
                    disabled={!isStateUpdated}
                    isLoading={isDrafting}
                  >
                    Save as Draft
                  </Button>
                </div>

                <div className="flex gap-4">
                  <Button
                    variant={'outlined'}
                    size={'xmall'}
                    onClick={toggleFullScreen}
                    className="md:px-2 py-4 rounded-md"
                  >
                    {isFullScreen ? <MinimizeIcon /> : <FullScreenIcon />}
                  </Button>
                  <Button
                    size={'small'}
                    variant={'primary'}
                    onClick={saveHandler}
                    disabled={!isStateUpdated}
                    isLoading={isSaving}
                    className="min-w-[120px]"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div className="w-full flex-auto flex justify-center items-center">
              No format found
            </div>
          )
        ) : (
          <div className="w-full flex-auto bg-white flex items-center justify-center">
            <img src={LoaderIcon} className="w-[40px] h-[40px] animate-spin" />
          </div>
        )}
      </div>
    </>
  );
};

export default HandWrittenNotes;
