import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { createTestResult } from '../utils/pdfUtils/labResultPdf';

type UseResultDownload = {
  downloadTDLResult: (test: TDLRequestedTestResponse) => Promise<void>;
};

const useResultDownload = (): UseResultDownload => {
  const client = useSelector<RootState, ClientEntities>(
    state => state.client.client
  );

  const downloadTDLResult = async (
    test: TDLRequestedTestResponse
  ): Promise<void> => {
    test && client && createTestResult(test, client);
  };

  return {
    downloadTDLResult,
  };
};

export default useResultDownload;
