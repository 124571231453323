/* eslint-disable @typescript-eslint/no-unused-vars */
import { Slider } from '../../../UI';
import React from 'react';
import StageCard from './StageCard';

const StageColum: React.FC<{
  events: BoardEvent[];
  stage: StageLite;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setEventToUpdateId: any;
  removeArchivedEvent: (archivedEvent: BoardEvent) => void;
}> = ({ events, stage, setEventToUpdateId, removeArchivedEvent }) => {
  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    event: BoardEvent
  ) => {
    setEventToUpdateId(event.eventPublicId);
    e.dataTransfer.setData('text/plain', event.eventPublicId);
  };
  const currentStageEvents = events.filter(
    event => event.currentStage.publicId === stage.publicId
  );

  return (
    <div className="h-[367px] w-[300px] p-3 rounded-lg border border-neutral-100 bg-neutral-50 space-y-2 flex flex-col">
      <div className="flex gap-2 items-center">
        <p className="font-medium  text-[14px]">{stage.name}</p>
        <span
          className={
            'px-[4px] rounded-full min-w-2 text-[12px] font-medium ' +
            'border border-neutral-100 bg-shades-0 text-nutral-100'
          }
        >
          <p>{currentStageEvents.length}</p>
        </span>
      </div>
      <Slider variant={'vertical'} className="flex-grow gap-2">
        {currentStageEvents.map(event => (
          <StageCard
            key={event.eventPublicId}
            task={event}
            onDragStart={handleDragStart}
            removeArchivedEvent={removeArchivedEvent}
          />
        ))}
      </Slider>
    </div>
  );
};

export default StageColum;
