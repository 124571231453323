export const getFullName = (
  data: PatientLite | PatientInformation | StaffInformation
) => {
  const parts: string[] = [];

  if (data.title) parts.push(data.title);
  if (data.firstName) parts.push(data.firstName);
  if (data.lastName) parts.push(data.lastName);

  return parts.join(' ');
};

export const getAddress = (address: Address): string => {
  const parts: string[] = [];

  if (address.street1) parts.push(address.street1);
  if (address.city) parts.push(address.city);
  if (address.state) parts.push(address.state);
  if (address.postalCode) parts.push(address.postalCode);
  if (address.country) parts.push(address.country);

  return parts.join(', ');
};
const appointmentTimeMatrix = [
  {
    name: 'First Consultation',
    time: 30,
  },
  {
    name: 'Follow Up Consultation',
    time: 15,
  },
  { name: 'Ultrasound Scan', time: 15 },
  { name: 'Anti-Mullerian (AMH) Test', time: 15 },
  { name: 'One-Stop Fertility Diagnosis Couple', time: 30 },
  { name: 'One-Stop Fertility Diagnosis Female', time: 30 },
  { name: 'HyCoSy (Tube Test)', time: 30 },
];

export const getTimeByServiceName = (name: string): number => {
  const appointment = appointmentTimeMatrix.find(app => app.name === name);

  return appointment ? appointment.time : 0;
};
