import { store } from '../../redux/store/store';
import axios from 'axios';
const URLS = {
  p4_AZURE: process.env.REACT_APP_API_URL, //dev
};

export const AZURE_URL = axios.create({
  baseURL: URLS.p4_AZURE,
});

AZURE_URL.interceptors.request.use(config => {
  const state = store.getState();
  const { token, tokenType } = state.auth.auth;
  config.headers.Authorization = `${tokenType} ${token}`;
  return config;
});
let isTokenExpiredAlertShown = false;

AZURE_URL.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 403 && !isTokenExpiredAlertShown) {
      isTokenExpiredAlertShown = true;
      alert('Access Token expired. Please logout and login again.');
      window.localStorage.clear();

      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);

// Set the maximum number of API calls
const MAX_API_CALLS = 20;

// Keep track of the last five API calls

// Add a request interceptor
AZURE_URL.interceptors.request.use(function (config) {
  const lastFiveAPICalls: (string | undefined)[] = [];
  // Check if the last five API calls are the same
  if (
    lastFiveAPICalls.length === MAX_API_CALLS &&
    lastFiveAPICalls.every(call => call === config.url)
  ) {
    return Promise.reject(new Error('All five API calls are the same.'));
  }

  // Remove the oldest API call if the maximum number of API calls has been reached
  if (lastFiveAPICalls.length === MAX_API_CALLS) {
    lastFiveAPICalls.shift();
  }

  // Add the current API call to the last five API calls
  lastFiveAPICalls.push(config.url);

  return config;
});
