import React, { useState } from 'react';
import QRCodeGenerator from './QRCode';
import { RightArrow } from '../../../assets/icons';
import { Button } from '../../UI';
import { useLocation } from 'react-router-dom';
type Props = {
  setNextStep: (value: number) => void;
};

const TwoFactoAuthQRForm = ({ setNextStep }: Props) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [hideQRView, setHideQRView] = useState<boolean>(false);
  const qrCode = location.state && location.state.qrCode;
  const goToNextStep = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setNextStep(2);
    }, 1000);
  };
  return (
    <div className="space-y-5">
      <p className="font-[14px] text-neutral-400">
        To authenticate, please scan this QR code with your{' '}
        <span className="underline text-neutral-900">
          Google Authenticator App
        </span>{' '}
        or{' '}
        <span className="underline neutral-900">
          Microsoft Authenticator App
        </span>
      </p>
      <QRCodeGenerator twopath_url={qrCode} />
      <p className="text-sm text-neutral-400">
        Not Working?{' '}
        <span className="cursor-pointer text-neutral-600">Refresh QR code</span>
      </p>
      <Button
        style={{ width: '100%' }}
        type="submit"
        variant="primary"
        size="medium"
        isLoading={isLoading}
        onClick={goToNextStep}
        trailingIcon={<RightArrow />}
      >
        Continue
      </Button>
    </div>
  );
};

export default TwoFactoAuthQRForm;
