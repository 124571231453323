import React from 'react';

interface ClockHistoryIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const ClockHistoryIcon: React.FC<ClockHistoryIconProps> = ({
  width = 14,
  height = 14,
  color = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M1 7C1 8.18669 1.35189 9.34673 2.01118 10.3334C2.67047 11.3201 3.60754 12.0892 4.7039 12.5433C5.80026 12.9974 7.00666 13.1162 8.17054 12.8847C9.33443 12.6532 10.4035 12.0818 11.2426 11.2426C12.0818 10.4035 12.6532 9.33443 12.8847 8.17054C13.1162 7.00666 12.9974 5.80026 12.5433 4.7039C12.0892 3.60754 11.3201 2.67047 10.3334 2.01118C9.34673 1.35189 8.18669 1 7 1C5.32263 1.00631 3.71265 1.66082 2.50667 2.82667L1 4.33333"
      stroke={color}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClockHistoryIcon;
