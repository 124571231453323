import React from 'react';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoperClose,
} from '../../../../UI';
import { ListFilter } from 'lucide-react';
import DateRangePicker from '../../../../../components/UI/DateRangePicker';
import { DateRange } from 'react-day-picker';

interface FilterProps {
  dateRange: DateRange;
  setDateRange: (value: DateRange) => void;
  returnFunction: () => void;
}

const LabsFilter: React.FC<FilterProps> = React.memo(
  ({ returnFunction, dateRange, setDateRange }) => {
    const applyFilters = () => {
      if (dateRange) returnFunction();
    };

    return (
      <Popover>
        <PopoverTrigger asChild>
          <div>
            <Button
              variant={'link'}
              size={'xmall'}
              leadingIcon={<ListFilter />}
            >
              <span className="sr-only">Open menu</span>
              Filter
            </Button>
          </div>
        </PopoverTrigger>
        <PopoverContent
          align="center"
          className="min-w-[310px] bg-shades-0 space-y-3"
          style={{ zIndex: 100 }}
        >
          <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
          {/* Action Menu Item */}
          <div className="flex gap-3 w-full">
            <PopoperClose className="w-full">
              <Button variant={'link'} size={'large'} className={'h-[30px] '}>
                Close
              </Button>
            </PopoperClose>
            <PopoperClose className="w-full">
              <Button
                variant={'primary'}
                size={'large'}
                className={'h-[30px] '}
                onClick={applyFilters}
              >
                Apply
              </Button>
            </PopoperClose>
          </div>
        </PopoverContent>
      </Popover>
    );
  }
);

export default LabsFilter;
