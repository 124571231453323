import { AllPatient } from '../components/sections';
import React, { useEffect } from 'react';
import { PAGE_TITLE } from '../constants/TitleConstants';

const Patients = () => {
  useEffect(() => {
    document.title = PAGE_TITLE.PATIENTS;
  }, []);

  return <AllPatient />;
};

export default Patients;
