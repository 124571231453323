import React from 'react';
import EmailManagerPage from '../components/sections/Admin/EmailManager/EmailManagerPage';

const EmailManager = () => {
  return (
    <div>
      <EmailManagerPage />
    </div>
  );
};

export default EmailManager;
