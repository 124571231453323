import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: LocationState = { locations: [] };

export const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    addLocations: (state, action: PayloadAction<Location[]>) => {
      state.locations = action.payload;
    },
    addLocation: (state, action: PayloadAction<Location>) => {
      state.locations.push(action.payload);
    },
    updateLocation: (state, action: PayloadAction<Location>) => {
      const locationIndex = state.locations.findIndex(
        location => location.publicId === action.payload.publicId
      );
      state.locations[locationIndex] = action.payload;
    },
    deleteLocation: (state, action: PayloadAction<{ publicId: string }>) => {
      state.locations = state.locations.filter(
        location => location.publicId !== action.payload.publicId
      );
    },
  },
});

export default locationSlice.reducer;
export const { addLocations, addLocation, updateLocation, deleteLocation } =
  locationSlice.actions;
