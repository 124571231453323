import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import Staffs from './Staff/Staffs';
import { ROUTES } from '../../../../constants/NavigationConstants';
import Sites from './Site/Sites';
import Locations from './Locations/Locations';
import Teams from './Teams/Teams';
import Pathways from './Pathways/Pathways';
import Service from './Service/Service';
import Resources from './Resources/Resources';
const clinicManagerTabsRouting = () => {
  return (
    <>
      <Route index element={<Navigate to={ROUTES.ADMIN.STAFFS} replace />} />
      <Route index path={ROUTES.ADMIN.STAFFS} element={<Staffs />} />
      <Route path={ROUTES.ADMIN.SITES} element={<Sites />} />
      <Route path={ROUTES.ADMIN.LOCATIONS} element={<Locations />} />
      <Route path={ROUTES.ADMIN.TEAMS} element={<Teams />} />
      <Route path={ROUTES.ADMIN.PATHWAYS} element={<Pathways />} />
      <Route path={ROUTES.ADMIN.SERVICES} element={<Service />} />
      <Route path={ROUTES.ADMIN.RESOURCES} element={<Resources />} />
    </>
  );
};

export default clinicManagerTabsRouting;
