export const STEPPER_COLORS = {
  ACTIVE: '#A580C1',
  FAILURE: '#D21F3C',
};
export const BASIC_COLORS = {
  THEME_BLACK: '#110B16',
  THEME_WHITE: '#FCFCFC',
  THEME_GREY: '#808080',
  PRIMARY_500: '#A580C1',
  DESTRUCTIVE_500: '#EF4444',
};
