import React, { ReactNode } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../UI';
export interface dropDownMenuItem {
  label: string | ReactNode;
  Icon: ReactNode;
  action: () => void;
}

interface DropdownType extends React.HTMLAttributes<HTMLDivElement> {
  drownDownItems?: dropDownMenuItem[];
  multipleDropDownSections?: dropDownMenuItem[][];
  side?: 'bottom' | 'top' | 'right' | 'left';
  align?: 'center' | 'end' | 'start';
  alignOffset?: number;
  sideOffset?: number;
}

const ActionDropdown: React.FC<DropdownType> = ({
  drownDownItems,
  multipleDropDownSections,
  className,
  side,
  align,
  alignOffset,
  sideOffset,
  children,
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent
        side={side}
        align={align}
        alignOffset={alignOffset}
        sideOffset={sideOffset}
        className={className}
      >
        {!multipleDropDownSections &&
          drownDownItems &&
          drownDownItems.length > 0 &&
          drownDownItems.map((item, index) => (
            <DropdownMenuItem key={index} onClick={() => item.action()}>
              {item.Icon}
              {item.label}
            </DropdownMenuItem>
          ))}
        {multipleDropDownSections &&
          multipleDropDownSections.map(
            (dropdownItems: dropDownMenuItem[], idx) => (
              <>
                {dropdownItems.map((item, index) => (
                  <DropdownMenuItem key={index} onClick={() => item.action()}>
                    {item.Icon}
                    {item.label}
                  </DropdownMenuItem>
                ))}
                {idx !== multipleDropDownSections.length - 1 && (
                  <div className="h-[1px] w-full bg-neutral-100 my-2" />
                )}
              </>
            )
          )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ActionDropdown;
