/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  Input,
  Slider,
  DialogClose,
} from '../UI';
import { SearchIcon } from '../../assets/icons';
import { searchPatient } from '../../services/api/endPoints/patients';
import { getFullName } from '../../utils/Helper';
import { ROUTES } from '../../constants/NavigationConstants';
import { Link } from 'react-router-dom';
import { Permission } from '../../types/enums';
import { notify } from './Alert';
import { ERROR_TITLE } from '../../constants/NotificationConstants';
interface SearchResult {
  type: string;
  label: string;
  results: PatientLite[];
}

const SearchModal = () => {
  const [search, setSearch] = useState<string>('');
  const [tableData, setTableData] = useState<SearchResult[]>();

  useEffect(() => {
    const handleSearch = async () => {
      await searchPatient(search)
        .then(res => {
          const data: PatientLite[] = [];
          res.data.forEach((row: PatientLite) => {
            data.push({
              ...row,
              fullName: getFullName(row),
              phoneNo: `${row.phoneNo ? row.phoneNo : ''}`,
            });
          });
          setTableData([
            {
              type: 'patient',
              label: 'Patients',
              results: data,
            },
          ]);
        })
        .catch(err => {
          notify.error({
            title: ERROR_TITLE.GENERIC_PATIENT_SEARCH,
            message: err.data,
          });
        });
    };

    if (search.length > 3) {
      handleSearch();
    }
  }, [search]);

  return (
    <div>
      <Dialog>
        <DialogTrigger>
          <Button
            leadingIcon={<SearchIcon />}
            variant={'outlined'}
            className="border-neutral-100 text-neutral-500 w-[120px] md:w-[260px] h-[40px] justify-start"
          >
            <span className="truncate">Search</span>
          </Button>
        </DialogTrigger>
        <DialogContent className="p-0 w-[50%] md:w-[40%] h-[50%] md:max-h-[70%] flex flex-col gap-0">
          <Input
            leadingIcon={<SearchIcon />}
            className="w-[250px] sm:w-full focus:border-neutral-100 rounded-b-none"
            placeholder="Search for patients"
            value={search}
            onChange={e => {
              setSearch(e.target.value);
              // if (e.target.value.length < 3) {
              //   setValidationError('Search must be at least 3 characters long');
              // } else {
              //   setValidationError('');
              // }
            }}
            // onKeyDown={e => {
            //   if (e.key === 'Enter') {
            //     handleSearch();
            //   }
            // }}
            // onBlur={() => {
            //   handleSearch();
            // }}
          />
          {/* Search results based on input provided : UI not given in figma */}
          {/* Suggested Search And Search History can be shown here */}
          {tableData && <SearchResult data={tableData} />}
        </DialogContent>
      </Dialog>
    </div>
  );
};

const SearchResult: React.FC<{ data: SearchResult[] }> = ({ data }) => {
  // Search Results Components based on differnend search type
  // it will will help in future to add more details in each search type
  const showSearchResults = (type: string, results: PatientLite[]) => {
    switch (type) {
      // case 'staff':
      //   return results.map(({ id, name, email }) => (
      //     <StaffSearchedItem key={id} staffName={name} email={email} />
      //   ));
      // case 'service':
      //   return results.map(({ id, name }) => (
      //     <ServiceSearchedItem key={id} serviceName={name} />
      //   ));

      case 'patient':
        return results.map(
          ({ publicId, fullName, email, patientPermission }) => (
            <PatientSearchedItem
              key={publicId}
              patientName={fullName || ''}
              email={email}
              publicId={publicId}
              patientPermission={patientPermission}
            />
          )
        );
    }
  };
  return (
    <Slider variant={'vertical'} className="flex-1">
      {data.map(({ type, label, results }, index) => (
        <>
          <div key={index} className="p-[12px]">
            <div className="w-full flex justify-between">
              <h1 className="font-semibold text-[12px] text-neutral-500">
                {/* {label} */}
              </h1>
              <p className="font-semibold text-[12px] text-neutral-500 cursor-pointer">
                {/* see all */}
              </p>
            </div>
            <div className="space-y-2">{showSearchResults(type, results)}</div>
          </div>
          <hr />
        </>
      ))}
    </Slider>
  );
};

const StaffSearchedItem = ({
  staffName,
  email,
}: {
  staffName: string;
  email: string;
}) => (
  <li className="flex justyfy-start gap-2 cursor-pointer">
    <p className="text-[14px] font-medium text-neutral-900">{staffName}</p>
    <p className="text-[14px] font-medium text-neutral-500">{email}</p>
  </li>
);

const ServiceSearchedItem = ({ serviceName }: { serviceName: string }) => (
  <li className="flex justyfy-start gap-2 cursor-pointer">
    <p className="text-[14px] font-medium text-neutral-900">{serviceName}</p>
  </li>
);
const PatientSearchedItem = ({
  patientName,
  email,
  publicId,
  patientPermission,
}: {
  patientName: string;
  email: string;
  publicId: string;
  patientPermission: any;
}) => {
  // return (
  //   <li className="flex justyfy-start gap-2 cursor-pointer">
  //     <Link
  //       to={`${ROUTES.ROUTE_TO_PATIENT_PROFILE}${publicId}`}
  //       state={{ from: window.location.pathname }}
  //     >
  //       <DialogClose>
  //         <p className="text-[14px] font-medium text-neutral-900">
  //           {patientName}
  //         </p>
  //       </DialogClose>
  //     </Link>
  //     <p className="text-[14px] font-medium text-neutral-500">{email}</p>
  //   </li>
  // );
  if (
    patientPermission?.detailsPermission &&
    Number(Permission[patientPermission?.detailsPermission]) > Permission.none
  ) {
    return (
      <li className="flex justify-start gap-2 cursor-pointer">
        <Link
          to={`${ROUTES.ROUTE_TO_PATIENT_PROFILE}${publicId}`}
          state={{ from: window.location.pathname }}
        >
          <DialogClose>
            <p className="text-[14px] font-medium text-neutral-900">
              {patientName}
            </p>
          </DialogClose>
        </Link>
        <p className="text-[14px] font-medium text-neutral-500">{email}</p>
      </li>
    );
  } else {
    return (
      <li className="flex justify-start gap-2 cursor-pointer">
        <p className="text-[14px] font-medium text-neutral-500">
          {patientName}
        </p>
        <p className="text-[14px] font-medium text-neutral-200">{email}</p>
      </li>
    );
  }
};

export default SearchModal;
