import React, { useState } from 'react';
import { CrossIcon } from '../../../assets/icons';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Slider,
} from '../../UI';
import { DatePicker, notify } from '../../../components/common';
import DebouncedSearchInput from '../../../components/UI/DebouncedSearchInput';
import { searchPatient } from '../../../services/api/endPoints/patients';
import { getFullName } from '../../../utils/Helper';
import AutoComplete from '../../../components/UI/AutoComplete';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { ERROR_TITLE } from '../../../constants/NotificationConstants';

const BookResourceModal: React.FC<Modal> = ({ open, onClose }) => {
  //eslint-disable-next-line
  const [date, setDate] = useState<Date | undefined>(new Date());
  //eslint-disable-next-line
  const [selectedPatient, setSelectedPatient] = useState<
    PatientInformation | PatientLite | null
  >(null);

  const staffs = useSelector<RootState, StaffInformation[]>(
    state => state.staffs.staffs
  );
  const user = useSelector<RootState, UserEntities>(state => state.user.user);
  const [selectedStaff, setSelectedStaff] = useState<StaffInformation>(
    user.staff
  );
  const [selectedService, setSelectedService] = useState<Option | null>(null);

  const services = useSelector<RootState, Service[]>(
    state => state.services.services
  );
  const [selectedLocation, setSelectedLocation] = useState<Option | null>(null);
  const locations = useSelector<RootState, Location[]>(
    state => state.locations.locations
  );

  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      setDate(newDate);
    }
  };

  const onSearch = async (search: string): Promise<PatientLite[]> => {
    try {
      const res = await searchPatient(search);
      const data: PatientLite[] = res.data.map((row: PatientLite) => ({
        ...row,
        fullName: getFullName(row),
        phoneNo: `${row.phoneNo ? row.phoneNo : ''}`,
      }));
      return data;
    } catch (err) {
      notify.error({
        title: ERROR_TITLE.GENERIC_PATIENT_SEARCH,
      });
      return [];
    }
  };

  const mapToOption = <T extends { publicId: string; name: string }>(
    item: T | null
  ): Option | null => {
    if (!item) {
      return null;
    }

    return {
      label: item.name,
      value: item.publicId,
    };
  };

  const resourceOptions = [
    { label: 'Resource 1', value: 'Resource1' },
    { label: 'Resource 2', value: 'Resource2' },
    { label: 'Resource 3', value: 'Resource3' },
    { label: 'Resource 4', value: 'Resource4' },
  ];
  return (
    <div>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0  tablet:w-[40rem] md:w-[40rem] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-semibold">
              Book a resource
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Slider className="flex-1" variant={'vertical'}>
            <div className="p-[1rem]">
              <div>
                <DebouncedSearchInput
                  label={'Patient'}
                  onSearch={onSearch}
                  onSelect={option =>
                    setSelectedPatient(option as PatientInformation)
                  }
                />
              </div>
              <div className="pt-2">
                <Label className="pb-1">Service</Label>
                <AutoComplete
                  setSelectedValue={values =>
                    setSelectedService(
                      values.length > 0 ? (values[0] as Option) : null
                    )
                  }
                  selectedValue={selectedService ? [selectedService] : []}
                  options={services
                    .map(mapToOption)
                    .filter((o): o is Option => o !== null)}
                  labelField="label"
                  valueField="value"
                  placeholder="Select Services"
                />
              </div>
              <div className="pt-2">
                <Label className="pb-1">Consultant</Label>
                <AutoComplete
                  setSelectedValue={values => setSelectedStaff(values[0] || {})}
                  selectedValue={[selectedStaff]}
                  options={staffs}
                  labelField={'fullName'}
                  valueField={'publicId'}
                  placeholder={'Select Staff'}
                />
              </div>
              <div className="pt-2">
                <Label className="pb-1">Location</Label>
                <AutoComplete
                  setSelectedValue={values =>
                    setSelectedLocation(
                      values.length > 0 ? (values[0] as Option) : null
                    )
                  }
                  selectedValue={selectedLocation ? [selectedLocation] : []}
                  options={locations
                    .map(mapToOption)
                    .filter((o): o is Option => o !== null)}
                  labelField="label"
                  valueField="value"
                  placeholder="Select Location"
                />
              </div>
              <div className="mt-2">
                <Label>Resource</Label>
                <AutoComplete
                  setSelectedValue={values => console.log(values)}
                  selectedValue={[]}
                  options={resourceOptions}
                  labelField="label"
                  valueField="value"
                  placeholder="Select Resource"
                />
              </div>
              <div className="pt-2 pb-2 flex flex-col gap-2">
                <div>
                  <Label>Start Date & Time</Label>
                  <DatePicker
                    disableAfterDate={new Date()}
                    variant="dateTime"
                    handleDateChange={handleDateChange}
                  />
                </div>
                <div>
                  <Label>End Date & Time</Label>
                  <DatePicker
                    variant="dateTime"
                    handleDateChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
          </Slider>
          <DialogFooter>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              size={'small'}
              onClick={onClose}
              className="w-full"
              variant={'primary'}
            >
              Book
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BookResourceModal;
