import React, { useRef } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface QuillEditorProps extends ReactQuillProps {
  value: string | '';
  onChange: (value: string) => void;
  height?: string | number;
  enableImageUpload?: boolean;
}

const TextEditor: React.FC<QuillEditorProps> = ({
  value,
  onChange,
  height,
  enableImageUpload = true,
  ...props
}) => {
  const quillRef = useRef<ReactQuill>(null);

  const handleChange = (content: string) => {
    onChange(content);
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ...(enableImageUpload ? [['image']] : []),
      ['clean'],
    ],
  };

  const editorStyle = {
    height: height || '300px',
  };

  return (
    <ReactQuill
      ref={quillRef}
      value={value}
      onChange={handleChange}
      modules={modules}
      style={editorStyle}
      {...props}
    />
  );
};

export default TextEditor;
