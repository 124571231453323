import React, { useState } from 'react';
import { Button, Slider } from '../../UI';
import { DirectedArrowIcon } from '../../../assets/icons';
import { getHumanReadableTime } from '../../../utils/date.utl';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { ActivityFeedType } from '../../../types/enums';
const ActivityFeed = () => {
  const [activityType, setActivityType] = useState<
    ActivityFeedType.DAILY | ActivityFeedType.PRIORITY
  >(ActivityFeedType.DAILY);
  const notifications = useSelector<
    RootState,
    Record<string, Record<string, string>[]>
  >(state => state.notifications.notifications);
  return (
    <div className="sm:h-full md:h-full lg:h-full xl:custom-height rounded-md border border-neutral-100 flex flex-col">
      <div className="flex justify-between items-center flex-none">
        <h2 className="px-[12px] py-[14px] font-semibold text-[16px] text-neutral-900">
          Activity Feed
        </h2>
        <div className="flex  pr-2">
          <Button
            variant="link"
            className={
              'px-[16px] border rounded-l-md font-medium ' +
              (activityType === ActivityFeedType.DAILY
                ? 'border-neutral-900 text-neutral-900'
                : 'border-neutral-500 border-r-0 text-neutral-500')
            }
            size={'xmall'}
            onClick={() => setActivityType(ActivityFeedType.DAILY)}
          >
            Daily
          </Button>
          <Button
            variant="link"
            className={
              'px-[16px] border-t border-b border-r rounded-r-md font-medium ' +
              (activityType === ActivityFeedType.PRIORITY
                ? 'border-neutral-900 text-neutral-900 border-l'
                : 'border-neutral-500 text-neutral-500')
            }
            size={'xmall'}
            onClick={() => setActivityType(ActivityFeedType.PRIORITY)}
          >
            Priority
          </Button>
        </div>
      </div>
      <Slider variant="vertical" className="flex-auto">
        <ul className="relative h-full">
          {/* Grouping the list */}
          {/* Group header */}
          {Object.keys(notifications).length > 0 ? (
            Array.from(Object.keys(notifications))
              .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
              .map(activityFeedTime => (
                <>
                  <li
                    key={activityFeedTime}
                    className="bg-neutral-50 p-[12px] font-semibold text-neutral-900 border-b border-t border-neutral-100 sticky top-0 z-[9999]"
                  >
                    {getHumanReadableTime(
                      Number(notifications[activityFeedTime][0].time)
                    )}
                  </li>
                  {/* Each group will have multiple list items  */}
                  <li>
                    <ul>
                      {notifications[activityFeedTime]?.map(feedData => (
                        <FeedItem
                          key={feedData.id}
                          feedTitle={feedData.title}
                          feedContent={feedData.message}
                          time={feedData.time}
                        />
                      ))}
                    </ul>
                  </li>
                </>
              ))
          ) : (
            <p className="flex justify-center items-center h-full border-t">
              No notifications
            </p>
          )}
        </ul>
      </Slider>
    </div>
  );
};

const FeedItem: React.FC<{
  feedTitle: string;
  feedContent: string;
  time: string;
}> = ({ feedContent, feedTitle, time }) => (
  <li className="px-[12px] py-[12px] border-b border-neutral-100 text-[14px] hover:bg-primary-50 transition-all delay-150">
    <div className="w-full flex justify-between items-start">
      <p className="font-semibold text-neutral-900">{feedTitle}</p>
      <p className="text-neutral-600">{getHumanReadableTime(Number(time))}</p>
    </div>
    <p className="text-neutral-600">{feedContent}</p>
    <Button
      variant={'link'}
      size={'xmall'}
      trailingIcon={
        <span className="rotate-90">
          <DirectedArrowIcon fill="#A580C1" />
        </span>
      }
      className="text-primary-500 w-[78px] p-0 justify-start"
    >
      Action
    </Button>
  </li>
);

export default ActivityFeed;
