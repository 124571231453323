import React, { useState } from 'react';
import {
  CrossIcon,
  PersonIcon,
  LocationIcon,
  ClockIcon,
  ConsultantIcon,
  InvoiceIcon,
  MedicalNoteIcon,
  OpenIcon,
  DeleteIcon,
} from '../../../assets/icons';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
} from '../../UI';
import ReallocateResourceModal from './ReallocateResourceModal';
import { formatDateTime } from '../../../utils/date.utl';
import { BASIC_COLORS } from '../../../constants/ColorConstants';
const CalenderEventModal = ({
  event,
  open,
  onClose,
}: {
  event: any;
  open: boolean;
  onClose: () => void;
}) => {
  const [openAddPatientModal, setOpenPatientModal] = useState<boolean>(false);

  return (
    <div>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0  tablet:w-[25rem] md:w-[25rem] flex flex-col gap-0 border border-neutral-100 max-h-[80%]">
          <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
            <div>
              <h1 className="text-[14px] text-neutral-900 font-semibold">
                {event?.title}
              </h1>
              <h1 className="text-[14px] text-neutral-900 font-sm">
                {event?.start &&
                  event?.end &&
                  formatDateTime(event.start, event.end)}
              </h1>
            </div>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <div className="p-[1rem]">
            <div className="flex flex-row gap-[0.75rem] items-center">
              <PersonIcon stroke={BASIC_COLORS.THEME_GREY} />
              <h1 className="text-[14px] text-neutral-900 font-sm">
                {event?.patient}
              </h1>
            </div>
            <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
              <ConsultantIcon stroke={BASIC_COLORS.THEME_GREY} />
              <h1 className="text-[14px] text-neutral-900 font-sm">
                {event?.resource}
              </h1>
            </div>
            <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
              <LocationIcon />
              <h1 className="text-[14px] text-neutral-900 font-sm">
                {event?.location}
              </h1>
            </div>
            <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
              <ClockIcon />
              <h1 className="text-[14px] text-neutral-900 font-sm">30 min</h1>
            </div>
            <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
              <InvoiceIcon stroke={BASIC_COLORS.THEME_GREY} />
              <div className="border border-success-500 bg-success-50 px-2 py-1 rounded flex items-center gap-[0.25rem]">
                <div className="h-2 w-2 rounded-full bg-green-500"></div>
                <h1 className="text-[14px] text-neutral-600 font-sm">Paid</h1>
              </div>
            </div>
            <div className="flex flex-row gap-[0.75rem] items-center mt-[0.5rem]">
              <MedicalNoteIcon stroke={BASIC_COLORS.THEME_GREY} />
              <div className="flex flex-row items-center gap-[0.5rem] cursor-pointer">
                <h1 className="text-[14px] text-neutral-900 font-sm">
                  View Details
                </h1>
                <OpenIcon />
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button
              size={'small'}
              onClick={() => setOpenPatientModal(true)}
              className="w-full"
              variant={'secondary'}
            >
              Reallocate Resource
              <div className="mt-[0.15rem]">
                <ClockIcon stroke={BASIC_COLORS.PRIMARY_500} />
              </div>
            </Button>
            <Button
              variant={'link'}
              size={'small'}
              className="w-full flex items-center justify-center text-destructive-500"
              onClick={onClose}
            >
              <h1>Cancel Booking</h1>
              <div className="mt-[0.15rem]">
                <DeleteIcon stroke={BASIC_COLORS.DESTRUCTIVE_500} />
              </div>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <ReallocateResourceModal
        open={openAddPatientModal}
        onClose={() => setOpenPatientModal(false)}
      />
    </div>
  );
};

export default CalenderEventModal;
