import React from 'react';
import { Route } from 'react-router-dom';
import ScannedPreview from './ScannedPreview';
import Scanner from './Scanner';
import { ROUTES } from '../../../constants/NavigationConstants';
const scannerRoutes = () => {
  return (
    <>
      <Route index element={<Scanner />} />
      <Route
        path={ROUTES.ROUTE_TO_SCANNER.PREVIEW}
        element={<ScannedPreview />}
      />
    </>
  );
};

export default scannerRoutes;
