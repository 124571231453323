import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ServicesState = { services: [] };

export const serviceSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    addServices: (state, action: PayloadAction<Service[]>) => {
      state.services = action.payload;
    },
    deleteService: (state, action: PayloadAction<{ publicId: string }>) => {
      state.services = state.services.filter(
        staff => staff.publicId !== action.payload.publicId
      );
    },
    addService: (state, action: PayloadAction<Service>) => {
      state.services.push(action.payload);
    },
    updateService: (state, action: PayloadAction<Service>) => {
      const serviceIndex = state.services.findIndex(
        staff => staff.publicId === action.payload.publicId
      );
      state.services[serviceIndex] = action.payload;
    },
  },
});

export default serviceSlice.reducer;
export const { addServices, deleteService, addService, updateService } =
  serviceSlice.actions;
