import React, { useEffect, useState } from 'react';
import LoginImg from '../assets/images/LoginImg.png';
import Logo from '../assets/icons/Logo';
import {
  TwoFactoAuthQRForm,
  ValidateTwoFactorAuth,
} from '../components/sections';
import { PAGE_TITLE } from '../constants/TitleConstants';

const TwoFactorAuth = () => {
  const [step, setStep] = useState<number>(1);

  useEffect(() => {
    document.title = PAGE_TITLE.SETUP_2FA;
  }, []);

  return (
    <main className="flex min-h-screen">
      <section className="flex justify-center items-center tablet:w-full md:w-1/2">
        <div className="h-[max-content] flex flex-col justify-center w-[55%] md:w-[65%]  gap-y-8 items-center md:items-start border border-neutral-100 rounded-[8px] p-[24px] md:p-0 md:border-0 bg-primary-gradient-login md:bg-none">
          <Logo />
          <div>
            <h2 className="text-4xl font-medium text-neutral-900">
              Set-up Two Factor Auth
            </h2>
            <p className="mt-3 text-sm text-neutral-500 tablet:text-center md:text-left">
              Set-up two factor authentication
            </p>
          </div>
          {step === 1 ? (
            <TwoFactoAuthQRForm setNextStep={setStep} />
          ) : (
            <ValidateTwoFactorAuth />
          )}
        </div>
      </section>
      <img
        src={LoginImg}
        alt="loginImg"
        className="tablet:hidden md:block md:w-1/2"
      />
    </main>
  );
};

export default TwoFactorAuth;
