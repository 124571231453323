/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from '../../UI';
import {
  Edit2Icon,
  RedoIcon,
  RightArrow,
  LeftArrowIcon,
  AddIcon,
} from '../../../assets/icons';
import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import CreateFolderModal from '../Patient/PatientInfoTabs/Documents/CreateFolderModal';
import { useScanner } from './ScannerContextProvider';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { notify } from '../../common/Alert';
import { createDocument } from '../../../services/api';

function getImageDimensions(
  base64String: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = base64String;
  });
}

const ScannedPreview = () => {
  const { onEditModification, onRetakeModification, images, resetContext } =
    useScanner();
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageIndex, setImageIndex] = useState<number>(
    Number(localStorage.getItem('currentPreviewIndex')) | 0
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [uploadedFileName, uploadFileToStorage] = useFileUpload();

  useEffect(() => {
    if (images.length === 0) {
      navigate(-1);
    }
  });
  const nextImage = () => {
    if (imageIndex == images.length - 1) return;
    setImageIndex(prevIndex => prevIndex + 1);
  };
  // Prev image display
  const prevImage = () => {
    if (imageIndex == 0) return;
    setImageIndex(prevIndex => prevIndex - 1);
  };

  const generatePDF = async (name: string) => {
    setLoading(true);
    const doc = new jsPDF();
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      if (i !== 0) {
        doc.addPage();
      }
      const dimension = await getImageDimensions(image.croppedImg);
      let imgWidth = dimension.width; // Width of the image
      let imgHeight = dimension.height; // Height of the image
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const padding = 0;
      if (imgWidth >= pageWidth || imgHeight >= pageHeight) {
        const ratio = imgWidth / imgHeight;
        if (imgWidth >= pageWidth) {
          imgWidth = pageWidth - padding;
          imgHeight = imgWidth / ratio;
        }

        if (imgHeight >= pageHeight) {
          imgHeight = pageHeight - padding;
          imgWidth = imgHeight * ratio;
        }
      }

      const x = (pageWidth - imgWidth) / 2; // Calculate horizontal position
      const y = (pageHeight - imgHeight) / 2; // Calculate vertical position

      doc.addImage(
        image.croppedImg,
        'JPEG',
        Math.max(x, padding),
        Math.max(y, padding),
        imgWidth,
        imgHeight
      );
    }

    //check if name has pdf extension, otherwise add it. Remove any other extension
    if (!name.toLowerCase().endsWith('.pdf')) {
      name = name.replace(/\.pdf/g, '');
      name = name + '.pdf';
    }

    const pdfFile = new File([doc.output('blob')], name);

    if (location.state && location.state.activeFolder) {
      await uploadFile(pdfFile, location.state.activeFolder);
    } else {
      await uploadFile(pdfFile, {
        name: 'All Files',
        id: 'root',
        type: 'Folder',
        modifiedAt: new Date(),
        createdAt: new Date(),
      });
    }

    localStorage.removeItem('currentPreviewIndex');
    setLoading(false);
    setOpenCreate(false);
    navigate(-1);
  };

  const uploadFile = async (file: File, activeFolder: DocumentUIType) => {
    if (!file) return;
    const [response, error] = await uploadFileToStorage(
      file,
      `${id}-documents`
    );
    if (error) {
      notify.error({
        message: error,
        title: 'Failed to upload document',
      });
      return;
    }
    const url = response;
    if (url && id) {
      const docCreateReq: DocumentCreateRequest = {
        name: file.name,
        type: 'Document',
        url: url,
        parentPublicId: activeFolder.id,
        patientPublicId: id,
      };

      const [res, err] = await createDocument(docCreateReq);
      if (err) {
        notify.error({
          message: err.data,
          title: 'Failed to upload document',
        });
      } else if (res) {
        notify.success({
          title: 'Document uploaded successfully',
          message: `New document '${file.name}' has been uploaded`,
        });
      }
    }
  };

  const onEdit = () => {
    localStorage.setItem('currentPreviewIndex', JSON.stringify(imageIndex));
    onEditModification(imageIndex);
    navigate(-1);
  };

  const onRetake = () => {
    localStorage.setItem('currentPreviewIndex', JSON.stringify(imageIndex));
    onRetakeModification(imageIndex);
    navigate(-1);
  };
  return (
    <>
      <CreateFolderModal
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        title="Create Document"
        placeHolder="File Name"
        loading={loading}
        onSave={generatePDF}
      />
      <div className="p-3 custom-height">
        <div className="flex flex-col h-full border rounded border-neutral-100">
          <div className=" relative flex justify-center items-center p-3 border-b border-b-neutral-100">
            <h1 className="text-[14px] text-neutral-900 font-medium">
              Preview All Documents
            </h1>
            <Button
              className="absolute left-3 top-2"
              leadingIcon={<LeftArrowIcon />}
              variant={'outlined'}
              size={'xmall'}
              onClick={() => {
                localStorage.removeItem('currentPreviewIndex');
                resetContext();
                navigate(-1);
              }}
            >
              Back
            </Button>
          </div>
          <div className="flex-1 flex flex-col items-center justify-center gap-2 p-[20px]">
            {images.length > 0 && (
              <div className="relative">
                <img
                  style={{
                    minWidth: '400px',
                    minHeight: '200px',
                    maxHeight: '800px',
                    maxWidth: '100%',
                    objectFit: 'contain',
                    // aspectRatio: '16/9'
                    margin: '0 auto',
                  }}
                  src={images[imageIndex]?.croppedImg || ''}
                />
                <button
                  onClick={prevImage}
                  className="rounded-full flex justify-center items-center p-0 w-[34px] h-[34px] absolute top-[50%] left-4 border border-neutral-100 bg-white"
                >
                  <LeftArrowIcon width="20" height="20" stroke="black" />
                </button>
                <button
                  onClick={nextImage}
                  className="rounded-full flex justify-center items-center p-0 w-[34px] h-[34px] absolute top-[50%] right-4 border border-neutral-100 bg-white"
                >
                  <RightArrow width="30" height="30" stroke="black" />
                </button>
                <div className="absolute bottom-5 left-[50%] right-[50%] translate-x-[-50%] bg-slate-50 py-3 px-5 flex items-center justify-center font-medium text-[14px] rounded-3xl min-w-[70px]">
                  <p>
                    {imageIndex + 1}/{images.length}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="w-full flex justify-between p-3 border-t border-neutral-100">
            <div className="flex gap-2">
              <Button
                variant={'outlined'}
                size={'small'}
                leadingIcon={<Edit2Icon />}
                onClick={onEdit}
              >
                Edit
              </Button>
              <Button
                size={'small'}
                variant={'outlined'}
                leadingIcon={<RedoIcon />}
                onClick={onRetake}
              >
                Retake
              </Button>
            </div>
            <div className="flex gap-4">
              <Button
                onClick={() => navigate(-1)}
                size={'small'}
                variant={'outlined'}
                trailingIcon={<AddIcon stroke="black" />}
              >
                Add More
              </Button>
              <Button
                onClick={() => setOpenCreate(true)}
                size={'small'}
                trailingIcon={<RightArrow />}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScannedPreview;
