import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SupplementsState = { supplements: [] };

export const supplementsSlice = createSlice({
  name: 'tests',
  initialState,
  reducers: {
    addSupplements: (state, action: PayloadAction<Supplement[]>) => {
      state.supplements = action.payload;
    },
  },
});

export default supplementsSlice.reducer;
export const { addSupplements } = supplementsSlice.actions;
