import { STEPPER_COLORS } from '../../constants/ColorConstants';
import React, { FC } from 'react';

interface ActiveStepProps {
  width?: number;
  height?: number;
  stroke?: string;
}

const ActiveStep: FC<ActiveStepProps> = ({
  width = 36,
  height = 37,
  stroke = STEPPER_COLORS.ACTIVE,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 41"
      fill="none"
    >
      <g filter="url(#filter0_d_2077_22154)">
        <rect x="2" y="2.5" width="36" height="36" rx="18" fill="white" />
        <rect
          x="3"
          y="3.5"
          width="34"
          height="34"
          rx="17"
          stroke={stroke}
          strokeWidth="2"
        />
        <circle cx="20" cy="20.5" r="6" fill={stroke} />
      </g>
      <defs>
        <filter
          id="filter0_d_2077_22154"
          x="0"
          y="0.5"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          {/* <feMorphology
            radius="2"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_2077_22154"
          /> */}
          <feOffset />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.89518 0 0 0 0 0.756667 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2077_22154"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2077_22154"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ActiveStep;
