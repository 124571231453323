import React from 'react';
type Props = {
  twopath_url: string;
};

const QRCodeGenerator = ({ twopath_url }: Props) => {
  return (
    <div className="flex justify-center md:justify-start w-full">
      <img
        className="block w-[134.545px] h-[134.545px] object-contain"
        src={twopath_url}
        alt="Two Factor Auth QR"
      />
    </div>
  );
};

export default QRCodeGenerator;
