import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ProgressNotes = {
  data: [],
  loading: false,
  error: null,
  needsUpdate: true,
};

const progressNotesSlice = createSlice({
  name: 'progressNotes',
  initialState,
  reducers: {
    setProgressNotes: (state, action: PayloadAction<unknown[]>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    needsUpdate: (state, action: PayloadAction<boolean>) => {
      state.needsUpdate = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { setProgressNotes, setLoading, setError, needsUpdate } =
  progressNotesSlice.actions;

export default progressNotesSlice.reducer;
