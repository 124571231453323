import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  Slider,
} from '../../../../UI';
import { CrossIcon } from '../../../../../assets/icons';

const ViewService = ({ open, onClose, service }: ViewServiceModalProps) => {
  const [displayData, setDisplayData] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);
  useEffect(() => {
    const data = [
      { label: 'Name', value: service.name },
      { label: 'Service Type', value: service.type },
      { label: 'Pathway', value: service.pathway.name },
    ];
    setDisplayData(data);
  }, []);
  const renderData = (label: string, value: string) => {
    return (
      <>
        <div className="cols-span-1 ">
          <p className="text-[14px] font-semibold text-neutral-500">{label}</p>
        </div>
        <div className="cols-span-1">
          <p className="text-[14px] font-semibold text-neutral-900">{value}</p>
        </div>
      </>
    );
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0   w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            Service details
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="grid grid-cols-2  p-4  gap-2">
            {displayData &&
              displayData.map(data => renderData(data.label, data.value))}
          </div>
        </Slider>
      </DialogContent>
    </Dialog>
  );
};

export default ViewService;
