/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import ActionBar from './ActionBar';
import Analytics from './Analytics';
import Staff from './Staff';
import Service from './Service';
import ActivityFeed from './ActivityFeed';
const AdminView = () => {
  return (
    <div>
      <ActionBar />
      {/* <Analytics role="admin" /> */}
      <div className="mt-5 grid grid-cols-5 gap-3">
        <div className="col-span-3 space-y-5">
          <Staff />
          <Service />
        </div>
        {/* <div className="col-span-2">
          <ActivityFeed />
        </div> */}
      </div>
    </div>
  );
};

export default AdminView;
