import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SitesState = { sites: [] };

export const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    addSites: (state, action: PayloadAction<Site[]>) => {
      state.sites = action.payload;
    },
    deleteSite: (state, action: PayloadAction<{ publicId: string }>) => {
      state.sites = state.sites.filter(
        site => site.publicId !== action.payload.publicId
      );
    },
    addSite: (state, action: PayloadAction<Site>) => {
      state.sites.push(action.payload);
    },
    updateSite: (state, action: PayloadAction<Site>) => {
      const siteIndex = state.sites.findIndex(
        site => site.publicId === action.payload.publicId
      );
      state.sites[siteIndex] = action.payload;
    },
  },
});

export default sitesSlice.reducer;
export const { addSites, deleteSite, addSite, updateSite } = sitesSlice.actions;
