import React from 'react';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoperClose,
  Checkbox,
} from '../../UI';
import { ListFilter } from 'lucide-react';
import { DownArrow } from '../../../assets/icons';

interface FilterProps {
  returnFunction: () => void;
}

const ResourceBookingFilter: React.FC<FilterProps> = React.memo(
  ({ returnFunction }) => {
    const applyFilters = () => {
      returnFunction();
    };
    const [locationDropdown, setLocationDropdown] = React.useState(false);
    const [resourceDropdown, setResourceDropdown] = React.useState(false);
    const [consultantDropdown, setConsultantDropdown] = React.useState(false);
    const [serviceDropdown, setServiceDropdown] = React.useState(false);
    const locations = ['Location 1', 'Location 2', 'Location 3', 'Location 4'];
    const resource = ['Resource 1', 'Resource 2', 'Resource 3', 'Resource 4'];
    const consultant = [
      'Consultant 1',
      'Consultant 2',
      'Consultant 3',
      'Consultant 4',
    ];
    const service = ['Service 1', 'Service 2', 'Service 3', 'Service 4'];
    return (
      <Popover>
        <PopoverTrigger asChild>
          <div>
            <Button
              variant={'link'}
              size={'xmall'}
              leadingIcon={<ListFilter />}
            >
              <span className="sr-only">Open menu</span>
              Filter
            </Button>
          </div>
        </PopoverTrigger>
        <PopoverContent
          align="center"
          className="min-w-[310px] bg-shades-0 space-y-3"
        >
          <div>
            <div className="flex flex-row items-center justify-between px-5 py-3 border-b border-neutral-100">
              <h1 className="text-sm not-italic font-medium leading-[120%]">
                Location
              </h1>
              <div
                className="cursor-pointer"
                onClick={() => setLocationDropdown(!locationDropdown)}
              >
                {locationDropdown ? (
                  <div className="transform rotate-180">
                    <DownArrow height={'20'} width={'20'} />
                  </div>
                ) : (
                  <div>
                    <DownArrow height={'20'} width={'20'} />
                  </div>
                )}
              </div>
            </div>
            {locationDropdown && (
              <div className="px-5 py-3 border-b border-neutral-100 flex flex-col gap-2">
                <div className="flex flex-row items-center justify-between ">
                  <h1 className="text-sm not-italic font-medium leading-[120%] text-primary-600">
                    Select all Location
                  </h1>
                  <div>
                    <Checkbox />
                  </div>
                </div>
                {locations.map((location, index) => (
                  <div
                    key={index}
                    className="flex flex-row items-center justify-between "
                  >
                    <h1 className="text-sm not-italic font-medium leading-[120%]">
                      {location}
                    </h1>
                    <div>
                      <Checkbox />
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="flex flex-row items-center justify-between px-5 py-3 border-b border-neutral-100">
              <h1 className="text-sm not-italic font-medium leading-[120%]">
                Resource
              </h1>
              <div
                className="cursor-pointer"
                onClick={() => setResourceDropdown(!resourceDropdown)}
              >
                {resourceDropdown ? (
                  <div className="transform rotate-180">
                    <DownArrow height={'20'} width={'20'} />
                  </div>
                ) : (
                  <div>
                    <DownArrow height={'20'} width={'20'} />
                  </div>
                )}
              </div>
            </div>
            {resourceDropdown && (
              <div className="px-5 py-3 border-b border-neutral-100 flex flex-col gap-2">
                <div className="flex flex-row items-center justify-between ">
                  <h1 className="text-sm not-italic font-medium leading-[120%] text-primary-600">
                    Select all Resources
                  </h1>
                  <div>
                    <Checkbox />
                  </div>
                </div>
                {resource.map((location, index) => (
                  <div
                    key={index}
                    className="flex flex-row items-center justify-between "
                  >
                    <h1 className="text-sm not-italic font-medium leading-[120%]">
                      {location}
                    </h1>
                    <div>
                      <Checkbox />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="flex flex-row items-center justify-between px-5 py-3 border-b border-neutral-100">
              <h1 className="text-sm not-italic font-medium leading-[120%]">
                Consultant
              </h1>
              <div
                className="cursor-pointer"
                onClick={() => setConsultantDropdown(!consultantDropdown)}
              >
                {consultantDropdown ? (
                  <div className="transform rotate-180">
                    <DownArrow height={'20'} width={'20'} />
                  </div>
                ) : (
                  <div>
                    <DownArrow height={'20'} width={'20'} />
                  </div>
                )}
              </div>
            </div>
            {consultantDropdown && (
              <div className="px-5 py-3 border-b border-neutral-100 flex flex-col gap-2">
                <div className="flex flex-row items-center justify-between ">
                  <h1 className="text-sm not-italic font-medium leading-[120%] text-primary-600">
                    Select all Consultant
                  </h1>
                  <div>
                    <Checkbox />
                  </div>
                </div>
                {consultant.map((location, index) => (
                  <div
                    key={index}
                    className="flex flex-row items-center justify-between "
                  >
                    <h1 className="text-sm not-italic font-medium leading-[120%]">
                      {location}
                    </h1>
                    <div>
                      <Checkbox />
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="flex flex-row items-center justify-between px-5 py-3 border-b border-neutral-100">
              <h1 className="text-sm not-italic font-medium leading-[120%]">
                Service
              </h1>
              <div
                className="cursor-pointer"
                onClick={() => setServiceDropdown(!serviceDropdown)}
              >
                {serviceDropdown ? (
                  <div className="transform rotate-180">
                    <DownArrow height={'20'} width={'20'} />
                  </div>
                ) : (
                  <div>
                    <DownArrow height={'20'} width={'20'} />
                  </div>
                )}
              </div>
            </div>
            {serviceDropdown && (
              <div className="px-5 py-3 border-b border-neutral-100 flex flex-col gap-2">
                <div className="flex flex-row items-center justify-between ">
                  <h1 className="text-sm not-italic font-medium leading-[120%] text-primary-600">
                    Select all Services
                  </h1>
                  <div>
                    <Checkbox />
                  </div>
                </div>
                {service.map((location, index) => (
                  <div
                    key={index}
                    className="flex flex-row items-center justify-between "
                  >
                    <h1 className="text-sm not-italic font-medium leading-[120%]">
                      {location}
                    </h1>
                    <div>
                      <Checkbox />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* Action Menu Item */}
          <div className="flex gap-3 w-full">
            <PopoperClose className="w-full">
              <Button variant={'link'} size={'large'} className={'h-[30px] '}>
                Close
              </Button>
            </PopoperClose>
            <PopoperClose className="w-full">
              <Button
                variant={'primary'}
                size={'large'}
                className={'h-[30px] '}
                onClick={applyFilters}
              >
                Apply
              </Button>
            </PopoperClose>
          </div>
        </PopoverContent>
      </Popover>
    );
  }
);

export default ResourceBookingFilter;
