import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Toaster } from 'react-hot-toast';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const persister = persistStore(store);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Toaster position="top-right" containerStyle={{ zIndex: 9999999 }} />
      <Provider store={store}>
        <PersistGate persistor={persister}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
