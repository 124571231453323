import { BASIC_COLORS } from '../../constants/ColorConstants';
import React from 'react';

const OpenIcon = ({
  stroke = BASIC_COLORS.THEME_BLACK,
}: {
  stroke?: string;
}) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66602 5.16602H11.3327V11.8327"
        stroke={stroke}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66602 11.8327L11.3327 5.16602"
        stroke={stroke}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default OpenIcon;
