import React, { ReactNode, useState } from 'react';
import { Button, Slider } from '../../UI';
import {
  FilterIcon,
  AddIcon,
  DotMenu,
  DirectedArrowIcon,
  OutlinedAddIcon,
  // EventIcon,
  // NoteIcon,
  // PersonIcon,
} from '../../../assets/icons';
import { ColumnDef } from '@tanstack/react-table';
import { ActionDropdown, DataTable } from '../../common';

const Service = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);
  //    Dummy data : Need to remove after API integertaion
  interface serviceType {
    id: string;
    name: string;
    type: string;
    activePatients: number;
  }
  const service: serviceType = {
    id: '1',
    name: 'Jane Cooper',
    type: 'Consultant',
    activePatients: 20,
  };
  const services: serviceType[] = Array(30)
    .fill(service)
    .map((obj, index) => ({ ...obj, id: index + 1, name: obj.name + index }));

  // Coumns Defn required
  const serviceColumns: ColumnDef<serviceType>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => {
        return (
          <button
            className="flex gap-1 items-center"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Name
            {column.getIsSorted() === 'asc' ? (
              <div className="rotate-180">
                <DirectedArrowIcon fill="#808080" />{' '}
              </div>
            ) : (
              <DirectedArrowIcon fill="#808080" />
            )}
          </button>
        );
      },
    },

    {
      accessorKey: 'type',
      header: 'Type',
    },
    {
      accessorKey: 'activePatients',
      header: 'Active Patients',
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        console.log(row);
        const dropDownMenu: {
          label: string;
          Icon: ReactNode;
          action: () => void;
        }[] = [
          {
            label: 'Test',
            Icon: <AddIcon />,
            action: () => {},
          },
        ];
        return (
          <div className="flex justify-end">
            <ActionDropdown drownDownItems={dropDownMenu}>
              <button className="w-8 h-8 p-0 outline-none border-none">
                <span className="sr-only">Open menu</span>
                <DotMenu />
              </button>
            </ActionDropdown>
          </div>
        );
      },
    },
  ];
  return (
    <div className="w-full rounded-md border border-neutral-100">
      <div className="px-[12px]  py-[16px] w-full flex justify-between items-center ">
        <h2 className="font-semibold text-[16px] text-neutral-900">
          Upcomig Services
        </h2>
        <div className="flex gap-3 items-center justify-center">
          <Button variant={'link'} leadingIcon={<FilterIcon />} size={'xmall'}>
            Filter
          </Button>
          <Button
            variant={'secondary'}
            size={'xmall'}
            trailingIcon={<OutlinedAddIcon stroke="#8D5EB0" />}
          >
            Add Service
          </Button>
        </div>
      </div>
      <Slider variant={'vertical'} className="max-h-[520px]">
        <DataTable
          columns={serviceColumns}
          data={services}
          isLoading={isLoading}
        />
      </Slider>
    </div>
  );
};

export default Service;
