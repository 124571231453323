import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Slider,
  Textarea,
} from '../../../../UI';
import { CrossIcon, DownArrowIcon } from '../../../../../assets/icons';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store/store';
import { createNewResource, updateResource } from '../../../../../services/api';
import { useDispatch } from 'react-redux';
import {
  addResource,
  updateResource as updateResourceRedux,
} from '../../../../../redux/slice';
import { notify } from '../../../../../components/common';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';
const AddAndEditResourceModal = ({
  open,
  onClose,
  ...props
}: AddAndEditResourceModalProps) => {
  const [data, setData] = useState<ResourceFormInputType>({
    name: '',
    description: '',
    locationPublicId: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { locations } = useSelector<RootState, LocationState>(
    state => state.locations
  );
  useEffect(() => {
    if (props.type === 'Edit') {
      const initialData: ResourceFormInputType = {
        name: props.resource.name,
        description: props.resource.description,
        locationPublicId: props.resource.location.publicId,
      };
      setData(initialData);
    }
  }, []);

  const submitHandler = async () => {
    setIsLoading(true);
    if (props.type === 'Create') {
      const newResourceData: CreateNewResourceRequest = {
        ...data,
      };
      const [res, error] = await createNewResource(newResourceData);
      if (res) {
        dispatch(addResource(res));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_RESOURCE_ADD,
          message: `Resource - ${res.name} added successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_RESOURCE_ADD,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    } else if (props.type === 'Edit') {
      const updateResourceData: UpdateResourceRequest = {
        name: data.name,
        description: data.description,
        publicId: props.resource.publicId,
      };
      const [res, error] = await updateResource(updateResourceData);
      if (res) {
        dispatch(updateResourceRedux(res));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_RESOURCE_UPDATE,
          message: `Resource - ${props.resource.name} updated successfully`,
        });
      } else {
        console.log(error);
        notify.error({
          title: ERROR_TITLE.GENERIC_RESOURCE_UPDATE,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    }
    setIsLoading(false);
    onClose();
  };
  const inputHandler = (name: keyof ResourceFormInputType, value: string) => {
    setData(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type == 'Create' ? 'Add resource' : 'Edit resource'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-3">
            <div className="col-span-3 space-y-1">
              <Label htmlFor="name">Name</Label>
              <Input
                placeholder="Enter resource name"
                id="name"
                name="name"
                value={data.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  inputHandler('name', e.target.value)
                }
              />
            </div>
            <div className="col-span-3 space-y-1">
              <Label htmlFor="description">Description</Label>
              <Textarea
                placeholder="Enter description"
                id="description"
                name="description"
                value={data.description}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  inputHandler('description', e.target.value)
                }
              />
            </div>

            <div className="space-y-1">
              <Label htmlFor="Location">Location</Label>
              <Select
                required
                name="Location"
                disabled={props.type === 'Edit'}
                value={data.locationPublicId}
                onValueChange={value => inputHandler('locationPublicId', value)}
              >
                <SelectTrigger
                  className="w-full"
                  selectIcon={<DownArrowIcon />}
                >
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent className="bg-shades-0 z-[999999]">
                  {locations.map(location => (
                    <SelectItem
                      key={location.publicId}
                      value={location.publicId}
                    >
                      {location.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            variant={'primary'}
            size={'small'}
            onClick={submitHandler}
            className="w-full"
            disabled={isLoading}
            isLoading={isLoading}
          >
            {props.type == 'Create' ? 'Add resource' : 'Save resource'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddAndEditResourceModal;
