import React from 'react';
import ClinicManagerTabs from '../components/sections/Admin/ClinicManager/ClinicManagerTabs';

const Admin = () => {
  return (
    <div>
      <ClinicManagerTabs />
    </div>
  );
};

export default Admin;
