import { FC } from 'react';

const BoardIcon: FC<{
  stroke?: string;
}> = ({ stroke = '#808080' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M10.0007 1.83398H6.00065C5.63246 1.83398 5.33398 2.13246 5.33398 2.50065V3.83398C5.33398 4.20217 5.63246 4.50065 6.00065 4.50065H10.0007C10.3688 4.50065 10.6673 4.20217 10.6673 3.83398V2.50065C10.6673 2.13246 10.3688 1.83398 10.0007 1.83398Z"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.666 3.16602H11.9993C12.353 3.16602 12.6921 3.30649 12.9422 3.55654C13.1922 3.80659 13.3327 4.14573 13.3327 4.49935V13.8327C13.3327 14.1863 13.1922 14.5254 12.9422 14.7755C12.6921 15.0255 12.353 15.166 11.9993 15.166H3.99935C3.64573 15.166 3.30659 15.0255 3.05654 14.7755C2.80649 14.5254 2.66602 14.1863 2.66602 13.8327V4.49935C2.66602 4.14573 2.80649 3.80659 3.05654 3.55654C3.30659 3.30649 3.64573 3.16602 3.99935 3.16602H5.33268"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7.83398H10.6667"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.166H10.6667"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33398 7.83398H5.34065"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33398 11.166H5.34065"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BoardIcon;
