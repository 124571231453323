export const ROUTES = {
  ROUTE_TO_BASE: '/',
  ROUTE_TO_SIGN_IN: '/signIn',
  ROUTE_TO_UPDATE_PASSWORD: '/signIn/updatePassword',
  ROUTE_TO_SET_2FA: '/signIn/twoFactorAuth',
  ROUTE_TO_CONFIRM_2FA: '/signIn/confirmTwoFactorAuth',
  ROUTE_TO_OVERVIEW: '/overview',
  ROUTE_TO_UNAUTHORIZED: '/unauthorized',
  ROUTE_TO_PATIENT: '/patients',
  ROUTE_TO_SCANNER: {
    SCANNER: 'documents/scanner',
    PREVIEW: 'preview',
  },
  ROUTE_TO_PATIENT_PROFILE: '/patient/',
  ROUTE_TO_RESOURCE_BOOKING: '/resource-booking',
  LABS: {
    LABS_WILDCARD: '/labs',
    BASE: '/labs',
    CREATE: '/labs/create',
    VIEW: '/labs/view-result',
  },
  ADMIN: {
    BASE: '/admin-clinic-manager',
    STAFFS: 'staffs',
    SITES: 'sites',
    LOCATIONS: 'locations',
    TEAMS: 'teams',
    PATHWAYS: 'pathways',
    SERVICES: 'services',
    RESOURCES: 'resources',
  },
  EMAIL_MANAGER: '/email-manager',
};

// export const WENT_WRONG_MSG = "Something went wrong";

export const PATIENT_ROUTES = {
  ADMINISTRATIVE_NOTES: 'administrativeNotes',

  MEDICAL_NOTES: 'medicalNotes',

  ADMIN_FORMS: {
    HOME: 'admin-forms',
    CREATE: 'admin-forms/create',
    VIEW: 'admin-forms/view',
  },

  MEDICAL_REPORTS: {
    HOME: 'medical-reports',
    CREATE: 'medical-reports/create',
    VIEW: 'medical-reports/view',
  },

  HANDWRITTEN_NOTES: {
    HOME: 'handwritten-notes',
    CREATE: 'handwritten-notes/create',
  },

  RECOMMENDED: {
    HOME: 'recommended',
  },

  APPOINTMENT: {
    HOME: 'appointment',
    VIEW: 'appointment/view',
  },

  LABS: {
    HOME: 'labs',
    ADD: 'labs/add-test',
    VIEW: 'labs/view-result',
  },

  PRESCRIPTION: {
    HOME: 'prescription',
    CREATE: 'prescription/create',
    VIEW: 'prescription/view',
  },

  DOCUMENTS: {
    HOME: 'documents',
    VIEW: 'documents/view',
  },

  NOTES: {
    HOME: 'notes',
  },

  // <Route path="medicalNotes/create" element={<CreateMedicalNotes />} />
  // <Route
  //   path="medicalNotes/reportView/:reportId"
  //   element={<MedicalReportView />}
  // />
  // <Route path="recomended" element={<Recomended />} />
  // <Route path="appointment" element={<Appointment />} />
  // <Route
  //   path="appointment/reportview/:reportId"
  //   element={<AppointmentReportView />}
  // />
  // <Route path="labs" element={<Labs />} />
  // <Route path="labs/addTest" element={<LabTestForm />} />
  // <Route path="prescription" element={<Prescriptions />} />
  // <Route
  //   path="prescription/addPrescription"
  //   element={<PrescriptionForm />}
  // />
  // {/* TODO: Need to add id based routing */}
  // <Route path="documents" element={<Documents />} />
  // <Route path="notes" element={<Notes />} />
};
