import React, { ButtonHTMLAttributes } from 'react';
import { ToastOptions, toast } from 'react-hot-toast';
import { cn } from '../../utils';
interface NotificationType extends ButtonHTMLAttributes<HTMLBRElement> {
  title: string;
  message?: string;
  size?: 'large' | 'medium' | 'small';
  opt?: ToastOptions;
}

const notifySuccess = ({ opt, ...props }: NotificationType) => {
  toast.custom(<Success {...props} />, {
    ...opt,
  });
};

const notifyDefault = ({ opt, ...props }: NotificationType) => {
  toast.custom(<Default {...props} />, { ...opt });
};

const notifyError = ({ opt, ...props }: NotificationType) => {
  toast.custom(<Error {...props} />, { ...opt });
};

const notifyPrimary = ({ opt, ...props }: NotificationType) => {
  toast.custom(<Primary {...props} />, { ...opt });
};
const sizeVariant = {
  large: 'py-5 px-4 w-[448px]',
  medium: 'py-3 px-4 w-[446px]',
  small: 'p-3 w-[438px]',
};
const Success: React.FC<Partial<NotificationType>> = props => (
  <div
    className={cn(
      'border border-success-500 bg-success-50 rounded-[8px] flex flex-col gap-',
      props.className,
      sizeVariant[props.size ? props.size : 'medium']
    )}
  >
    <h1 className="text-[14px] font-bold text-success-500">{props.title}</h1>
    <p className="text-[14px] font-normal text-neutral-600">{props.message}</p>
  </div>
);

const Default: React.FC<Partial<NotificationType>> = props => (
  <div
    className={cn(
      'border border-neutral-100 bg-shades-0 rounded-[8px] flex flex-col gap-2',
      props.className,
      sizeVariant[props.size ? props.size : 'medium']
    )}
  >
    <h1 className="text-[14px] font-bold text-neutral-900">{props.title}</h1>
    <p className="text-[14px] font-normal text-neutral-600">{props.message}</p>
  </div>
);

const Error: React.FC<Partial<NotificationType>> = props => (
  <div
    className={cn(
      'border border-destructive-500 bg-destructive-50 rounded-[8px] flex flex-col gap-2',
      props.className,
      sizeVariant[props.size ? props.size : 'medium']
    )}
  >
    <h1 className="text-[14px] font-bold text-destructive-500">
      {props.title}
    </h1>
    <p className="text-[14px] font-normal text-neutral-600">{props.message}</p>
  </div>
);

const Primary: React.FC<Partial<NotificationType>> = props => (
  <div
    className={cn(
      'border border-primary-500 bg-primary-50 rounded-[8px] flex flex-col gap-2',
      props.className,
      sizeVariant[props.size ? props.size : 'medium']
    )}
  >
    <h1 className="text-[14px] font-bold border-primary-500">{props.title}</h1>
    <p className="text-[14px] font-normal text-neutral-600">{props.message}</p>
  </div>
);

export const notify = {
  success: notifySuccess,
  default: notifyDefault,
  primary: notifyPrimary,
  error: notifyError,
};
