import React from 'react';

const DirectedArrowIcon: React.FC<{ fill?: string }> = ({
  fill = '#000000',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21133 2.38053C6.2875 2.30445 6.39076 2.26172 6.49841 2.26172C6.60607 2.26172 6.70933 2.30445 6.7855 2.38053L10.0355 5.63053C10.0754 5.66772 10.1074 5.71257 10.1296 5.7624C10.1518 5.81224 10.1638 5.86603 10.1647 5.92058C10.1657 5.97513 10.1557 6.02931 10.1352 6.07989C10.1148 6.13048 10.0844 6.17643 10.0458 6.21501C10.0072 6.25358 9.96128 6.284 9.9107 6.30443C9.86011 6.32486 9.80593 6.33489 9.75138 6.33393C9.69683 6.33297 9.64304 6.32103 9.59321 6.29883C9.54337 6.27662 9.49852 6.24461 9.46133 6.20469L6.90466 3.64803V11.3343C6.90466 11.442 6.86186 11.5454 6.78568 11.6215C6.70949 11.6977 6.60616 11.7405 6.49841 11.7405C6.39067 11.7405 6.28734 11.6977 6.21115 11.6215C6.13497 11.5454 6.09216 11.442 6.09216 11.3343V3.64803L3.5355 6.20469C3.49831 6.24461 3.45346 6.27662 3.40362 6.29883C3.35379 6.32103 3.3 6.33297 3.24545 6.33393C3.1909 6.33489 3.13672 6.32486 3.08613 6.30443C3.03555 6.284 2.9896 6.25358 2.95102 6.21501C2.91244 6.17643 2.88203 6.13048 2.8616 6.07989C2.84117 6.02931 2.83113 5.97513 2.83209 5.92058C2.83306 5.86603 2.845 5.81224 2.8672 5.7624C2.8894 5.71257 2.92142 5.66772 2.96133 5.63053L6.21133 2.38053Z"
    />
  </svg>
);

export default DirectedArrowIcon;
