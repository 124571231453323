/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
  ClockIcon,
  Dot,
  LocationIcon,
  OutlinedUserIcon,
} from '../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { addDaysToDateTime } from '../../../../utils/date.utl';
import { PATHWAY_DEADLINE_STATUS } from '../../../../constants/constants';
import ArchiveIcon from '../../../../assets/icons/ArchiveIcon';
import { Button } from '../../../../components/UI';
import { archiveEvent } from '../../../../services/api/endPoints/events';
import { notify } from '../../../../components/common';
const StageCard: React.FC<TaskCardProps> = ({
  task,
  onDragStart,
  removeArchivedEvent,
}) => {
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const handlePatientClick = () => {
    navigate(`/patients/information/${task.patient.publicId}`);
  };
  const result = addDaysToDateTime(
    task.whenLastStageUpdated,
    task.currentStage.maxWaitTimeDays
  );

  const handleArchiveEvent = async () => {
    setIsUpdating(true);
    const [response, error] = await archiveEvent({
      publicId: task.eventPublicId,
      archived: true,
    });
    setIsUpdating(false);
    if (response) {
      removeArchivedEvent(task);
      notify.success({
        title: 'Archived successfully.',
      });
    } else {
      notify.error({
        title: 'Failed to archive.',
      });
    }
  };
  return (
    <div
      className="cursor-grab p-4 rounded border space-y-2 border-neutral-100 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.08)] bg-shades-0"
      draggable
      onDragStart={e => onDragStart(e, task)}
    >
      <div className="flex justify-between">
        <p className="font-medium text text-sm space-y-2 text-neutral-900">
          {task.name}
        </p>
        {task.currentStage.finalStage && (
          <Button
            className="border-none pr-0 !important"
            leadingIcon={<ArchiveIcon stroke="#A580C1" />}
            variant={'outlined'}
            size={'xmall'}
            isLoading={isUpdating}
            onClick={handleArchiveEvent}
          ></Button>
        )}
      </div>
      <div className="grid grid-cols-2">
        <p className="flex items-center font-medium text text-sm text-neutral-600">
          <OutlinedUserIcon />{' '}
          <span className="ml-2">{task.staff.fullName}</span>
        </p>
        {task.booking && (
          <p className="flex items-center font-medium text text-sm text-neutral-600">
            <LocationIcon />{' '}
            <span className="ml-2">{task.booking?.location?.name}</span>
          </p>
        )}
      </div>
      <p className="flex items-center gap-2 font-medium text text-sm text-neutral-600">
        <ClockIcon /> {task.startTime} - {task.endTime}
      </p>
      <hr />
      <div className="flex justify-between">
        <p className="items-center font-medium text text-sm text-neutral-600">
          <span className="ml-2">Deadline</span>
        </p>
        <p className="items-center font-medium text text-sm text-neutral-600">
          <span className="ml-2"> {result.newDate}</span>
        </p>
      </div>
      {result.isPast && (
        <p
          className={
            'py-1 px-2 flex items-center gap-1 rounded border border-rose-400 text text-sm w-[fit-content] bg-warning-50'
          }
        >
          <Dot fill={'#EF4444'} />
          <span>{PATHWAY_DEADLINE_STATUS.DEADLINE_CROSSED}</span>
        </p>
      )}
    </div>
  );
};
// color:  ? "red" : "green",
export default StageCard;
