import { useState } from 'react';
import { uploadFile } from '../services/api/endPoints/files.api';
import { generateRandomString } from '../utils/data.helper';

export const useFileUpload = (): [
  string,
  (file: File, containerName: string) => Promise<[string | null, string | null]>
] => {
  const [fileName, setFileName] = useState<string>('');

  const handleFileChange = async (
    file: File,
    containerName: string
  ): Promise<[string | null, string | null]> => {
    const formData = new FormData();
    setFileName(file.name);
    const fileName = generateRandomString(10) + '_' + file.name;
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('containerName', containerName);

    try {
      const [response, error] = await uploadFile(formData);
      return [response, error?.data];
    } catch (error) {
      return [null, 'An error occurred while uploading the file.'];
    }
  };

  return [fileName, handleFileChange];
};
