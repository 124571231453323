import React, { FC } from 'react';

interface ArrowUpProps {
  stroke?: string;
}

const ArrowUpIcon: FC<ArrowUpProps> = ({ stroke = '#0A0A0A' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12 10L8 6L4 10"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowUpIcon;
