import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Button,
  DialogFooter,
  Label,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Slider,
} from '../../../../UI';
import { CrossIcon, DownArrowIcon } from '../../../../../assets/icons';
import {
  getAllSites,
  createNewLocation,
  updateLocation,
} from '../../../../../services/api';
import {
  addLocation,
  updateLocation as updateLocationRedux,
} from '../../../../../redux/slice';
import { notify } from '../../../../../components/common';
import { useDispatch } from 'react-redux';
import {
  ERROR_TITLE,
  SUCCESS_TITLE,
} from '../../../../../constants/NotificationConstants';

const AddAndEditLocationModal = ({
  open,
  onClose,
  ...props
}: AddAndEditLocationModalProps) => {
  const [siteOptions, setSiteOptions] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [data, setData] = useState<LocationFormInputType>({
    name: '',
    email: '',
    phoneNo: '',
    street1: '',
    city: '',
    country: '',
    postalCode: '',
    sitePublicId: '',
  });
  useEffect(() => {
    if (props.type === 'Edit') {
      const initialData: LocationFormInputType = {
        name: props.location.name,
        email: props.location.email,
        phoneNo: props.location.phoneNo.toString(),
        sitePublicId: props.location.site.publicId,
        ...props.location.address,
      };
      setData(initialData);
    }
    const getSites = async () => {
      const [response, error] = await getAllSites();
      if (response) {
        const results: Record<string, string> = {};
        response.map((element: Site) => {
          results[element.publicId] = element.name;
        });
        setSiteOptions(results);
      } else {
        console.log(error);
      }
    };
    getSites();
  }, []);
  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const addLocationHandler = async () => {
    setIsLoading(true);
    const addressInfo: CreateLocationRequest = {
      name: data.name,
      email: data.email,
      phoneNo: data.phoneNo,
      address: {
        street1: data.street1,
        city: data.city,
        country: data.country,
        postalCode: data.postalCode,
      },
      sitePublicId: data.sitePublicId,
    };
    if (data.street2) {
      addressInfo.address.street2 = data.street2;
    }
    if (data.state) {
      addressInfo.address.state = data.state;
    }
    console.log(addressInfo);
    if (props.type === 'Create') {
      const [res, error] = await createNewLocation(addressInfo);
      if (res) {
        dispatch(addLocation(res));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_LOCATION_ADD,
          message: `Location - ${res.name} added successfully`,
        });
      } else {
        notify.error({
          title: ERROR_TITLE.GENERIC_LOCATION_ADD,
          message: error,
        });
        setIsLoading(false);
        return;
      }
    } else if (props.type === 'Edit') {
      const updateAddressInfo: UpdateLocationRequest = {
        externalId: props.location.externalId,
        publicId: props.location.publicId,
        ...addressInfo,
      };
      const [res, error] = await updateLocation(updateAddressInfo);
      if (res) {
        dispatch(updateLocationRedux(res));
        notify.success({
          title: SUCCESS_TITLE.GENERIC_LOCATION_UPDATE,
          message: `Location - ${props.location.name} updated successfully`,
        });
      } else {
        notify.error({
          title: SUCCESS_TITLE.GENERIC_LOCATION_UPDATE,
          message: error.data,
        });
        setIsLoading(false);
        return;
      }
    }

    setIsLoading(false);
    onClose();
  };
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="p-0  w-[65vw] md:w-[533px] flex flex-col gap-0 border border-neutral-100 max-h-[70%]">
        <DialogHeader className="flex justify-between items-center border-b border-neutral-100">
          <h1 className="text-[14px] text-neutral-900 font-semibold">
            {props.type === 'Create' ? 'Add location' : 'Edit location'}
          </h1>
          <Button variant={'link'} size={'xmall'} onClick={onClose}>
            <CrossIcon />
          </Button>
        </DialogHeader>
        <Slider className="flex-1" variant={'vertical'}>
          <div className="p-4 space-y-3">
            <div className="space-y-3">
              <div className="space-y-1">
                <Label htmlFor="name">Location name</Label>
                <Input
                  placeholder="Enter location name"
                  name="name"
                  onChange={inputHandler}
                  value={data.name}
                />
              </div>
              <div className="flex gap-3">
                <div className="space-y-1 w-full">
                  <Label htmlFor="contactNumber">Location contact number</Label>
                  <Input
                    placeholder="Enter contact number"
                    id="phoneNo"
                    name="phoneNo"
                    onChange={inputHandler}
                    value={data.phoneNo}
                  />
                </div>
                <div className="space-y-1 w-full">
                  <Label htmlFor="lastName">Location email address</Label>
                  <Input
                    placeholder="Enter email address"
                    id="email"
                    name="email"
                    onChange={inputHandler}
                    value={data.email}
                  />
                </div>
              </div>
            </div>
            <div className="h-[1px] w-full bg-neutral-100" />
            <div className="space-y-3">
              <h3 className="text-[14px] font-semibold">Address</h3>
              <div className="flex gap-3">
                <div className="space-y-1 w-full">
                  <Label htmlFor="street1">Street 1</Label>
                  <Input
                    placeholder="Street 1"
                    id="street1"
                    name="street1"
                    onChange={inputHandler}
                    value={data.street1}
                  />
                </div>
                <div className="space-y-1 w-full">
                  <Label htmlFor="street2">Street 2</Label>
                  <Input
                    required
                    placeholder="Street 2"
                    id="street2"
                    name="street2"
                    onChange={inputHandler}
                    value={data.street2}
                  />
                </div>
              </div>

              <div className="flex gap-3">
                <div className="space-y-1 w-full">
                  <Label htmlFor="city">City</Label>
                  <Input
                    placeholder="City"
                    id="city"
                    name="city"
                    onChange={inputHandler}
                    value={data.city}
                  />
                </div>
                <div className="space-y-1 w-full">
                  <Label htmlFor="state">County</Label>
                  <Input
                    placeholder="County"
                    id="state"
                    name="state"
                    onChange={inputHandler}
                    value={data.state}
                  />
                </div>
              </div>
              <div className="flex gap-3">
                <div className="space-y-1 w-full">
                  <Label htmlFor="country">Country</Label>
                  <Input
                    placeholder="Country"
                    id="country"
                    name="country"
                    onChange={inputHandler}
                    value={data.country}
                  />
                </div>
                <div className="space-y-1 w-full">
                  <Label htmlFor="postalCode">Postal code</Label>
                  <Input
                    required
                    placeholder="Postal code"
                    id="postalCode"
                    name="postalCode"
                    onChange={inputHandler}
                    value={data.postalCode}
                  />
                </div>
              </div>
            </div>
            <div className="h-[1px] w-full bg-neutral-100" />
            <div className="space-y-1">
              {siteOptions && (
                <>
                  <Label htmlFor="site">Staff site</Label>
                  <Select
                    required
                    name="siteExternalId"
                    value={data.sitePublicId}
                    onValueChange={value =>
                      setData(prev => ({
                        ...prev,
                        sitePublicId: value,
                      }))
                    }
                  >
                    <SelectTrigger
                      className="w-full"
                      selectIcon={<DownArrowIcon />}
                    >
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent className="bg-shades-0 z-[999999]">
                      {Object.keys(siteOptions).map(key => (
                        <SelectItem value={key} key={key}>
                          {siteOptions[key]}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>{' '}
                </>
              )}
            </div>
          </div>
        </Slider>
        <DialogFooter>
          <Button
            variant={'link'}
            size={'small'}
            className="w-full"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            size={'small'}
            onClick={addLocationHandler}
            className="w-full"
            variant={'primary'}
            isLoading={isLoading}
            disabled={isLoading}
          >
            {props.type === 'Create' ? 'Add location' : 'Save location'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddAndEditLocationModal;
