import { Button } from '../../../../UI';
import React from 'react';
import { LeftArrowIcon } from '../../../../../assets/icons';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import MedicalFormNotes from './MedicalFormNotes';

const CreateMedicalNotes = () => {
  const navigate = useNavigate();
  const { formatId } = useParams();

  const location = useLocation();

  const backHandler = () => {
    navigate(location.state.from, {
      state: {
        formFormatPublicId: formatId,
        showingAll: location.state.showingAll,
      },
    });
  };
  return (
    <>
      <div className="relative border-b border-neutral-100 p-3 flex justify-center items-center">
        <Button
          variant={'outlined'}
          size={'small'}
          leadingIcon={<LeftArrowIcon />}
          onClick={backHandler}
          className="absolute left-5 top-1.5"
        >
          Back
        </Button>
        <h1 className="text-neutral-900 font-medium whitespace-nowrap">
          {location.state.formFormatName}
        </h1>
      </div>
      <MedicalFormNotes />
    </>
  );
};

export default CreateMedicalNotes;
