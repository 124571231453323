import React, { FC } from 'react';

interface OpenEyeIconProps {
  stroke?: string;
}

const OpenEyeIcon2: FC<OpenEyeIconProps> = ({ stroke = '#0A0A0A' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M2.83521 7.99968C2.83521 7.99968 4.53471 4.03418 8.50021 4.03418C12.4657 4.03418 14.1652 7.99968 14.1652 7.99968C14.1652 7.99968 12.4657 11.9652 8.50021 11.9652C4.53471 11.9652 2.83521 7.99968 2.83521 7.99968Z"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50004 9.69929C9.43865 9.69929 10.1995 8.9384 10.1995 7.99979C10.1995 7.06119 9.43865 6.30029 8.50004 6.30029C7.56143 6.30029 6.80054 7.06119 6.80054 7.99979C6.80054 8.9384 7.56143 9.69929 8.50004 9.69929Z"
        stroke="black"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OpenEyeIcon2;
