import React from 'react';

interface NoteRolling2IconProps {
  stroke?: string;
}

const NoteRolling2Icon: React.FC<NoteRolling2IconProps> = ({
  stroke = 'currentColor',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M5.33325 14H13.3333C13.6869 14 14.026 13.8595 14.2761 13.6095C14.5261 13.3594 14.6666 13.0203 14.6666 12.6667V11.3333H6.66658V12.6667C6.66658 13.0203 6.52611 13.3594 6.27606 13.6095C6.02601 13.8595 5.68687 14 5.33325 14ZM5.33325 14C4.97963 14 4.64049 13.8595 4.39044 13.6095C4.14039 13.3594 3.99992 13.0203 3.99992 12.6667V3.33333C3.99992 2.97971 3.85944 2.64057 3.60939 2.39052C3.35935 2.14048 3.02021 2 2.66659 2C2.31296 2 1.97382 2.14048 1.72378 2.39052C1.47373 2.64057 1.33325 2.97971 1.33325 3.33333V5.33333H3.99992"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.6667 11.3333V3.33333C12.6667 2.97971 12.5263 2.64057 12.2762 2.39052C12.0262 2.14048 11.687 2 11.3334 2H2.66675"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0001 5.3335H6.66675"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0001 8H6.66675"
      stroke={stroke}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default NoteRolling2Icon;
