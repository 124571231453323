import { Label } from '../../../../../components/UI';
import RadioButton from '../../../../../components/UI/RadioButton';
import React from 'react';

interface Question {
  label: string;
  id: string;
  options: { label: string; value: string | boolean }[];
}
interface Props {
  setSelectedOptions: React.Dispatch<
    React.SetStateAction<{ [key: string]: string | boolean | null }>
  >;
  selectedOptions: { [key: string]: string | boolean | null };
}

const LabRadioOptions: React.FC<Props> = ({
  setSelectedOptions,
  selectedOptions,
}) => {
  const questions: Question[] = [
    {
      label: 'Have you sent a grey tube?',
      id: 'isGrey',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
    {
      label: 'Is the Patient Fasting?',
      id: 'isFasting',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
    {
      label: 'VENOUS blood kit',
      id: 'needsVenous',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
    {
      label: 'Self collect collection kit',
      id: 'needsKit',
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
  ];

  //   const [selectedCompOptions, setSelectedCompOptions] = useState<{
  //     [key: string]: string | boolean | null;
  //   }>({});

  const handleOptionChange = (label: string, value: string | boolean) => {
    // setSelectedCompOptions(prev => ({ ...prev, [label]: value }));
    setSelectedOptions(prev => ({ ...prev, [label]: value }));
  };

  return (
    <div>
      {questions.map(question => (
        <div key={question.label} className="flex justify-between items-center">
          <Label>{question.label}</Label>
          <div className="flex gap-8">
            {question.options.map(option => (
              <RadioButton
                key={option.label}
                label={question.label}
                optionLabel={option.label}
                value={option.value}
                selectedValue={selectedOptions[question.id]}
                onChange={value => handleOptionChange(question.id, value)}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LabRadioOptions;
