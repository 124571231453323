/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  // FolderIcon,
  // FileIcon,
  // AllFilesIcon,
  // DownArrowIcon,
  RightArrow,
  UploadIcon,
  CreateFolderIcon,
  SearchIcon,
  ScanIcon,
  DirectedArrowIcon,
} from '../../../../../assets/icons';
import React, { useEffect, useState } from 'react';
import DocumentUploadModal from './DocumentUploadModal';
import { Input, Slider } from '../../../../UI';
import GroupActionButton, {
  ActionButtonType,
} from '../../../../common/GroupActionButton';
import FilesTable from './FilesTable';
import EmptyFolder from './EmptyFolder';
import CreateFolderModal from './CreateFolderModal';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createDocument,
  deleteDocument,
  getChildDocuments,
} from '../../../../../services/api';
import { useFileUpload } from '../../../../../hooks/useFileUpload';
import { notify } from '../../../../common/Alert';

const Documents = () => {
  const { id } = useParams();
  // const [isActive, setIsActive] = useState<boolean>(true);
  const [activeFolder, setActiveFolder] = useState<DocumentUIType>({
    name: 'All Files',
    id: 'root',
    type: 'Folder',
    modifiedAt: new Date(),
    createdAt: new Date(),
  });
  const [childrenDocuments, setChildrenDocuments] = useState<DocumentUIType[]>(
    []
  );
  const [loading, isLoading] = useState<boolean>(false);
  const [uploadedFileName, uploadFileToStorage] = useFileUpload();

  function compareDocuments(a: DocumentUIType, b: DocumentUIType) {
    if (a.type === 'Folder' && b.type === 'Document') {
      return -1;
    }
    if (a.type === 'Document' && b.type === 'Folder') {
      return 1;
    }
    if (a.type === 'Folder' && b.type === 'Folder') {
      if (a.modifiedAt && b.modifiedAt) {
        return b.modifiedAt.getTime() - a.modifiedAt.getTime();
      }
      if (a.createdAt && b.createdAt) {
        return b.createdAt.getTime() - a.createdAt.getTime();
      }
    }
    if (a.type === 'Document' && b.type === 'Document') {
      if (a.modifiedAt && b.modifiedAt) {
        return b.modifiedAt.getTime() - a.modifiedAt.getTime();
      }
      if (a.createdAt && b.createdAt) {
        return b.createdAt.getTime() - a.createdAt.getTime();
      }
    }
    return 0;
  }

  const loadFolderChildren = async () => {
    isLoading(true);
    if (id) {
      const [res, err] = await getChildDocuments(id, activeFolder.id);
      if (err) {
        notify.error({
          message: err.data,
          title: 'Failed to fetch documents',
        });
      }

      const children: DocumentUIType[] = res.map((doc: Document) => ({
        id: doc.publicId,
        name: doc.name,
        url: doc.url,
        createdAt: new Date(doc.whenCreated),
        modifiedAt: doc.whenLastUpdated ? new Date(doc.whenLastUpdated) : null,
        type: doc.type,
        parent: activeFolder,
      }));

      children.sort(compareDocuments);
      setChildrenDocuments(children);
    }
    isLoading(false);
  };

  const createFolder = async (folderName: string) => {
    isLoading(true);
    if (id) {
      const folderCreateRequest: DocumentCreateRequest = {
        name: folderName,
        type: 'Folder',
        parentPublicId: activeFolder.id,
        patientPublicId: id,
      };
      const [res, err] = await createDocument(folderCreateRequest);
      if (err) {
        notify.error({
          message: err.data,
          title: 'Failed to create folder',
        });
      } else if (res) {
        notify.success({
          title: 'Folder created successfully',
          message: `New folder '${folderName}' has been created`,
        });
        loadFolderChildren();
      }
    }
    isLoading(false);
  };

  const uploadFile = async (file: File) => {
    if (!file) return;
    isLoading(true);
    const [response, error] = await uploadFileToStorage(
      file,
      `${id}-documents`
    );
    if (error) {
      notify.error({
        message: error,
        title: 'Failed to upload file',
      });
      isLoading(false);
      return;
    }
    const url = response;
    if (url && id) {
      const docCreateReq: DocumentCreateRequest = {
        name: file.name,
        type: 'Document',
        url: url,
        parentPublicId: activeFolder.id,
        patientPublicId: id,
      };

      const [res, err] = await createDocument(docCreateReq);
      if (err) {
        notify.error({
          message: err.data,
          title: 'Failed to upload file',
        });
      } else if (res) {
        notify.success({
          title: 'File uploaded successfully',
          message: `New file '${file.name}' has been uploaded`,
        });
        await loadFolderChildren();
      }
    }
    isLoading(false);
  };

  const deleteFile = async (file?: DocumentUIType) => {
    if (!file) return;
    isLoading(true);
    const [res, err] = await deleteDocument(file.id);
    if (err) {
      notify.error({
        message: err.data,
        title: 'Failed to delete file',
      });
    } else if (res) {
      notify.success({
        title: 'File deleted successfully',
        message: `File '${file.name}' has been deleted`,
      });
    }
    await loadFolderChildren();
    isLoading(false);
  };

  const onBackClicked = () => {
    if (activeFolder.parent) {
      setActiveFolder(activeFolder.parent);
    }
  };

  useEffect(() => {
    loadFolderChildren();
  }, [activeFolder]);

  return (
    <div className="flex h-[600px]">
      {/* <div className="min-h-[inherit] flex flex-col border-r border-neutral-100 w-[200px] space-y-3">
        <div className="py-4">
          <div className="flex gap-2 items-center p-3 font-medium text-neutral-900 cursor-pointer">
            <AllFilesIcon /> All Files
          </div>
          <div className="flex gap-2 items-center p-3 font-medium text-neutral-900 cursor-pointer">
            <FileIcon /> Files
          </div>
          <div className="flex gap-2 items-center p-3 font-medium text-neutral-900 cursor-pointer">
            <FolderIcon /> Folders
          </div>
        </div>
        <div className="h-[1px] w-full bg-neutral-100" />
        <div>
          <div
            className="px-3 py-2 flex items-center gap-2 text-neutral-500 font-medium cursor-pointer"
            onClick={() => setIsActive(prev => !prev)}
          >
            {isActive ? (
              <DownArrowIcon stroke="currentColor" />
            ) : (
              <RightArrow stroke="currentColor" />
            )}
            Folders
          </div>
          {isActive ? (
            <Slider className="flex-grow" variant={'vertical'}>
              <ul>
                {foldersMenu.map(folder => (
                  <li
                    key={folder.id}
                    className="p-3 text-neutral-900 font-medium flex gap-1 items-center cursor-pointer"
                  >
                    <RightArrow stroke="currentColor" />
                    {folder.folderName}
                  </li>
                ))}
              </ul>
            </Slider>
          ) : null}
        </div>
      </div> */}
      <div className="flex-grow space-y-6 flex flex-col">
        <DocumentHeader
          activeFolder={activeFolder}
          onBackClicked={onBackClicked}
          createFolder={createFolder}
          uploadFile={uploadFile}
        />
        {loading || (childrenDocuments && childrenDocuments.length > 0) ? (
          <FilesTable
            setActiveFolder={setActiveFolder}
            files={childrenDocuments}
            onDelete={deleteFile}
            loading={loading}
          />
        ) : (
          <div className="flex-grow flex flex-col">
            <EmptyFolder onUpload={uploadFile} />
          </div>
        )}
      </div>
    </div>
  );
};

const DocumentHeader = ({
  activeFolder,
  onBackClicked,
  createFolder,
  uploadFile,
}: {
  activeFolder: DocumentUIType;
  onBackClicked: () => void;
  createFolder: (folderName: string) => void;
  uploadFile: (file: File) => void;
}) => {
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showCreateFolderModal, setShowCreateFolderModal] =
    useState<boolean>(false);
  const openModal = () => {
    setShowUploadModal(true);
  };
  const navigate = useNavigate();

  const actionButtons: ActionButtonType[] = [
    {
      label: 'Upload',
      trailingIcon: <UploadIcon />,
      onClick: openModal,
      className: 'px-[10px] ',
    },
    {
      label: 'Create Folder',
      trailingIcon: <CreateFolderIcon />,
      onClick: () => setShowCreateFolderModal(true),
      className: 'px-[10px]',
    },
    {
      label: 'Scan',
      trailingIcon: <ScanIcon />,
      onClick: () => {
        navigate('scanner', {
          state: {
            activeFolder: activeFolder,
          },
        });
      },
      className: 'px-[10px] py-[12px]',
    },
    {
      label: 'Back',
      trailingIcon: <DirectedArrowIcon />,
      onClick: onBackClicked,
      disabled: !activeFolder.parent,
      className: 'px-[10px] py-[12px]',
    },
  ];
  return (
    <>
      <DocumentUploadModal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onUpload={uploadFile}
      />
      <CreateFolderModal
        open={showCreateFolderModal}
        onClose={() => setShowCreateFolderModal(false)}
        onSave={(name: string) => {
          createFolder(name);
          setShowCreateFolderModal(false);
        }}
      />
      <div className="space-y-4 p-4">
        <div className="flex justify-between">
          {/* <Input
            placeholder="Search for documents"
            leadingIcon={<SearchIcon />}
            className="w-[300px] md:w-[600px]"
          /> */}
          <p className="text-[18px] font-medium text-neutral-900">
            {activeFolder.name}
          </p>
          <GroupActionButton actions={actionButtons} />
        </div>
      </div>
    </>
  );
};

export default Documents;
