import { BASIC_COLORS } from '../../constants/ColorConstants';
import React, { ReactNode } from 'react';

const EmailManagerSidebarIcon = ({ isActive }: { isActive: Boolean }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4.66634C2 4.31272 2.14048 3.97358 2.39052 3.72353C2.64057 3.47348 2.97971 3.33301 3.33333 3.33301H12.6667C13.0203 3.33301 13.3594 3.47348 13.6095 3.72353C13.8595 3.97358 14 4.31272 14 4.66634V11.333C14 11.6866 13.8595 12.0258 13.6095 12.2758C13.3594 12.5259 13.0203 12.6663 12.6667 12.6663H3.33333C2.97971 12.6663 2.64057 12.5259 2.39052 12.2758C2.14048 12.0258 2 11.6866 2 11.333V4.66634Z"
        stroke={isActive ? BASIC_COLORS.THEME_BLACK : BASIC_COLORS.THEME_WHITE}
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 4.66602L8 8.66602L14 4.66602"
        stroke={isActive ? BASIC_COLORS.THEME_BLACK : BASIC_COLORS.THEME_WHITE}
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EmailManagerSidebarIcon;
