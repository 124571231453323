export const PAGE_TITLE = {
  OVERVIEW: 'Overview | Swivo MediNotes',
  LABS: 'Labs | Swivo MediNotes',
  PATIENTS: 'Patients | Swivo MediNotes',
  PATIENT_PROFILE: 'Patient Profile | Swivo MediNotes',
  CONFIRM_2FA: 'Confirm 2FA | Swivo MediNotes',
  SIGN_IN: 'Sign In | Swivo MediNotes',
  RESET_PASSWORD: 'Reset Password | Swivo MediNotes',
  SETUP_2FA: 'Setup 2FA | Swivo MediNotes',
};
