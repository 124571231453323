/* eslint-disable @typescript-eslint/no-explicit-any */
import { AZURE_URL } from '../requestConstructor';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ERROR_TO_DO } from '@/types';
export const getAllPathways = async () => {
  try {
    const response = await AZURE_URL.get('/pathway/get/all');
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: ERROR_TO_DO) {
    return [null, 'Server error: ' + error.response];
  }
};

export const createPathway = async (pathway: CreatePathwayRequest) => {
  try {
    const response = await AZURE_URL.post('/pathway/create', pathway);

    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const updatePathway = async (pathway: UpdatePathwayRequest) => {
  try {
    const response = await AZURE_URL.put('/pathway/update', pathway);

    return [response.data, null];
  } catch (error: ERROR_TO_DO) {
    return [null, error.response];
  }
};

export const deletePathway = async (id: string) => {
  try {
    const response = await AZURE_URL.delete(`/pathway/delete?publicId=${id}`);
    return [response, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getPathwayById = async (pathwayId: string) => {
  try {
    const response = await AZURE_URL.get(`/pathway/get?publicId=${pathwayId}`);
    if (response.status === 200) {
      return [response.data, null];
    } else {
      return [null, response];
    }
  } catch (error: ERROR_TO_DO) {
    return [null, 'Server error: ' + error.response];
  }
};
