import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: PathwayState = { pathways: [] };

export const pathwaySlice = createSlice({
  name: 'pathways',
  initialState,
  reducers: {
    addPathways: (state, action: PayloadAction<PathwayLite[]>) => {
      state.pathways = action.payload;
    },
    deletePathway: (state, action: PayloadAction<{ publicId: string }>) => {
      state.pathways = state.pathways.filter(
        pathway => pathway.publicId !== action.payload.publicId
      );
    },
    addPathway: (state, action: PayloadAction<PathwayLite>) => {
      state.pathways.push(action.payload);
    },
    updatePathway: (state, action: PayloadAction<PathwayLite>) => {
      const pathwayIndex = state.pathways.findIndex(
        pathway => pathway.publicId === action.payload.publicId
      );
      state.pathways[pathwayIndex] = action.payload;
    },
  },
});

export default pathwaySlice.reducer;
export const { addPathways, deletePathway, addPathway, updatePathway } =
  pathwaySlice.actions;
