import React from 'react';
import { Outlet } from 'react-router-dom';
import { ScannerContextProvider } from '../components/sections';
const ScannerPage = () => {
  return (
    <ScannerContextProvider>
      <Outlet />
    </ScannerContextProvider>
  );
};

export default ScannerPage;
