import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SwivoClient = {
  client: {
    clientId: null,
    name: null,
    url: null,
  },
};

export const clientSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clientDetails: (
      state: SwivoClient,
      action: PayloadAction<ClientEntities>
    ) => {
      state.client = action.payload;
    },
    removeClientDetails: () => initialState,
  },
});

export default clientSlice.reducer;
export const { clientDetails, removeClientDetails } = clientSlice.actions;
