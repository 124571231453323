import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedDatesAppointment {
  date: string;
  appointments: Appointment[];
}

interface AllAppointmentState {
  allAppointments: SelectedDatesAppointment[];
}

const initialState: AllAppointmentState = {
  allAppointments: [],
};

const appointmentSlice = createSlice({
  name: 'allAppointments',
  initialState,
  reducers: {
    setAppointments: (
      state,
      action: PayloadAction<SelectedDatesAppointment>
    ) => {
      const { date, appointments } = action.payload;
      const existingIndex = state.allAppointments.findIndex(
        item => item.date === date
      );

      if (existingIndex !== -1) {
        state.allAppointments[existingIndex].appointments = appointments;
      } else {
        state.allAppointments.push({ date, appointments });
      }
    },
  },
});

export const { setAppointments } = appointmentSlice.actions;

export default appointmentSlice.reducer;
