import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Input,
  Slider,
} from '../../../../UI';
import { CrossIcon, SearchIcon } from '../../../../../assets/icons';

interface SearchResult {
  type: string;
  label: string;
  results: { [key: string]: string }[];
}

const SearchModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  // Dummy Search Result: Search result from differenet section
  // result type may be changed based in API : But it should be preferred style
  const suggestedSearch: SearchResult[] = [
    {
      type: 'accessedPeople',
      label: 'People with Access',
      results: [
        {
          id: '1',
          name: 'Jhon Clark',
          email: 'john@gmail.com',
        },
        {
          id: '2',
          name: 'Jhon Clark',
          email: 'john@gmail.com',
        },
      ],
    },
    {
      type: 'staff',
      label: 'Staff',
      results: [
        {
          id: '1',
          name: 'Jack Clerk',
          email: 'johnc@gmail.com',
        },
        {
          id: '2',
          name: 'Jack Clerk',
          email: 'johnc@gmail.com',
        },
        {
          id: '3',
          name: 'Jack Clerk',
          email: 'johnc@gmail.com',
        },
        {
          id: '4',
          name: 'Jack Clerk',
          email: 'johnc@gmail.com',
        },
      ],
    },
  ];
  return (
    <div>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="p-0 md:w-[50%] max-h-[70%] flex flex-col gap-0">
          <DialogHeader className="flex justify-between items-center">
            <h1 className="text-[14px] text-neutral-900 font-medium">
              Send Documents
            </h1>
            <Button variant={'link'} size={'xmall'} onClick={onClose}>
              <CrossIcon />
            </Button>
          </DialogHeader>
          <Input
            leadingIcon={<SearchIcon />}
            className="w-full focus:border-neutral-100 rounded-none border-t border-b border-r-0 border-l-0"
            placeholder="Search for staff and Team"
          />
          <SearchResult data={suggestedSearch} />
          <DialogFooter className="flex gap-3 w-full ">
            <Button
              variant={'link'}
              size={'medium'}
              className="w-full"
              onClick={onClose}
            >
              Close
            </Button>
            <Button size={'medium'} onClick={onClose} className="w-full">
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const SearchResult: React.FC<{ data: SearchResult[] }> = ({ data }) => {
  return (
    <Slider variant={'vertical'} className="flex-1">
      {data.map(({ type, label, results }, index) => (
        <>
          <div key={index} className="p-[12px]">
            <h1 className="font-semibold text-[12px] text-neutral-500">
              {label}
            </h1>
            <div className="space-y-2">
              {results.map(user => (
                <SendPeople
                  key={user.id}
                  name={user.name}
                  email={user.email}
                  showCheckbox={type === 'staff' || type === 'team'}
                />
              ))}
            </div>
          </div>
          {data.length - 1 !== index && <hr />}
        </>
      ))}
    </Slider>
  );
};

const SendPeople = ({
  name,
  email,
  showCheckbox,
}: {
  name: string;
  email: string;
  showCheckbox: boolean;
}) => (
  <li className="cursor-pointer flex items-center justify-between space-y-3">
    <div className="flex items-center gap-2">
      <p className="text-[14px] font-medium text-neutral-900">{name}</p>
      <p className="text-[14px] font-medium text-neutral-500">{email}</p>
    </div>
    {showCheckbox && <input type="checkbox" />}
  </li>
);

export default SearchModal;
