/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormatType } from '@/types/form/type';
import { AZURE_URL } from '../../requestConstructor';
// API funtion will work on both -> if USER wants all forms a particular form format or a partcicula form of a particular formFormat
export const getFilledForms = async (
  patientPublicId: string,
  formatPublicId?: string,
  formPublicId?: string,
  type?: FormatType
) => {
  const url = `/form/get?patientPublicId=${patientPublicId}&formatPublicId=${formatPublicId ? formatPublicId : ''}&publicId=${formPublicId ? formPublicId : ''}&type=${type ? type : ''}`;
  try {
    const response = await AZURE_URL.get(url);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getFilledForm = async (publicId: string) => {
  try {
    const response = await AZURE_URL.get(`/form/get?publicId=${publicId}`);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const formFilled = async (formFilledData: Record<string, any>) => {
  try {
    const response = await AZURE_URL.post('/form/fill', formFilledData);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const updateFormFilled = async (formFilledData: Record<string, any>) => {
  try {
    const response = await AZURE_URL.put('/form/update', formFilledData);
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const sendFormFilled = async (publicId: string) => {
  try {
    const response = await AZURE_URL.post(`/form/send?publicId=${publicId}`);
    return [response, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const deleteFormFilled = async (publicId: string) => {
  try {
    const response = await AZURE_URL.delete(
      `/form/delete?publicId=${publicId}`
    );
    return [response, null];
  } catch (error: any) {
    return [null, error.response];
  }
};

export const getFillFormPDF = async (publicId: string) => {
  try {
    const response = await AZURE_URL.get(`/form/pdf?publicId=${publicId}`, {
      responseType: 'blob',
    });
    return [response.data, null];
  } catch (error: any) {
    return [null, error.response];
  }
};
