import React, { useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import * as dateFns from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './LibraryCalendar.css';
import CalenderEventModal from './CalenderEventModal';

const locales = {
  // 'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format: dateFns.format,
  parse: dateFns.parse,
  startOfWeek: dateFns.startOfWeek,
  getDay: dateFns.getDay,
  locales,
});

const events = [
  {
    title: 'Ultrasound Scan',
    patient: 'John Walker',
    resource: 'Jane Cooper',
    location: 'P4 Fertility at Harborne Road',
    start: new Date(2024, 4 - 1, 9, 8, 0),
    end: new Date(2024, 4 - 1, 9, 9, 30),
  },
  {
    title: 'Ultrasound Scan',
    patient: 'John Walker',
    resource: 'Jane Cooper',
    location: 'P4 Fertility at Harborne Road',
    start: new Date(2024, 4 - 1, 9, 8, 0),
    end: new Date(2024, 4 - 1, 9, 9, 0),
  },
  {
    title: 'Ultrasound Scan',
    patient: 'John Walker',
    resource: 'Jane Cooper',
    location: 'P4 Fertility at Harborne Road',
    start: new Date(2024, 4 - 1, 9, 10, 0),
    end: new Date(2024, 4 - 1, 9, 11, 0),
  },
  {
    title: 'Ultrasound Scan',
    patient: 'John Walker',
    resource: 'Jane Cooper',
    location: 'P4 Fertility at Harborne Road',
    start: new Date(2024, 4 - 1, 9, 11, 30),
    end: new Date(2024, 4 - 1, 9, 13, 0),
  },
  {
    title: 'Ultrasound Scan',
    patient: 'John Walker',
    resource: 'Jane Cooper',
    location: 'P4 Fertility at Harborne Road',
    start: new Date(2024, 4 - 1, 9, 13, 30),
    end: new Date(2024, 4 - 1, 9, 14, 0),
  },
  {
    title: 'Ultrasound Scan',
    patient: 'John Walker',
    resource: 'Jane Cooper',
    location: 'P4 Fertility at Harborne Road',
    start: new Date(2024, 4 - 1, 9, 14, 30),
    end: new Date(2024, 4 - 1, 9, 15, 30),
  },
  {
    title: 'Ultrasound Scan',
    patient: 'John Walker',
    resource: 'Jane Cooper',
    location: 'P4 Fertility at Harborne Road',
    start: new Date(2024, 4 - 1, 9, 16, 0),
    end: new Date(2024, 4 - 1, 9, 17, 30),
  },
  {
    title: 'Ultrasound Scan',
    patient: 'John Walker',
    resource: 'Jane Cooper',
    location: 'P4 Fertility at Harborne Road',
    start: new Date(2024, 4 - 1, 9, 18, 0),
    end: new Date(2024, 4 - 1, 9, 19, 0),
  },
  {
    title: 'Ultrasound Scan',
    patient: 'John Walker',
    resource: 'Jane Cooper',
    location: 'P4 Fertility at Harborne Road',
    start: new Date(2024, 4 - 1, 9, 19, 30),
    end: new Date(2024, 4 - 1, 9, 20, 30),
  },
  {
    title: 'Ultrasound Scan',
    patient: 'John Walker',
    resource: 'Jane Cooper',
    location: 'P4 Fertility at Harborne Road',
    start: new Date(2024, 4 - 1, 9, 21, 0),
    end: new Date(2024, 4 - 1, 9, 22, 30),
  },
  {
    title: 'Ultrasound Scan',
    patient: 'John Walker',
    resource: 'Jane Cooper',
    location: 'P4 Fertility at Harborne Road',
    start: new Date(2024, 4 - 1, 9, 23, 0),
    end: new Date(2024, 4 - 1, 9, 23, 15),
  },
];

const MyCalendar: React.FC = () => {
  // eslint-disable-next-line
  const [allEvents, setAllEvents] = useState(events);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
    null
  );
  const [openEventModal, setOpenEventModal] = useState<boolean>(false);
  const handleCloseModal = () => {
    setSelectedEvent(null);
    setOpenEventModal(false);
  };
  return (
    <div>
      <Calendar
        localizer={localizer}
        events={allEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectEvent={event => {
          setSelectedEvent(event);
          setOpenEventModal(true);
        }}
      />
      <CalenderEventModal
        event={selectedEvent}
        open={openEventModal}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default MyCalendar;
